import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonToggle
} from "reactstrap"

import Switch from "react-switch";

import PropTypes from 'prop-types'

import Breadcrumbs from "navigation/Breadcrumb"

import CardPricing from "./card-pricing"
import SubscriptionHistory from "./subscriptionHistory"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import {
  getPlans,
  getBots,
  getSubscriptionHistory,
  autoRenewBot
} from "store/actions"
import { CardHeader, TableHead } from "@material-ui/core";
// import plans from './plans'



class Subscription extends Component {

  constructor(props) {
    super(props)
    this.state = {
      subscribedBots: []
    }
    this.onConfirmAutoRenewBot = this.onConfirmAutoRenewBot.bind(this)
    this.onAutoRenewSuccess = this.onAutoRenewSuccess.bind(this)
    this.onAutoRenewError = this.onAutoRenewError.bind(this)
    this.getSubscriptionHistoryColumn = this.getSubscriptionHistoryColumn.bind(this)
  }

  getSubscriptionHistoryColumn() {
    let that = this;
    return [
      {
        dataField: "name",
        text: this.props.t("Bot Name"),
        formatter: (cellContent, row) => (
          <span className="ms_event">{row.name}</span>
        ),
        events: { onClick: this.onClickRow }
      },
      {
        dataField: 'created_at',
        text: this.props.t("Created Date"),
        formatter: (cellContent, row) => (
         <span className="text-success">{new Date(row?.created_date).toLocaleString()}</span>
        )

      },
      {
        dataField: "subscription_expiry",
        text: this.props.t("Expire Date"),
        formatter: (cellContent, row) => (
          !row.subscription_status || row.subscription_status===false?(
            <span className="text-center">---</span>
          ):(
            <span className="text-danger">{new Date(row.subscription_expiry)?.toLocaleString()}</span>
          )
             
        )
      },
      {
        dataField: "initialize_id",
        text: this.props.t("Initialize"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          // row.is_initialised && row.is_initialised===true?true:false
          row.is_initialised && row.is_initialised===true?(
              <span className="text-success"><i className='bx bx-check-circle cmBx28'></i></span>
          ):(
            <span className="text-danger"><i className='bx bx-x-circle cmBx28'></i></span>
          )
        )
      },
      {
        dataField: "subscribe_id",
        text: this.props.t("Subscribe"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          // row.subscription_status && row.subscription_status===true?true:false
          row.subscription_status && row.subscription_status===true?(
            <span className="text-success"><i className='bx bx-check-circle cmBx28'></i></span>
          ):(
            <span className="text-danger"><i className='bx bx-x-circle cmBx28'></i></span>
          )
        )
      },
    
      {
        dataField: "",
        text: this.props.t("Auto Renew"),
        // events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
        // console.log("cellContent" , row)
          row?.subscription_status == true && row?.is_initialised == true ? (
            <>
            <span>{this.props.t("Auto Renew")} </span>
            <span style={{ position: "relative", top: 4 }}>
              <Switch height={18} width={40} onChange={(value) => { this.toggleAutoRenew(row, value) }} checked={row?.auto_renew ? row.auto_renew : false} />
            </span>
            </>
            ):(
              <span className="text-danger">{this.props.t("Buy Subscription")}</span>
            )
        )
      }
    ]
  }

  componentDidMount() {
    this.props.onGetPlans()
    this.props.onGetSubscriptionHistory()
    this.props.onGetBots((bots) => {
      let sbots = bots.data?.filter(bot => bot.is_initialised == true && bot.subscription_expiry);
      this.setState({ subscribedBots: sbots })
    })
  }

  toggleAutoRenew = (bot, value) => {
    // alert('Test');
    bot.auto_renew = value;
    this.onConfirmAutoRenewBot(bot);
    

  }

  onConfirmAutoRenewBot(bot) {
    // alert('Test2');
    this.props.onAutoRenewBot(bot, this.onAutoRenewSuccess, this.onAutoRenewError)
  }

  onAutoRenewSuccess(response) {
    // console.log(response,'response')
    let localsubscribedBots = this.state.subscribedBots;
    // console.log(localsubscribedBots,'localsubscribedBots')
    localsubscribedBots.map((item, index) => {
      if (response.bot._id == item._id) {
        localsubscribedBots[index].auto_renew = response.bot.auto_renew;
      }

    })
    this.setState({ subscribedBots: localsubscribedBots });
    window.location.reload();
  }

  onAutoRenewError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error") })
    }
    else {
      this.setState({ error: response.error })
    }
  }
 

  render() {

    // let botListColumn = this.getBotListColumn()
    let subscriptionHistoryColumn = this.getSubscriptionHistoryColumn()
    const { bots, isComponent, balance } = this.props

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: this.state.subscribedBots.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    const { plans } = this.props
    let planID = this.props.user?.subscription_status

    let initializedBots = this.props.bots?.filter(bot => bot.is_initialised == true && !bot.subscription_expiry)
  
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Logos | Pricing")}</title>
          </MetaTags>
          <Container fluid>
            <section>
              <Row>
                <Col md={8}>
                    <nav>
                      <ol className="cd-breadcrumb triangle p-0 m-0">
                        <li className="cmBgli">Initialize & Subscription Data</li>
                      </ol>
                    </nav>
                </Col>
                <Col md={4} className="cmAlignRight">
                {/* <Breadcrumbsnav
                  title={this.props.t("Subscription")}
                  parent="/subscription"
                  /> */}
                  <p>{this.props.t("Subscription")}</p>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mt-4">
                  <h4 className="text-center mb-3">{this.props.t("Initialize")}</h4>
                  <p className="text-center text-muted">{this.props.t("A one time Initialization Fee is required for each bot.")}</p>
                  <h5 className="mb-2 ms-2">&nbsp;</h5>
                  <Card className="cmCardHeight">
                    <CardBody>
                      <div className="d-flex-column text-center">
                        <p className="mb-1">{this.props.t("One Time Initialization Fee")}</p>
                        <h5>{plans?.initialise_price?.amount + ' '+plans?.initialise_price?.currency}</h5>
                      </div>
                      <Button
                        onClick={() => this.props.history.push('/initialize')}
                        color="primary"
                        className="btn btn-primary btn-sm d-block m-auto mt-3">
                        {this.props.t("Initialize A Bot")}
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6}>
                    <h4 className="text-center mt-4 mb-3">{this.props.t("Choose Plan")}</h4>
                    <p className="text-center text-muted">{this.props.t("After Subscribe, you can pay can choose plan")}</p>
                    <h5 className="mb-2 ms-2">&nbsp;</h5>
                      {plans?.subscriptions_plans?.map(plan => (
                        <CardPricing
                          key={plan._id}
                          plan={plan}
                          selectedPlanID={planID}
                          history={this.props.history}
                          style={plan.style}
                          numPlans={plans?.subscriptions_plans?.length}
                          pricingInfo={this.props.pricingInfo}
                          monthlyPrice={plans?.subscriptions_plans?.find(plan => plan._id == '611226cd7b9fa3f2c999b12d')?.price}
                        />
                      ))}
                </Col>
              </Row>
              <Row>
                <Col md={12}><h4 className="card-title mb-4">Subscribed Bots</h4></Col>
              </Row>
              <Card>
                <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='_id'
                  columns={subscriptionHistoryColumn}
                  data={this.state.subscribedBots}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="_id"
                      data={this.state.subscribedBots}
                      columns={subscriptionHistoryColumn}
                      bootstrap4
                      search
                    >
                      {/* selectRow={selectRow} */}
                      {toolkitProps => (
                        <React.Fragment>
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              defaultSorted={defaultSorted}
                              bordered={true}
                              striped={true}
                              keyField="_id"
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
                </CardBody>
              </Card>
            </section>

            {/* <Row>
              <Col md={4} className="mt-4">
                <h4 className="text-center mb-3">{this.props.t("Initialize")}</h4>
                <p className="text-center text-muted">{this.props.t("A one time Initialization Fee is required for each bot.")}</p>
                <h5 className="mb-2 ms-2">&nbsp;</h5>
                <Card>
                  <CardBody>
                    <div className="d-flex-column text-center">
                      <p className="mb-1">{this.props.t("One Time Initialization Fee")}</p>
                      <h5>{plans?.initialise_price?.amount + ' '+plans?.initialise_price?.currency}</h5>
                    </div>
                    
                    <Button
                      onClick={() => this.props.history.push('/initialize')}
                      color="primary"
                      className="btn btn-primary btn-sm d-block m-auto mt-3"
                    >
                      {this.props.t("Initialize A Bot")}
                    </Button>
                  </CardBody>
                </Card>
                {initializedBots?.length ? (
                  <React.Fragment>
                    <div className="d-flex mt-5 mb-4 align-items-center justify-content-center">
                      <i className="d-inline bx bx-check-circle font-size-24 text-success" />
                      <h5 className="mb-0 ms-2 text-success">{this.props.t("Inititialized Bots")}</h5>
                    </div>
                    {initializedBots?.map(bot => {
                      return (
                        <Card key={bot._id}>
                          <CardBody className="d-flex justify-content-between">
                            <p className="mb-0 fw-bold">{bot.name}</p>
                            <p className="mb-0 text-info">{this.props.t("Created on")} {new Date(bot.created_date)?.toLocaleString()}</p>
                          </CardBody>
                        </Card>
                      )
                    })}
                    <div style={{ height: '2rem' }}></div>
                  </React.Fragment>
                ) : ''}
              </Col>
              <Col md={5}>

              <h4 className="text-center mt-4 mb-3">{initializedBots?.length ? this.props.t("Subscribe") : this.props.t("Choose Your Pricing Plan")}</h4>
              <p className="text-center text-muted">{this.props.t("After Initialization, you can pay your subscription fee via the following plans")}</p>

              {this.state.subscribedBots?.length ? (
                <React.Fragment>
                  <div className="d-flex mt-0 mb-2 align-items-center justify-content-center">
                    <i className="d-inline bx bx-check-circle font-size-24 text-success" />
                    <h5 className="mb-0 ms-2 text-success">{this.props.t("Subscribed Bots")}</h5>
                  </div>
                  <Card style={{maxHeight:'360px', overflowX:'scroll'}}>
                    <CardBody className="d-flex justify-content-between">
                      <table className="table striped bordered hover" >
                        <tbody>
                          {this.state.subscribedBots?.map(bot => {
                            var bot_expired = Object.prototype.hasOwnProperty.call(bot, 'subscription_status') && !bot.subscription_status;
                            
                            return (

                              <tr key={bot._id}>
                                <td width={'50%'}>{bot.name}</td>
                                {
                                  bot_expired ?
                                    <td className="error">{this.props.t("Expired on")} {new Date(bot.subscription_expiry)?.toLocaleString()}</td>
                                    :
                                    <td >{this.props.t("Expires on")} {new Date(bot.subscription_expiry)?.toLocaleString()}</td>
                                }
                                <td>
                                  <span>{this.props.t("Auto Renew")} </span>
                                  <span style={{ position: "relative", top: 4 }}>

                                    <Switch height={18} width={40} onChange={(value) => { this.toggleAutoRenew(bot, value) }} checked={bot?.auto_renew ? bot.auto_renew : false} />
                                  </span>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                  <div style={{ height: '2rem' }}></div>
                </React.Fragment>
              ) : ''}
              
              </Col>
              <Col md={3} >
              <h4 className="text-center mt-4 mb-3">{this.props.t("Choose Plan")}</h4>
              <p className="text-center text-muted">{this.props.t("After Subscribe, you can pay can choose plan")}</p>
              <h5 className="mb-2 ms-2">&nbsp;</h5>
                {plans?.subscriptions_plans?.map(plan => (
                  <CardPricing
                  
                    key={plan._id}
                    plan={plan}
                    selectedPlanID={planID}
                    history={this.props.history}
                    style={plan.style}
                    numPlans={plans?.subscriptions_plans?.length}
                    pricingInfo={this.props.pricingInfo}
                    monthlyPrice={plans?.subscriptions_plans?.find(plan => plan._id == '611226cd7b9fa3f2c999b12d')?.price}
                  />
                ))}
              </Col>
            </Row> */}

            <SubscriptionHistory
              invoices={this.props.invoices}
              plans={this.props.plans}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Subscription.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  plans: PropTypes.any,
  invoices: PropTypes.any,
  bots: PropTypes.any,
  pricingInfo: PropTypes.any,
  error: PropTypes.any,
  onGetPlans: PropTypes.func,
  onGetBots: PropTypes.func,
  onGetSubscriptionHistory: PropTypes.func,
  onAutoRenewBot: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth?.user,
  plans: state.user?.plans,
  invoices: state.user?.invoices,
  bots: state.bots?.bots,
  pricingInfo: state.user?.pricingInfo,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onGetPlans: (onSuccess, onError) => dispatch(getPlans(onSuccess, onError)),
  onGetBots: (onSuccess, onError) => dispatch(getBots(null, onSuccess, onError)),
  onGetSubscriptionHistory: (onSuccess, onError) => dispatch(getSubscriptionHistory(onSuccess, onError)),
  onAutoRenewBot: (bot, onSuccess, onError) => dispatch(autoRenewBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Subscription)))
