import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Form,
  Label,
  Input
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getAdminExchanges,
  changeStatusExchange,
} from "store/actions"


class Exchanges extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: null,
      error: null,
      modal: false,
      changeStatusModalVisible: false,
      deletingExchange: null,
      shownSecrets: {},
      exchanges: [],
      loadingInitData: true
    }

    this.toggle = this.toggle.bind(this)

    this.onToggleChangeStatusExchange = this.onToggleChangeStatusExchange.bind(this)
    this.toggleChangeStatusModal = this.toggleChangeStatusModal.bind(this)
    this.onConfirmChangeStatusExchange = this.onConfirmChangeStatusExchange.bind(this)
    this.onChangeStatusSuccess = this.onChangeStatusSuccess.bind(this)
    this.onChangeStatusError = this.onChangeStatusError.bind(this)

    // this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.onToggleShowSecret = this.onToggleShowSecret.bind(this)
    this.renderColumns = this.renderColumns.bind(this)
  }

  // toLowerCase1(str) {
  //   return str.toLowerCase()
  // }

  componentDidMount() {
    this.props.onGetExchanges({
      skip: 0,
      limit: 10
    }, (res) => {
      this.setState({ exchanges: res.data, loadingInitData: false })
    }, (err) => {
      this.setState({ loadingInitData: false })
    })
  }

  // eslint-disable-next-line no-unused-vars
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { exchanges } = this.props
  //   if (!isEmpty(exchanges) && size(prevProps.exchanges) !== size(exchanges)) {
  //     this.setState({ exchanges: {}, isEdit: false })
  //   }
  // }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  onToggleShowSecret(api) {
    // let updatedShownSecrets = {};
    // for (let id in this.state.shownSecrets) {
    //   if (id === api.id) updatedShownSecrets[id] = !this.state.shownSecrets[id];
    //   else updatedShownSecrets[id] = this.state.shownSecrets[id];
    // }
    // if (updatedShownSecrets[api.id] === undefined) updatedShownSecrets[api.id] = true;

    this.setState(prevState => ({
      shownSecrets: {
        ...prevState.shownSecrets,
        [api._id]: !prevState.shownSecrets[api._id]
      }
    }));
  }


  renderColumns() {
    return [
      {
        dataField: "name",
        text: this.props.t("Name"),
      },
      // {
      //   dataField: "is_ccxt_registered",
      //   text: this.props.t("Is ccxt"),
      // },
      // {
      //   dataField: "ccxt_id",
      //   text: this.props.t("CCXT Id"),
      //   formatter: (cellContent, row) => (
      //     !row.ccxt_id ? "-" : row.ccxt_id
      //   )
      // },
      {
        dataField: "balance_api_url",
        text: this.props.t("Balance api url"),
        formatter: (cellContent, row) => (
          !row.balance_api_url ? "-" : row.balance_api_url_method ? "["+row.balance_api_url_method+"]"+row.balance_api_url : row.balance_api_url
        )

      },
      {
        dataField: "open_orders_api_url",
        text: this.props.t("Open orders api url"),
        formatter: (cellContent, row) => (
          !row.open_orders_api_url ? "-" : row.open_orders_api_url_method ? "["+row.open_orders_api_url_method+"]"+row.open_orders_api_url : row.open_orders_api_url
        )

      },
      {
        dataField: "create_order_api_url",
        text: this.props.t("Create order api url"),
        formatter: (cellContent, row) => (
          !row.create_order_api_url ? "-" : row.create_order_api_url_method ? "["+row.create_order_api_url_method+"]"+row.create_order_api_url : row.create_order_api_url
        )

      },
      {
        dataField: "delete_order_api_url",
        text: this.props.t("Delete order api url"),
        formatter: (cellContent, row) => (
          !row.delete_order_api_url ? "-" : row.delete_order_api_url_method ? "["+row.delete_order_api_url_method+"]"+row.delete_order_api_url : row.delete_order_api_url
        )

      },
      {
        dataField: "market_summary_url",
        text: this.props.t("Market summary api url"),
        formatter: (cellContent, row) => (
          !row.market_summary_url ? "-" : row.market_summary_url_method ? "["+row.market_summary_url_method+"]"+row.market_summary_url : row.market_summary_url
        )

      },
      {
        dataField: "order_history_url",
        text: this.props.t("Order history api url"),
        formatter: (cellContent, row) => (
          !row.order_history_url ? "-" : row.order_history_url_method ? "["+row.order_history_url_method+"]"+row.order_history_url : row.order_history_url
        )

      },
      {
        dataField: "ticker_api_url",
        text: this.props.t("Tickers api url"),
        formatter: (cellContent, row) => (
          !row.order_history_url ? "-" : row.ticker_api_url_method ? "["+row.ticker_api_url_method+"]"+row.ticker_api_url : row.ticker_api_url
        )

      },
      // {
      //   dataField: "is_active",
      //   text: this.props.t("Status"),
      // },
      {
        dataField: "is_active",
        text: this.props.t("Actions"),
        formatter: (cellContent, row) => (
          <div className="text-start">

            <Button
              type="button"
              color={row.is_active ? "danger" : "success"}
              onClick={() => this.onToggleChangeStatusExchange(row)}
            >
              {row.is_active ? this.props.t("De-activate") : this.props.t("Activate")}
            </Button>
          &nbsp;&nbsp;
            <Link to={"/addEditExchanges/" + row._id}>
              <Button
                type="button"
                color="primary"
              >
                {this.props.t("Edit")}
              </Button>
            </Link>
            {
              // row.is_ccxt_registered ?
                <span>

                  &nbsp;&nbsp;
                  <Link to={"/importCoinNPairs/" + row._id}>
                    <Button
                      type="button"
                      color="primary"
                    >
                      {this.props.t("Import Coins")}
                    </Button>
                  </Link>
                </span>
                // :
                // null
            }
          </div>
        )
      }
    ]
  }


  onToggleChangeStatusExchange(api) {
    this.setState({ deletingExchange: api })
    this.toggleChangeStatusModal();
  }

  toggleChangeStatusModal() {
    this.setState({ changeStatusModalVisible: !this.state.changeStatusModalVisible })
  }

  onConfirmChangeStatusExchange() {
    this.props.onChangeStatusExchange(this.state.deletingExchange, this.onChangeStatusSuccess, this.onChangeStatusError)
    this.setState({ changeStatusModalVisible: false })
  }


  onChangeStatusSuccess(response) {
    let localExchange = this.state.exchanges;
    localExchange.map((item, index) => {
      if (item._id == response.exchange._id) {
        if (response.exchange.is_active) {
          localExchange[index].is_active = false;
        } else {
          localExchange[index].is_active = true;

        }
      }
    })
    this.setState({ exchanges: localExchange, error: '', success: this.props.t("Exchange status changed successfully.") })
  }

  onChangeStatusError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error"), success: '' })
    }
    else {
      this.setState({ error: response.error, success: '' })
    }
  }


  changeStatusExchangeModal() {
    return (
      <Modal
        isOpen={this.state.changeStatusModalVisible}
        toggle={this.toggleChangeStatusModal}
      >
        {/* <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">ChangeStatus Exchange Connection</h5>
          <button
            type="button"
            onClick={this.toggleChangeStatusModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}

        <div className="modal-body">
          <h5>{this.props.t("Change status of this exchange?")}</h5>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleChangeStatusModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onConfirmChangeStatusExchange}
          >
            {this.props.t("Change Status")}
          </button>
        </div>
      </Modal>
    )
  }



  render() {
    const { exchanges } = this.state

    let exchangeExchangeColumns = this.renderColumns();

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: exchanges.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    return (
      <React.Fragment>
        <div className="page-content">
          {this.changeStatusExchangeModal()}

          <Container fluid>
            <Breadcrumbs title={this.props.t("Exchanges")} />
            <Row className="mb-2">
              <Col sm="4">
              </Col>
              <Col sm="8">
                <div className="text-sm-end">
                  <Link to="/addEditExchanges">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded mb-2 me-2"
                    >
                      <i className="mdi mdi-plus me-1" />{" "}
                      {this.props.t("Create Exchange")}
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                  <p>{this.props.t("Exchanges")}</p>
                </div>

                {this.state.success && (
                  <Alert color="success" role="alert">
                    {this.state.success}
                  </Alert>
                )}

                {this.state.error && (
                  <Alert color="danger" role="alert">
                    {this.state.error}
                  </Alert>
                )}
                {
                  !this.state.loadingInitData ?



                    exchanges && exchanges?.length ? (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={exchangeExchangeColumns}
                        data={exchanges}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={exchanges}
                            columns={exchangeExchangeColumns}
                            bootstrap4
                            search
                          >
                            {/* selectRow={selectRow} */}
                            {toolkitProps => (
                              <React.Fragment>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={true}
                                    striped={true}
                                    keyField="_id"
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                  />
                                </div>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                        <div>
                          <p className="text-muted">{this.props.t("You have not added any Exchanges.")}</p>
                        </div>
                      )
                    :
                    <div>
                      <p className="text-muted">{this.props.t("Loading...")}</p>
                    </div>
                }
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Exchanges.propTypes = {
  t: PropTypes.any,
  exchanges: PropTypes.array,
  onGetExchanges: PropTypes.func,
  onChangeStatusExchange: PropTypes.func,

}

const mapStateToProps = state => ({
  exchanges: state.superAdmin.exchanges,
})

const mapDispatchToProps = dispatch => ({
  onGetExchanges: (page, onSuccess, onError) => dispatch(getAdminExchanges(page, onSuccess, onError)),
  onChangeStatusExchange: (api, onSuccess, onError) => dispatch(changeStatusExchange(api, onSuccess, onError)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Exchanges)))
