import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardBody, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"

//import cryptoWalletColumns from "./cryptoWalletColumns"


const productData = [
  { id: 1, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 LTC", valueInUsd: "$ 1773.01" },
  { id: 2, idno: "#SK3336", pdate: "03 Mar, 2020", type: "Sell", coin: "Ethereum", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 3, idno: "#SK3226", pdate: "13 Jun, 2020", type: "Sell", coin: "Litecoin", amount: "0.00413 LTC", valueInUsd: "$ 1773.01" },
  { id: 4, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Buy", coin: "Ethereum", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 5, idno: "#SK3226", pdate: "23 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 6, idno: "#SK3116", pdate: "03 Mar, 2020", type: "Sell", coin: "Ethereum", amount: "0.00413 LTC", valueInUsd: "$ 1773.01" },
  { id: 7, idno: "#SK3336", pdate: "13 Mar, 2020", type: "Buy", coin: "Ethereum", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 8, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 9, idno: "#SK3226", pdate: "23 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 10, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Sell", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 11, idno: "#SK3226", pdate: "08 Mar, 2020", type: "Sell", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 12, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 13, idno: "#SK3336", pdate: "03 Mar, 2020", type: "Buy", coin: "Ethereum", amount: "0.00413 LTC", valueInUsd: "$ 1773.01" },
  { id: 14, idno: "#SK3336", pdate: "07 Mar, 2020", type: "Sell", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 15, idno: "#SK3226", pdate: "13 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 16, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Buy", coin: "Ethereum", amount: "0.00413 ETH", valueInUsd: "$ 1773.01" },
  { id: 17, idno: "#SK3336", pdate: "03 Mar, 2020", type: "Buy", coin: "Litecoin", amount: "0.00413 LTC", valueInUsd: "$ 1773.01" },
]


class WalletActivities extends Component {
  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }

  constructor(props) {
    super(props);

    this.state = {
      columns: []
    }
  }


  getColumns() {
    let that = this;
    return [
      {
        dataField: 'transaction_hash',
        text: this.props.t('ID'),
        formatter: (cellContent, row) => (
          row?.transaction_hash ? row?.transaction_hash : <div className="text-center">--</div>
        )
      },
      {
        dataField: 'created_at',
        text: this.props.t('Date'),
        formatter: (cellContent, row) => (
          new Date(row?.created_at)?.toLocaleString()
        )
      },
      {
        dataField: 'activity_type',
        text: this.props.t('Type'),
        formatter: (cellContent, row, rowIndex, formatExtraData) => (
          this.props.t(row?.activity_type)
        ),
        formatExtraData: (cell, row) => this.props.t(row?.activity_type)
      },
      {
        dataField: 'from_address',
        text: this.props.t('From')
      },
      {
        dataField: 'to_address',
        text: this.props.t('To')
      },
      {
        dataField: 'value',
        text: this.props.t('Amount'),
        formatter: (cellContent, row) => (
          row?.value + ' ' + row?.wallet_type
        )
      },
      {
        dataField: 'status',
        text: this.props.t('Status'),
        formatter(cellContent, row) {
          let statusText = null;
          // console.log("row.statu ", row.status);
          switch (row.status) {
            case 0: {
              return <span className="text-info">{that.props.t("Pending")}</span>
            }
            case 1: {
              return <span className="text-success">{that.props.t("Completed")}</span>
            }
            case 2: {
              return <span className="text-danger">{that.props.t("Cancelled")}</span>
            }
            default: {
              return <span className="text-info">{that.props.t("Pending")}</span>
            }

          }
        },
        formatExtraData: (cell, row) => {
          let statusText = null;
          // console.log("row.statu ", row.status);
          switch (row.status) {
            case 0: {
              return <span className="text-info">{that.props.t("Pending")}</span>
            }
            case 1: {
              return <span className="text-success">{that.props.t("Completed")}</span>
            }
            case 2: {
              return <span className="text-danger">{that.props.t("Cancelled")}</span>
            }
            default: {
              return <span className="text-info">{that.props.t("Pending")}</span>
            }

          }
        }
      }
    ]
  }


  render() {

    const { walletHistory } = this.props

    let columns = this.getColumns()

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: walletHistory.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]


    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{this.props.t("Transaction History")}</h4>
          {/*
          <ul className="nav nav-tabs nav-tabs-custom">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Buy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Sell
              </NavLink>
            </NavItem>
          </ul>
          */}

          {walletHistory && walletHistory?.length ? (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={walletHistory}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={walletHistory}
                  columns={columns}
                  bootstrap4
                  search
                >
                  {/* selectRow={selectRow} */}
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={true}
                          striped={true}
                          keyField="_id"
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"} />
                      </div>
                      <div className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
              <p className="text-muted">{this.props.t("You have no transaction history yet.")}</p>
            )}

        </CardBody>
      </Card>
    )
  }

}

WalletActivities.propTypes = {
  t: PropTypes.any,
  walletHistory: PropTypes.array,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
}

export default withTranslation()(WalletActivities)
