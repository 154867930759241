import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getCompanies,
  changeStatusCompany,
} from "store/actions"


class Companies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: null,
      error: null,
      modal: false,
      changeStatusModalVisible: false,
      deletingCompany: null,
      shownSecrets: {},
      companies: [],
      loadingInitData: true
    }

    this.toggle = this.toggle.bind(this)

    this.onToggleChangeStatusCompany = this.onToggleChangeStatusCompany.bind(this)
    this.toggleChangeStatusModal = this.toggleChangeStatusModal.bind(this)
    this.onConfirmChangeStatusCompany = this.onConfirmChangeStatusCompany.bind(this)
    this.onChangeStatusSuccess = this.onChangeStatusSuccess.bind(this)
    this.onChangeStatusError = this.onChangeStatusError.bind(this)

    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.renderColumns = this.renderColumns.bind(this)
  }

  toLowerCase1(str) {
    return str.toLowerCase()
  }

  componentDidMount() {
    const { companies, onGetCompanies } = this.props
    // if (companies && !companies.length) {
    onGetCompanies({
      skip: 0,
      limit: 10
    }, (res) => {
      this.setState({ companies: res.data, loadingInitData: false })
    }, (err) => {
      this.setState({ loadingInitData: false })
    })
    // }
  }

  // eslint-disable-next-line no-unused-vars
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { companies } = this.props
  //   if (!isEmpty(companies) && size(prevProps.companies) !== size(companies)) {
  //     this.setState({ companies: {}, isEdit: false })
  //   }
  // }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }



  renderColumns() {
    return [
      {
        dataField: "name",
        text: this.props.t("Name"),
      },
      {
        dataField: "email",
        text: this.props.t("Email"),
      },
      {
        dataField: "referral_code",
        text: this.props.t("Referral Code"),
      },
      {
        dataField: "initialise_price",
        text: this.props.t("Initial Price"),
        formatter: (cellContent, row) => (
          row?.initialise_price?.amount + " " + row.initialise_price?.currency
        )
      },
      {
        dataField: "subscription_plans",
        text: this.props.t("Subscription Price"),
        formatter: (cellContent, row) => (
          row?.subscription_plans[0]?.price + " " + row.subscription_plans[0]?.currency
        )
      },
      {
        dataField: "customization_price",
        text: this.props.t("Customization Price"),
        formatter: (cellContent, row) => (
          row?.customization_price?.amount + " " + row.customization_price?.currency
        )
      },
      {
        dataField: "services",
        text: this.props.t("Bot Services"),
        formatter: (cellContent, row) => (
          row.services.map(value => value.name).join(", ")
        )
      },

      // {
      //   dataField: "is_active",
      //   text: this.props.t("Status"),
      //   formatter: (cellContent, row) => (
      //     row.is_active ? "Active" : "Inactive"
      //   )
      // },
      {
        dataField: "created_at",
        text: this.props.t("Created Date"),
        formatter: (cellContent, row) => (
          new Date(row?.created_at)?.toLocaleString()
        )
      },

      {
        dataField: "is_active",
        text: this.props.t("Actions"),
        formatter: (cellContent, row) => (
          <div>
            <Button
              type="button"
              color={row.is_active ? "danger" : "success"}
              onClick={() => this.onToggleChangeStatusCompany(row)}
            >
              {row.is_active ? this.props.t("De-activate") : this.props.t("Activate")}
            </Button>
          &nbsp;&nbsp;
            <Link to={"/addEditCompany/" + row._id}>
              <Button
                type="button"
                color="primary"
              >
                {this.props.t("Edit")}
              </Button>
            </Link>
          </div>
        ),
        formatExtraData: (cell, row) => {
          <div>
            <Button
              type="button"
              color={row.is_active ? "danger" : "success"}
              onClick={() => this.onToggleChangeStatusCompany(row)}
            >
              {row.is_active ? this.props.t("De-activate") : this.props.t("Activate")}
            </Button>
          &nbsp;&nbsp;
            <Link to={"/addEditCompany/" + row._id}>
              <Button
                type="button"
                color="primary"
              >
                {this.props.t("Edit")}
              </Button>
            </Link>
          </div>
        }
      }
    ]
  }



  onToggleChangeStatusCompany(api) {
    this.setState({ deletingCompany: api })
    this.toggleChangeStatusModal();
  }

  toggleChangeStatusModal() {
    this.setState({ changeStatusModalVisible: !this.state.changeStatusModalVisible })
  }

  onConfirmChangeStatusCompany() {
    this.props.onChangeStatusCompany(this.state.deletingCompany, this.onChangeStatusSuccess, this.onChangeStatusError)
    this.setState({ changeStatusModalVisible: false })
  }

  onChangeStatusSuccess(response) {
    let localCompany = this.state.companies;
    localCompany.map((item, index) => {
      if (item._id == response.company._id) {
        if (response.company.is_active) {
          localCompany[index].is_active = false;
        } else {
          localCompany[index].is_active = true;

        }
      }
    })
    this.setState({ companies: localCompany, success: this.props.t("Company status changed successfully."), error: '' })
  }

  onChangeStatusError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error"), success: '' })
    }
    else {
      this.setState({ error: response.error, success: '' })
    }
  }


  changeStatusCompanyModal() {
    return (
      <Modal
        isOpen={this.state.changeStatusModalVisible}
        toggle={this.toggleChangeStatusModal}
      >
        {/* <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">ChangeStatus Company Connection</h5>
          <button
            type="button"
            onClick={this.toggleChangeStatusModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}

        <div className="modal-body">
          {
            this.state?.deletingCompany?.is_active ?
              <h5>{this.props.t("Would you like to DeActivate '[company_name]'?").replace("[company_name]", this.state?.deletingCompany?.name)}</h5>
              :
              <h5>{this.props.t("Would you like to Activate '[company_name]'?").replace("[company_name]", this.state?.deletingCompany?.name)}</h5>
          }
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleChangeStatusModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
          </button>
          <button
            type="button"
            className={`btn +${this.state?.deletingCompany?.is_active ? " btn-danger" : " btn-success"}`}
            onClick={this.onConfirmChangeStatusCompany}
          >
            {
              this.state?.deletingCompany?.is_active ?
                this.props.t("De-activate") :
                this.props.t("Activate")

            }
          </button>
        </div>
      </Modal>
    )
  }


  render() {
    const { companies } = this.state

    let exchangeCompanyColumns = this.renderColumns();

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: companies.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    return (
      <React.Fragment>
        <div className="page-content">
          {this.changeStatusCompanyModal()}
          <Container fluid>
            <Breadcrumbs title={this.props.t("Companies")} />
            <Row className="mb-2">
              <Col sm="4">
              </Col>
              {
                this.props.user.role == "superAdmin" ?
                  <Col sm="8">
                    <div className="text-sm-end">
                      <Link to="/addEditCompany">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-2"
                        >
                          <i className="mdi mdi-plus me-1" />{" "}
                          {this.props.t("Add Company")}
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  :
                  null
              }
            </Row>
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                  <p>{this.props.t("Companies")}</p>
                </div>

                {this.state.success && (
                  <Alert color="success" role="alert">
                    {this.state.success}
                  </Alert>
                )}

                {this.state.error && (
                  <Alert color="danger" role="alert">
                    {this.state.error}
                  </Alert>
                )}

                {
                  !this.state.loadingInitData ?
                    companies && companies?.length ? (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={exchangeCompanyColumns}
                        data={companies}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={companies}
                            columns={exchangeCompanyColumns}
                            bootstrap4
                            search
                          >
                            {/* selectRow={selectRow} */}
                            {toolkitProps => (
                              <React.Fragment>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={true}
                                    striped={true}
                                    keyField="_id"
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                  />
                                </div>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                        <div>
                          <p className="text-muted">{this.props.t("You have not added any Company.")}</p>
                        </div>
                      )
                    :
                    <div>
                      <p className="text-muted">{this.props.t("Loading...")}</p>
                    </div>
                }
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Companies.propTypes = {
  t: PropTypes.any,
  companies: PropTypes.array,
  onGetCompanies: PropTypes.func,
  onChangeStatusCompany: PropTypes.func,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  companies: state.superAdmin.companies,
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  onGetCompanies: (page, onSuccess, onError) => dispatch(getCompanies(page, onSuccess, onError)),
  onChangeStatusCompany: (api, onSuccess, onError) => dispatch(changeStatusCompany(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Companies)))
