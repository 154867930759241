import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BOTS,
  GET_COINS,
  GET_PAIRS,
  CREATE_BOT,
  EDIT_BOT,
  START_STOP_BOT,
  UPDATE_BOT,
  DELETE_BOT,
  GET_OPEN_ORDERS,
  GET_SELECTED_COIN_BAL,
} from "./actionTypes"
import {
  getBotsSuccess,
  getBotsFail,
  getCoinsSuccess,
  getCoinsFail,
  getPairsSuccess,
  getPairsFail,
  createBotSuccess,
  createBotFail,
  editBotSuccess,
  editBotFail,
  startStopBotSuccess,
  startStopBotFail,
  updateBotSuccess,
  updateBotFail,
  deleteBotSuccess,
  deleteBotFail,
  getOpenOrdersSuccess,
  getOpenOrdersFail,
  getTradeHistorySuccess,
  getTradeHistoryFail,
  getSelectedCoinBalSuccess,
  getSelectedCoinBalFail,
} from "./actions"

import { get, post } from "helpers/api_helper"


function* fetchBots({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/bots', page)
    if (onSuccess) onSuccess(response)
    yield put(getBotsSuccess(response.data))
  } catch (error) {
    // console.log("error.response", error.response)
    if (onError) onError(error.response)
    yield put(getBotsFail(error))
  }
}


function* fetchCoins({ payload: page }) {
  try {
    // console.log('pAYLOAD page:' , page)
    const response = yield call(post, 'exchanges/coins', page)
    // console.log('data :' , response.data)
    yield put(getCoinsSuccess(response.data))
  } catch (error) {
    yield put(getCoinsFail(error))
  }
}


function* fetchPairs({ onFetch, payload: page }) {
  try {
    const response = yield call(post, 'exchanges/pairs', page)
    yield put(getPairsSuccess(response.data))
    onFetch(response.data);
  } catch (error) {
    // console.log(error.response);
    yield put(getPairsFail(error))
  }
}


function* onCreateBot({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/createBot', bot)
    if (response.status == 'success') {
      onSuccess(response.data);
      yield put(createBotSuccess(response.data))
    } else {
      onError(response)
    }
    // else throw response
  }
  catch (error) {
    console.log("onCreateBot error ", error);
    console.log("onCreateBot error.response ", error.response);
    onError(error?.response?.data)
    yield put(createBotFail(error))
  }
}
function* onEditBot({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/editBot', bot)
    if (response.status == 'success') {
      onSuccess(response.data);
      yield put(editBotSuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    onError(error)
    yield put(editBotFail(error))
  }
}


function* onStartStopBot({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post,
      bot.status ? 'exchanges/stopBot' : 'exchanges/startBot',
      { bot_id: bot._id })

    if (onSuccess) onSuccess({ ...response, bot })
    yield put(startStopBotSuccess(bot))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(startStopBotFail(error.response))
  }
}


function* onUpdateBot({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/updateBot', bot)
    onSuccess({ ...response, bot })
    yield put(updateBotSuccess(bot))
  } catch (error) {
    onError(error.response)
    yield put(updateBotFail(error))
  }
}


function* onDeleteBot({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/deleteBot', { id: bot._id })
    onSuccess({ ...response, bot })
    yield put(deleteBotSuccess(bot))
  } catch (error) {
    onError(error.response)
    yield put(deleteBotFail(error))
  }
}


function* fetchOpenOrders({ payload: id, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/openOrders', { bot_id: id })
    if (onSuccess) onSuccess(response)
    yield put(getOpenOrdersSuccess(response.data, id))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(getOpenOrdersFail(error))
  }
}

function* fetchSelectedCoinBal({ payload: id, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/stats', { bot_id: id })
    if (onSuccess) onSuccess(response)
    yield put(getSelectedCoinBalSuccess(response.data, id))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(getSelectedCoinBalFail(error))
  }
}

function* fetchTradeHistory({ payload: id, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/myTrades', { bot_id: id })
    // console.log("response myTrades ", response);
    if (onSuccess) onSuccess(response)
    yield put(getTradeHistorySuccess(response.data, id))
  } catch (error) {
    if (onError) onError(error.response)
    yield put(getTradeHistoryFail(error))
  }
}


function* botsSaga() {
  yield takeEvery(GET_BOTS, fetchBots)
  yield takeEvery(GET_COINS, fetchCoins)
  yield takeEvery(GET_PAIRS, fetchPairs)
  yield takeEvery(CREATE_BOT, onCreateBot)
  yield takeEvery(EDIT_BOT, onEditBot)
  yield takeEvery(START_STOP_BOT, onStartStopBot)
  yield takeEvery(UPDATE_BOT, onUpdateBot)
  yield takeEvery(DELETE_BOT, onDeleteBot)
  yield takeEvery(GET_OPEN_ORDERS, fetchOpenOrders)
  yield takeEvery('GET_TRADE_HISTORY', fetchTradeHistory)
  yield takeEvery(GET_SELECTED_COIN_BAL, fetchSelectedCoinBal)
}

export default botsSaga
