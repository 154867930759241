import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Label,
  Alert,
  UncontrolledAlert,
  FormGroup,
  FormText,
  ModalHeader, ModalBody, ModalFooter,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getBalance,
  getPrices,
  getBots,
  getAPIs,
  createBot,
  startStopBot,
  deleteBot,
  deleteBotSuccess,
} from "store/actions"

import { get, post } from "helpers/api_helper"
import default_icon from "../../assets/images/default_icon.png"


const icons = {
  BTC: 'mdi-bitcoin',
  ETH: 'mdi-ethereum',
  XRP: 'mdi-bitcoin',
  USDT: 'mdi-currency-usd',
  BUSD: 'mdi-currency-usd',
  USD: 'mdi-currency-usd',
  JPY: 'mdi-currency-jpy',
}


// --------------------
// MARKET DATA

// const ccxt = require('ccxt');
// const binance = new ccxt.binance();
// let markets;
// let prices;

// --------------------
// COLORS

const green = '#209232';
const red = '#f0616e';

// --------------------

import { BOT_DIFFERENCE } from './bot_settings'

// makepayment
// const MyModel = (props) => (<Modal show={props.isHidden} onHide={props.onClose}>
//   <Modal.Header closeButton>
//     <Modal.Title>Translations</Modal.Title>
//   </Modal.Header>
//   <Modal.Body>
//     <h4>Filter:</h4>
//     <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
//     <hr />
//   </Modal.Body>
//   <Modal.Footer>
//     <Button onClick={props.onClose}>Close</Button>
//   </Modal.Footer>
// </Modal>);
// end makepayment

class BotList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      success: null,
      loadingBalance: false,
      loadingBotChange: false,
      loadingMMbotChange: false,

      updateModalVisible: false,
      updatingBot: null,

      assetsUSDT: null,
      assetsAverage: null,

      coins: [],
      botColumns: [],

      prices: null,
      insufficientBalances: null,
      // title: 'My Bot ',
   
    }

    // this.getBotListColumn = this.getBotListColumn.bind(this)
    this.getMMbotListColumn = this.getMMbotListColumn.bind(this)
    this.fetchPrices = this.fetchPrices.bind(this)
    this.fetchPricesSuccess = this.fetchPricesSuccess.bind(this)
    this.fetchPricesError = this.fetchPricesError.bind(this)

    this.redirectToSubscription = this.redirectToSubscription.bind(this)

    this.onToggleUpdateBot = this.onToggleUpdateBot.bind(this)
    this.toggleUpdateModal = this.toggleUpdateModal.bind(this)
    this.calculateAssetsAverage = this.calculateAssetsAverage.bind(this)
    this.coinDifference = this.coinDifference.bind(this)
    this.displayCoinDifference = this.displayCoinDifference.bind(this)
    this.isAssetSufficient = this.isAssetSufficient.bind(this)
    this.onConfirmStartStop = this.onConfirmStartStop.bind(this)
    this.onStartStopSuccess = this.onStartStopSuccess.bind(this)
    this.onStartStopError = this.onStartStopError.bind(this)

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.onToggleDeleteBot = this.onToggleDeleteBot.bind(this)
    this.onConfirmDeleteBot = this.onConfirmDeleteBot.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
    this.onDeleteError = this.onDeleteError.bind(this)

    this.onGetBalanceSuccess = this.onGetBalanceSuccess.bind(this)
    this.onGetBalanceError = this.onGetBalanceError.bind(this)
  }


 

  getMMbotListColumn() {
    return [
      {
        dataField: "name",
        text: this.props.t("Bot Name"),
        formatter: (cellContent, row) => (
          <span className="ms_event">{row.name}</span>
        ),
        events: { onClick: this.onClickRow }
      },
      {
        dataField: "exchange_id",
        text: this.props.t("Exchange"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          // this.props.exchangeAPIs?.find(api => api._id == row.api_id)?.exchange_id?.name
          row.exchange_id.name
        )
      },
      {
        dataField: "bot_service_id",
        text: this.props.t("Strategy Type"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          row?.bot_service_id?.name
        )
      },
      {
        dataField: "coin_details",
        text: this.props.t("Total Balance in USDT"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => {
          // (row?.coin_details?.USDT + row?.coin_details?.B2Z)
          // console.log("Data???:", row)
        if(row.bot_service_id && row.bot_service_id.service_code==="MARKET_MAKING_BOT"){
          return(<>
            <h6><b>AccountOne : </b>{Object.keys(row.coin_details.api_keys_one).reduce((acc,value)=>acc+row.coin_details.api_keys_one[value],0)}</h6>
            <h6><b>AccountTwo :</b> {Object.keys(row.coin_details.api_keys_two).reduce((acc,value)=>acc+row.coin_details.api_keys_two[value],0)}</h6>
          </>)
        }else{
          const totalUSDT = Object.keys(row.coin_details).reduce((acc,value)=>acc+row.coin_details[value],0)
          return <h6>{totalUSDT}</h6>
        }
        }
          
      },
    
      {
        dataField: "",
        text: this.props.t("Payment Status"),
        // events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
        // console.log("cellContent" , row)
          row?.subscription_status == true && row?.is_initialised == true ? (
              <span className="text-success">{this.props.t("Paid")}</span>
            ):(
              <span className="text-danger">{this.props.t("Buy Subscription")}</span>
            )
         
        )
      },
      
      {
        dataField: "status",
        text: this.props.t("Status"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
        
          row?.status == true ? (
            <span className="text-success">{this.props.t("Going")}</span>
          ):(
            <span className="text-danger">{this.props.t("Stopped")}</span>
          )
        )
      },
      
      {
        dataField: "api_key_one",
        text: this.props.t("Actions"),
        formatter: (cellContent, row) => (
          row?.subscription_status == true && row?.is_initialised == true ? (

            <>
            <Button
              type="button"
              color={row.status ? 'warning' : 'success'}
              style={{ marginLeft: '0.25rem' }}
              onClick={() => this.onToggleUpdateBot(row)}
              disabled={!row.is_initialised || !row.subscription_expiry} >
              {row.status ? this.props.t("Stop") : this.props.t("Start")}
            </Button>


            <Button
              type="button"
              color="danger"
              style={{ marginLeft: '0.25rem',width:'auto' }}
              onClick={() => this.onToggleDeleteBot(row)}
            >
              Delete
            </Button>

            <Button
              type="button"
              color="secondary"
              style={{ marginLeft: '0.25rem', width:'auto' }}
              onClick={() => this.onClickEdit(row)}
            >
              {this.props.t("Edit")}
            </Button>
            </>
          ):(
          <>
          <Button
            type="button"
            color={row.status ? 'danger' : 'success'}
            style={{ marginLeft: '0.25rem' }}

            onClick={()=>this.toggleModal(row)}>
              {row.status ? this.props.t("Stop") : this.props.t("Start")}
          </Button>
          
          
          <Button
            type="button"
              color="danger"
              style={{ marginLeft: '0.25rem', width:'auto' }}
              onClick={() => this.onToggleDeleteBot(row)}
            >
            {this.props.t("Delete")}
          </Button>
          <Button
            type="button"
              color="secondary"
              style={{ marginLeft: '0.25rem', width:'auto' }}
              // onClick={() => this.onToggleUpdateBot(row)}
              onClick={() => this.onClickEdit(row)}
            >
            {this.props.t("Edit")}
          </Button>
          </>
            
          )
         
        )
      }
    ]
  }

  async fetchPrices(coinsArray) {
    // console.log('coinsArray', coinsArray);
    this.props.onGetPrices({ coinsArray, api_id: this.state.updatingBot.api_id._id }, this.fetchPricesSuccess, this.fetchPricesError)

    // let prices = await binance.fetchTickers(coinsArray);
    // this.setState({ prices })
  }

  fetchPricesSuccess() {
    this.calculateAssetsAverage()
    this.setState({ loadingBalance: false })
  }

  fetchPricesError() {
    this.calculateAssetsAverage()
    this.setState({ loadingBalance: false })
  }

  componentDidMount() {
    const { bots, onGetBots } = this.props
    // if (bots && !bots.length) {
    onGetBots()
    // }
    // this.props.onGetAPIs()

    // this.loadMarkets()
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate called")
    // if (this.props.)
    // this.setState({});
    // console.log("props ", this.props)
    // console.log("Subscribed ", this.props.t("Subscribed"))
    // let botListColumn = this.getBotListColumn();
    // this.setState({botColumns: botListColumn})
  }


  // UNSAFE_componentWillReceiveProps(){
  //   let botListColumn = this.getBotListColumn();
  //   this.setState({botColumns: botListColumn})
  // }

  onClickEdit = (row) => {
    this.props.history.push('/edit-bot/' + row._id);
  }
  onClickRow = (e, column, columnIndex, row, rowIndex) => {
    this.props.history.push('/bot-detail/' + row._id);
  }

  redirectToSubscription() {
    this.props.history.push('/subscription')
  }

  toggleUpdateModal() {
    this.setState(prevState => ({
      updateModalVisible: !prevState.updateModalVisible,
    }))
  }

  onToggleUpdateBot(bot) {

    if (!bot.status) {

      this.setState({
        loadingBalance: true,
        updatingBot: bot,
        insufficientBalances: null
      });
      if(!bot['bot_service_id'] || (bot['bot_service_id'] && bot['bot_service_id']['service_code']!=='MARKET_MAKING_BOT')){
        this.props.onGetBalance(bot.api_id?._id, this.onGetBalanceSuccess, this.onGetBalanceError)
      }
    } else {
      this.setState({
        updatingBot: bot,
        insufficientBalances: null
      });
    }

    this.toggleUpdateModal();
  }

  onGetBalanceSuccess(response) {
    // console.log("ronGetBalanceSuccess esponse ", response);
    const { updatingBot } = this.state

    let coinNames = updatingBot.trade_coins;
    if (coinNames && coinNames.length) {
      let coins = []
      let coinsArray = []
      // console.log("updatingBot", updatingBot);
      coinNames.forEach(coin => {


        if (updatingBot.api_id.exchange_id.name.toLowerCase() == "bitflyer") {
          if (coin.coin_type && coin.coin_type != 'JPY') {
            // if (pairArry.indexOf(coin.coin_type + '-JPY') != -1) {
              coins.push(coin.coin_type + '/JPY')
            // }
          }
        } else {
          if (coin.coin_type && coin.coin_type != 'USDT') {
            // if (pairArry.indexOf(coin.coin_type + '-USDT') != -1) {
              coins.push(coin.coin_type + '/USDT')
            // }
          }
        }





        // if (coin.coin_type && coin.coin_type != 'USDT') {
        //   coins.push(coin.coin_type + '/USDT')
        // }
      });
      this.setState({ coins })
      this.fetchPrices(coins)
    }
    else {
      this.setState({
        loadingBalance: false,
        insufficientBalances: [response.data?.msg]
      })
    }

    // if (!prices) {
    //   setTimeout(() => {
    //     this.calculateAssetsAverage()
    //   }, 2000);
    // }
    // else {
    //   this.calculateAssetsAverage()
    // }
  }

  onGetBalanceError(response) {
    this.setState({
      loadingBalance: false,
      insufficientBalances: [response.data?.msg]
    })
  }

  calculateAssetsAverage() {
    const { prices } = this.props
    const coins = this.state.updatingBot?.trade_coins?.map(coin => coin.coin_type)
    const { balance } = this.props

    let error = '';
    if (!coins || !coins.length) {
      error += this.props.t("No coins.") + ' '
    }
    if (!prices) {
      error += this.props.t("No prices.") + ' '
    }
    if (!balance) {
      error += this.props.t("No balance.") + ' '
    }

    if (error.trim()) {
      this.setState({ error })
      return;
    }
    else {
      this.setState({ error: null })
    }

    let assetsUSDT = {}
    coins.forEach(coin => {


      if (this.state.updatingBot.api_id.exchange_id.name.toLowerCase() == "bitflyer") {
        if (coin == 'JPY') {
          assetsUSDT[coin] = balance[coin]
        } else {
          assetsUSDT[coin] = balance[coin] * prices[coin + '/JPY']?.price
        }
      } else {
        if (coin == 'USD' || coin == 'USDT') {
          assetsUSDT[coin] = balance[coin]
        } else {
          assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
        }
      }



      // if (coin == 'USD' || coin == 'USDT') {
      //   assetsUSDT[coin] = balance[coin]
      // }
      // else {
      //   assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
      // }
    });

    let values = Object.values(assetsUSDT)
    let total = values.reduce((x, i) => x + i);
    let assetsAverage = total / values.length;

    this.setState({ assetsUSDT, assetsAverage })

    this.calcBalanceErrors()
    // setTimeout(() => {
    // }, 100)
  }

  onConfirmStartStop() {
    this.setState({
      updateModalVisible: false,
      loadingBotChange: true
    });

    this.props.onStartStopBot(this.state.updatingBot, this.onStartStopSuccess, this.onStartStopError);
  }

  onStartStopSuccess(response) {
    let bot = this.props.bots.find(bot => bot._id == this.state.updatingBot._id);
    this.setState({
      success: this.props.t("Bot") + ` "${bot.name}" ${bot.status ? this.props.t("stopped") : this.props.t("started")} ` + this.props.t("successfully"),
      updatingBot: null,
      loadingBotChange: false
    })
  }

  onStartStopError(response) {
    let error = this.props.t("Connection Error")
    if (response) error = response?.data?.msg

    this.setState({
      error,
      loadingBotChange: false
    })
  }


  onToggleDeleteBot(bot) {
    this.setState({ updatingBot: bot });

    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.setState({ deleteModalVisible: !this.state.deleteModalVisible });
  }

  onConfirmDeleteBot() {
    this.props.onDeleteBot(this.state.updatingBot, this.onDeleteSuccess, this.onDeleteError)
    this.setState({ deleteModalVisible: false })
  }

  onDeleteSuccess(response) {
    this.setState({ success: this.props.t("Bot successfully deleted.") })
  }

  onDeleteError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error") })
    }
    else {
      this.setState({ error: response.error })
    }
  }

  deleteBotModal() {
    return (
      <Modal
        isOpen={this.state.deleteModalVisible}
        toggle={this.toggleDeleteModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Delete Bot")}</h5>
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h5>{this.props.t("After deleting bot [botName] you will not get any refund for subscription?").replace("[botName]", this.state.updatingBot?.name)}</h5>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onConfirmDeleteBot}
          >
            {this.props.t("Delete")}
          </button>
        </div>
      </Modal>
    )
  }

  

  // balanceRequired(coin) {
  //   const { updatingBot, prices } = this.state;
  //
  //   if (!prices || !prices[coin]) return '--';
  //
  //   let amount = updatingBot.amount || 2000;
  //   let perCoinAmount = amount / updatingBot.trade_coins.length;
  //
  //   let currentPrice = perCoinAmount / prices[coin].close;
  //
  //   return parseFloat(binance.amountToPrecision(coin, currentPrice));
  // }
  //
  // isBalanceSufficient(coin) {
  //   let coinName = coin.split('/')[0];
  //   if (!coinName) return '--';
  //   return this.props.balance?.[coinName] >= this.balanceRequired(coin);
  // }

  coinDifference(coin) {
    const { assetsUSDT, assetsAverage } = this.state

    let difference = assetsUSDT?.[coin] / assetsAverage;
    return difference - 1;
  }

  displayCoinDifference(coin) {
    let difference = this.coinDifference(coin) * 100;
    if (isNaN(difference)) return ' (-- %)'
    let plus = difference > 0 ? '+' : '';
    return ` (${plus}${difference.toFixed(1)}%)`
  }

  isAssetSufficient(coin) {
    let difference = this.coinDifference(coin)

    // e.g. if difference is 0.3 (30%), is the balance
    // between 0.7 (70%) and 1.3 (130%) of average
    return Math.abs(difference) <= BOT_DIFFERENCE;
  }

  calcBalanceErrors() {
    const { assetsUSDT } = this.state

    let errors = [];
    Object.keys(assetsUSDT).forEach(coin => {
      if (!this.isAssetSufficient(coin)) {
        errors.push(this.props.t(`[coinName] balance is more than 30% beyond the average.`).replace("[coinName]", coin));
      }
    });

    if (!errors.length) errors = null;

    this.setState({ insufficientBalances: errors })
  }

  formatColumnsWidth(coins) {
    if (!coins || coins.length <= 1) return '12';
    else if (coins.length === 2) return '6';
    else return '6';
  }

  toggleModal = (row) => {
    let botData = row
    console.log("????",row)
    this.setState({updatingBot:botData})
    this.setState({ isOpen: !this.state.isOpen });
    
  };
  toggleModal2 = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    // let botListColumn = this.getBotListColumn()
    let mmBotListColumn = this.getMMbotListColumn()
    const { bots, isComponent, balance } = this.props
    const {
      updateModalVisible,
      updatingBot,
      insufficientBalances
    } = this.state

    const coins = updatingBot ? updatingBot.trade_coins : [];


    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: bots.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    return (
      <React.Fragment>
        <div className={isComponent ? '' : 'page-content'}>
          <MetaTags>
            <title>Logos | {this.props.t("My Bots")}</title>
          </MetaTags>

          {!isComponent && <Breadcrumbs title={this.props.t("My Bots")} />}

          <Row className="mb-2">
            <Col md="12">
              <Modal isOpen={this.state.isOpen} toggle={this.toggleModal2} aria-labelledby="contained-modal-title-vcenter"
                  centered>
                  <ModalHeader toggle={this.toggleModal2}>
                    {/* {this.props.t("Bot Payment Status")} */}
                    <h4>Make a Payment </h4>
                  </ModalHeader>
                  <ModalBody>
                 
                    {/* <h5>{this.props.t("Can you make the Payment")}</h5> */}
                    <div className="row cmBorder">
                      <div className="col-5">
                        <h5 className="mb-2">Name of the Bot</h5>
                      </div>
                      <div className="col-7">
                        <h5 className="mb-2"><small>: {updatingBot?.name}</small></h5>
                      </div>
                    </div>
                    
                    <div className="row cmBorder mt-2">
                      <div className="col-5">
                        <h5 className="mb-2">Exchange </h5>
                      </div>
                      <div className="col-7">
                        <h5 className="mb-2"><small>: {updatingBot?.exchange_id.name}</small></h5>
                      </div>
                    </div>
                    {/* <hr/> */}
                    <div className="row mt-3">
                      <div className="col-12 text-right cmAlignment">
                          {
                            updatingBot?.subscription_status && updatingBot?.is_initialised ?
                          
                            (
                              <Button
                              type="button"
                              color="success"
                              className="btn-rounded mb-2 me-2 btn-sm">
                              <i className="mdi mdi-plus me-1" />{" "}
                              {this.props.t("Start Bot")}
                            </Button>
                            ):(
                              <Button
                              type="button"
                              color="success"
                              onClick={this.redirectToSubscription}
                              className="btn-rounded mb-2 me-2 btn-sm">
                              <i className="mdi mdi-plus me-1" />{" "}
                              {this.props.t("Make a Payment")}
                            </Button>
                            )
                          
                          },
                      </div>
                    </div>
                  </ModalBody>
                  {/* <ModalFooter>
                    <Button onClick={this.toggleModal2}>Close</Button>
                  </ModalFooter> */}
                </Modal>  
            </Col>
            <Col sm="4">
                
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link to="/create-bot">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                  >
                    <i className="mdi mdi-plus me-1" />{" "}
                    {this.props.t("Create New Bot")}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>

          {this.deleteBotModal()}

          <Modal
            isOpen={updateModalVisible}
            toggle={this.toggleUpdateModal}
            className="modal-md">
            
            { 
            
              updatingBot && updatingBot?.bot_service_id?.service_code=='MARKET_MAKING_BOT'?(
                  <div className="modal-body">
                    <h5>
                      {
                        (updatingBot && updatingBot?.status) ?
                            this.props.t("Would you like to stop [bot_name]?").replace("[bot_name]", updatingBot?.name) : this.props.t("Would you like to start [bot_name]?").replace("[bot_name]", updatingBot?.name)
                      }
                    </h5>
                  </div>
              ):(
                !this.state.loadingBalance ? (
                  <div className="modal-body">
                    
                    <h5>{(updatingBot && updatingBot?.status) ?
                      this.props.t("Would you like to stop [bot_name]?").replace("[bot_name]", updatingBot?.name) : this.props.t("Would you like to start [bot_name]?").replace("[bot_name]", updatingBot?.name)}</h5>
                    {
                      (updatingBot && !updatingBot?.status )  ? 
                        <Row className="my-4">
                          <div className="col-md-12">
                            {/* <table className="responsive p-2 mb-2 border" width={'100%'}>
                              <tr style={{borderRightStyle: 'solid', verticalAlign:'top'}}>
                                <td className="p-2"><span className="cm_fontStyle2">{this.props.t("Assets Average")}</span></td>
                                <th className="p-2"><span className="cm_fontStyle2">{this.props.t("Allowed Difference from Average")}</span></th>
                                <th className="p-2"><span className="cm_fontStyle2">{this.props.t("Pairs")}</span></th>
                              </tr>
                              <tr>
                                <td className="p-2"><span className="p-1">{this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + (this.state.updatingBot.api_id.exchange_id.name.toLowerCase() == "bitflyer" ? ' JPY' : ' USDT')}</span></td>
                                <td className="p-2">{(BOT_DIFFERENCE * 100) + '%'}</td>
                                <td className="p-2">{updatingBot?.pairs?.length && updatingBot.pairs.join(', ')}</td>
                              </tr>
                            </table>
                          </div> */}
                            <table className="responsive p-2 mb-2 table-bordered" width={'100%'}>
                              {/* <tr style={{borderRightStyle: 'solid', verticalAlign:'top'}}></tr> */}
    
                              <tr>
                                <th className="p-2" width="25%"><span className="cm_fontStyle2">{this.props.t("Assets Average")}</span></th>
                                <td className="p-2 cm_label_2"><span className="p-1">{this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + (this.state.updatingBot.api_id.exchange_id.name.toLowerCase() == "bitflyer" ? ' JPY' : ' USDT')}</span></td>
                              </tr>
                              <tr>
                                <th className="p-2" width="50%"><span className="cm_fontStyle2">{this.props.t("Allowed Difference from Average")}</span></th>
                                <td className="p-2 cm_label_2">{(BOT_DIFFERENCE * 100) + '%'}</td>
                              </tr>
                              <tr>
                                <th className="p-2" width="25%"><span className="cm_fontStyle2">{this.props.t("Pairs")}</span></th>
                                <td className="p-2 cm_label_2">{updatingBot?.pairs?.length && updatingBot.pairs.join(', ')}</td>
                              </tr>
                            </table>
                          </div>
    
                          {/* <div style={{ marginBottom: '1rem' }}>
                            <p><span className="text-primary">{this.props.t("Assets Average")}:</span> {this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + ' USDT'}</p>
                            <p><span className="text-primary">{this.props.t("Allowed Difference from Average")}:</span> {(BOT_DIFFERENCE * 100) + '%'}</p>
                            <p><span className="text-primary">{this.props.t("Pairs")}:</span> {updatingBot?.pairs?.length && updatingBot.pairs.join(', ')}</p>
                          </div> */}
    
                          <Label>{this.props.t("Balance Required")}</Label>
                          {(Object.keys(balance)?.length && coins && coins.length) ? coins.map(coin => {
                            return (
                              // <div key={coin._id}></div>
                              // sm={this.formatColumnsWidth(coins)} 
                              
                              <div className="col-md-6" key={coin._id}>
                                <Label className="card-radio-label mb-2">
                                  <div className="card-radio_d2 coin-balance"
                                    style={this.isAssetSufficient(coin.coin_type) ? {} : {
                                      borderColor: red
                                    }}
                                    >
                                    <div className="">
                                      {/* <i className={`mdi ${icons[coin.coin_type]} font-size-24 text-warning align-middle me-2`} />{" "} */}
                                      <img src={default_icon} width="20" className=""/>{" "}
                                      <span>{coin.coin_type}</span> &nbsp;:&nbsp;
                                      <span style={{
                                        marginLeft: '0px',
                                        fontWeight: '400',
                                        whiteSpace: "initial",
                                        color: this.isAssetSufficient(coin.coin_type) ? green : red
                                      }}>{balance[coin.coin_type].toFixed(8) + this.displayCoinDifference(coin.coin_type)}</span>
                                    </div>
                                  </div>
                                </Label>
                              </div>
                                
                            )
                          }) : ''}
                        </Row>
                        :
                        null
                    }
    
                    {insufficientBalances && insufficientBalances.map((error, index) => {
                      return (
                        <Alert color="danger" role="alert" key={index}>
                          {error}
                        </Alert>
                      )
                    })}
                  </div>
                ) : (
                  <div className="p-4">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                    <span>{this.props.t("Loading balance...")}</span>
                  </div>
                )
              )
            }

            <div className="modal-footer">
              <button
                type="button"
                onClick={this.toggleUpdateModal}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {this.props.t("Close")}
              </button>

              {/* row?.status == true ? (
            <span className="text-success">{this.props.t("Going")}</span>
          ):(
            <span className="text-danger">{this.props.t("Stopped")}</span>
          ) */}

             {updatingBot && updatingBot?.bot_service_id?.service_code=='MARKET_MAKING_BOT' ?(
              <button
              type="button"
              className={`btn btn-${updatingBot && updatingBot?.status ? 'danger' : 'success'}`}
              onClick={this.onConfirmStartStop}
            >
              {updatingBot && updatingBot?.status ?
                this.props.t("Stop") : this.props.t("Start")}
            </button>
             ):(
              <button
                type="button"
                className={`btn btn-${updatingBot && updatingBot?.status ? 'danger' : 'success'}`}
                onClick={this.onConfirmStartStop}
                disabled={
                  this.state.loadingBalance ||
                  insufficientBalances
                }
              >
                {updatingBot && updatingBot?.status ?
                  this.props.t("Stop") : this.props.t("Start")}
              </button>
             )
              
             }
              {/* <button
                type="button"
                className={`btn btn-${updatingBot && updatingBot.status ? 'danger' : 'success'}`}
                onClick={this.onConfirmStartStop}
                disabled={
                  this.state.loadingBalance ||
                  insufficientBalances
                }
              >
                {updatingBot && updatingBot.status ?
                  this.props.t("Stop") : this.props.t("Start")}
              </button> */}
            </div>
          </Modal>
          {/* disabled={!row.is_initialised || !row.subscription_expiry} */}
          <Card>
            <CardBody>
              {/* <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                <p>{this.props.t("My Bots")}</p>
              </div> */}
              {this.state.loadingBotChange && (
                <div className="p-4">
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                  <span>{this.props.t("Waiting for bot...")}</span>
                </div>
              )}

              {this.state.success && (
                <UncontrolledAlert color="success" role="alert">
                  {this.state.success}
                </UncontrolledAlert>
              )}

              {this.state.error && (
                <Alert color="danger" role="alert">
                  {this.state.error}
                </Alert>
              )}

              {/* {bots && bots?.length ? (

                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={botListColumn}
                  data={bots}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={bots}
                      columns={botListColumn}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              defaultSorted={defaultSorted}
                              bordered={true}
                              striped={true}
                              keyField="_id"
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </React.Fragment>

                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>

              ) : (
                <div>
                  <p className="text-muted">{this.props.t("You have not created any bots.")}</p>
                </div>
              )} */}

              <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                <p>{this.props.t("My Bots List")}</p>
              </div>

              
              {bots && bots?.length ? (

              <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={mmBotListColumn}
                  data={bots}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={bots}
                      columns={mmBotListColumn}
                      bootstrap4
                      search
                    >
                      {/* selectRow={selectRow} */}
                      {toolkitProps => (


                        <React.Fragment>
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              defaultSorted={defaultSorted}
                              bordered={true}
                              striped={true}
                              keyField="_id"
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                          {/* <Alert color="warning" role="alert" className="mt-3">
                    <span className="fw-bold">Note:</span> If you would like to delete a bot, please contact customer support.
                  </Alert> */}
                        </React.Fragment>


                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>

              ) : (
                <div>
                  <p className="text-muted">{this.props.t("You have not created any bots.")}</p>
                </div>
              )}
            </CardBody>
          </Card>


        </div>
      </React.Fragment>
    )
  }
}


BotList.propTypes = {
  isComponent: PropTypes.bool,
  t: PropTypes.any,
  history: PropTypes.any,
  balance: PropTypes.object,
  prices: PropTypes.object,
  bots: PropTypes.array,
  exchangeAPIs: PropTypes.array,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
  onGetBots: PropTypes.func,
  onGetAPIs: PropTypes.func,
  test: PropTypes.func,
  onCreateBot: PropTypes.func,
  onStartStopBot: PropTypes.func,
  onDeleteBot: PropTypes.func,
}

const mapStateToProps = state => ({
  balance: state.user.balance,
  prices: state.user.prices,
  bots: state.bots.bots,
  exchangeAPIs: state.exchangeAPI.exchangeAPIs
})

const mapDispatchToProps = dispatch => ({
  onGetBalance: (botID, onSuccess, onError) => dispatch(getBalance(botID, onSuccess, onError)),
  onGetPrices: (coins, onSuccess, onError) => dispatch(getPrices(coins, onSuccess, onError)),
  onGetBots: page => dispatch(getBots(page)),
  onGetAPIs: page => dispatch(getAPIs()),
  test: bot => dispatch(deleteBotSuccess(bot)),
  onCreateBot: bot => dispatch(createBot(bot)),
  onStartStopBot: (bot, onSuccess, onError) => dispatch(startStopBot(bot, onSuccess, onError)),
  onDeleteBot: (bot, onSuccess, onError) => dispatch(deleteBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BotList)))
