import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Col, Row, Card, CardBody, Media, Button } from "reactstrap"
import { withTranslation } from "react-i18next"

class CardPricing extends Component {

  render() {

    return (
      <Col xl="12" md="12">
        <Card className="plan-box cmCardHeight">
          <CardBody className="pt-3">
            <Media>
              <Media body>
                <h5>{this.props.plan?.name}</h5>
                <p className="text-muted">{this.props.plan?.description}</p>
              </Media>
            </Media>
            <div className={"pt-0 mt-1 pb-1"}>
              <div className="mt-3 d-flex-column text-center">
                <h6 className="mb-1">{this.props.t("Total")}</h6>
                <h5>{this.props.plan?.price + ' '+this.props.plan?.currency}</h5>
              </div>
            </div>
            <div className="text-center">
              <Button
                onClick={() => this.props.history.push('payment/' + this.props.plan?._id)}
                color="primary"
                className="btn btn-primary btn-sm">
                {this.props.t("Choose This Plan")}
              </Button>
            </div>
            <div className="plan-features mt-1">
              {this.props.plan?.features?.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

CardPricing.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  plan: PropTypes.object,
  selectedPlanID: PropTypes.string,
  numPlans: PropTypes.number,
  pricingInfo: PropTypes.any,
  monthlyPrice: PropTypes.number,
}

export default withTranslation()(CardPricing)
