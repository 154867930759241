import React, { useState } from "react";
import { Input, Label, Row } from "reactstrap";
import ReactDOM from 'react-dom';
import InputRange from 'react-input-range';

class MMBot extends React.Component {
    constructor(props) {
        super(props);
     
        this.state = { value: 0};
      }

render() {
	return <>
        <Row>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Account One API KEY</Label>
                    <Input type="text" className="form-control" placeholder="Account One API KEY"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Account One Private key</Label>
                    <Input type="text" className="form-control" placeholder="Account One Private key"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Account Two Api Key</Label>
                    <Input type="text" className="form-control" placeholder="Account Two Api Key"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Account Two Private key</Label>
                    <Input type="text" className="form-control" placeholder="Account Two Private key"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Pair</Label>
                    <Input type="text" className="form-control" placeholder="Pair"/>
                </div>
            </div>
            <div className="col-md-6">
                
                <div className="mt-3">
                <Label className="pb-2">Order execution Duration in : <span className="text-success text-bold"> {this.state.value} Sec</span></Label>
                <InputRange
                    maxValue={60}
                    minValue={0}
                    value={this.state.value}
                    onChange={value => this.setState({ value })} />                   

                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Max Order Count</Label>
                    <Input type="text" className="form-control" placeholder="Max Order Count"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Max Buy Order Count</Label>
                    <Input type="text" className="form-control" placeholder="Max Buy Order Count"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Max Sell Order Count</Label>
                    <Input type="text" className="form-control" placeholder="Max Sell Order Count"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Min Buy Order Count</Label>
                    <Input type="text" className="form-control" placeholder="Min Buy Order Count"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Order size</Label>
                    <Input type="text" className="form-control" placeholder="Order size"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>Order Size Multiplier </Label>
                    <Input type="text" className="form-control" placeholder="Order Size Multiplier "/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>MinWidthPercent</Label>
                    <Input type="text" className="form-control" placeholder="MinWidthPercent"/>
                </div>
            </div>
            <div className="col-md-6">
                <div className="mt-3">
                    <Label>MinWidthPercentIncrement</Label>
                    <Input type="text" className="form-control" placeholder="MinWidthPercentIncrement"/>
                </div>
            </div>
        </Row>

    </>;
}
}

export default MMBot;
