import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

//import cryptoWalletColumns from "./cryptoWalletColumns"



class ReferralWithdrawals extends Component {
  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }

  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          dataField: 'created_at',
          text: this.props.t("Date"),
          formatter: (cellContent, row) => (
            new Date(row?.created_at)?.toLocaleString()
          )
        },
        {
          dataField: 'amount',
          text: this.props.t('Amount'),
          formatter: (cellContent, row) => (
            row?.amount + ' PDT'
          )
        },
        {
          dataField: 'withdraw_status',
          text: this.props.t('Status'),
          formatter: (cellContent, row) => (
            row?.withdraw_status? (
              <span className="text-success">{this.props.t("Completed")}</span>
            ) : (
              <span className="text-danger">{this.props.t("Failed")}</span>
            )
          )
        }
      ]
    }
  }



  render() {
    return (
      
          
        <div>

          {this.props.referralWithdrawals?.length ? (
            <div className="mt-4 table-responsive">
              <BootstrapTable keyField='_id'
                data={this.props.referralWithdrawals}
                columns={this.state.columns} />
            </div>
          ) : (
            <p className="text-muted">{this.props.t("You have no transaction history yet.")}</p>
          )}
        </div>

       
    )
  }

}

ReferralWithdrawals.propTypes = {
  t: PropTypes.any,
  referralWithdrawals: PropTypes.array,
}

export default withTranslation()(ReferralWithdrawals)
