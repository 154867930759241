import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import { Hourglass } from 'react-loader-spinner'


import {
  Alert,
  UncontrolledAlert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import Select from 'react-select'

import Breadcrumbs from "navigation/Breadcrumb"
import InputRange from 'react-input-range';
import { AvField, AvForm } from "availity-reactstrap-validation";

import 'bootstrap/dist/css/bootstrap.min.css'; 
import axios from 'axios';

import {
  getAPIs,
  getExchanges,
  getBalance,
  getPrices,
  getCoins,
  getPairs,
  createBot,
  createAPI,
  getBotServices
} from "store/actions"


// --------------------
// MARKET DATA

// const ccxt = require('ccxt');
// const paradise = new ccxt.paradise();
// const binance = new ccxt.binance();
// let markets;
// let prices;

// --------------------
// COLORS

const green = '#5fcb80';
const red = '#f0616e';

// --------------------

import { BOT_DIFFERENCE } from './bot_settings'
import MMBot from "./BotDetailComponents/MMBot"
import PRBot from "./BotDetailComponents/PRBot"
import SwitchButton from "./SwitchButton"
import Loader from "pages/Spinner/Loader"




const icons = {
  BTC: 'mdi-bitcoin',
  ETH: 'mdi-ethereum',
  XRP: 'mdi-bitcoin',
  USDT: 'mdi-currency-usd',
  BUSD: 'mdi-currency-usd',
  USD: 'mdi-currency-usd',
  JPY: 'mdi-currency-jpy',
}

let paradiseData;

class CreateBot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      isMenu: false,

      name: '',
      selectedExchange: null,
      selectedCoins: [],
      prices: null,
      pairs: [],
      filteredPairs: [],
      selectedPairs: null,
      totalBalance: 9148.23,
      delta: 3,

      assetsUSDT: null,
      assetsAverage: null,

      loadingBalance: false,

      loading: false,
      error: null,
      insufficientBalances: null,
      SelectBotType:'',
      
      orderDuration: 0,
      selectedOption:  {value: "0", label: "Select Bot Type" },

      fields: {},
      errors: {},
      selectedExchangeName: null,
      apiKey: null,
      apiSecret: null,
      api_key_one:'',
      api_secret_one:'',
      api_memo_one:'',
      api_passphrase_one:"",
      selectCoinsList:[],
      botServices: [],
      SelectedBotServices:[],
      mm_name:'',
      api_key_two:'',
      api_secret_two:'',
      api_memo_two:'',
      api_passphrase_two:"",
      pair:'',
      max_order_count:0,
      max_buy_order_count:0,
      max_sell_order_count:0,
      min_buy_order_count:0,
      min_sell_order_count:0,
      order_size:'',
      order_size_multiplier:0,
      min_width_percent:0,
      min_width_percent_increment:'',
      initial_market_price:'',
      order_execution_duration:'',
      SelectedBotServiceType:'',
      selectedBotType:'',
      spread:false,
      poll_time:0,
      isEnabled: true,
      isAlertOpen:false,
      loader:false,
    }

    this.fetchPrices = this.fetchPrices.bind(this)
    this.fetchPricesSuccess = this.fetchPricesSuccess.bind(this)
    this.fetchPricesError = this.fetchPricesError.bind(this)
    this.onGetBalanceSuccess = this.onGetBalanceSuccess.bind(this)
    this.onGetBalanceError = this.onGetBalanceError.bind(this)

    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.calculatePairs = this.calculatePairs.bind(this)
    this.calculateAssetsAverage = this.calculateAssetsAverage.bind(this)
    this.coinDifference = this.coinDifference.bind(this)
    this.displayCoinDifference = this.displayCoinDifference.bind(this)
    this.isAssetSufficient = this.isAssetSufficient.bind(this)
    this.calcBalanceErrors = this.calcBalanceErrors.bind(this)
    this.formatBalanceColor = this.formatBalanceColor.bind(this)
    this.formatColumnsWidth = this.formatColumnsWidth.bind(this)
    this.formatCoinList = this.formatCoinList.bind(this)
    this.formatCoinListSelection = this.formatCoinListSelection.bind(this)

    // this.onChange = this.onChange.bind(this);
    this.onFetchExchanges = this.onFetchExchanges.bind(this)
    this.onExchangeChange = this.onExchangeChange.bind(this)
    this.addAPI = this.addAPI.bind(this)

    this.createBot = this.createBot.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)

    this.redirectToAPIs = this.redirectToAPIs.bind(this)

   
    
    // this.handleChange = this.handleChange.bind(this);

    // this.handleChange = this.handleChange.bind(this);
    // this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
  }

  componentDidMount() {
    // this.props.onGetAPIs()
    // this.props.onGetExchanges(this.onFetchExchanges)
    
    const { botServices, onGetBotServices } = this.props
    // console.log("componentDidMount called1");
    let code = this.props?.match?.params?.id;

    // console.log("componentDidMount called4 :", code);
    onGetBotServices(
      {}, 
      (res) => {
        // console.log(" res ", res);
      this.setState({ botServices: res.data, loadingInitData: false })
      let items = [];
      res.data.map((item) => (
        items.push({ value: item._id, label: item.name, service_code: item.service_code })

      ));
      this.setState({
        items: items,
        DataisLoaded: true
      });
    }, (err) => {
      // console.log(" err ", err);
      this.setState({ loadingInitData: false })
    })
    

    // this.loadMarkets()
  }


  // async loadMarkets() {
  //   markets = await binance.load_markets();
  //   console.log(markets);
  //   // markets = await fetch('https://api.paradise.exchange/spot/api/v3.2/market_summary', {
  //   //   method: 'GET',
  //   //   mode: 'cors',
  //   //   headers: {
  //   //     'Content-Type': 'application/json'
  //   //   }
  //   // }).then(response => {
  //   //   console.log(response);
  //   // });
  // }

  async fetchPrices(coins) {
    if (!coins || coins.length < 2) return;

    let coinsArray = []
    let pairArry = []
    this.props.pairs.map((item, index) => {
      pairArry.push(item.pair);
    });
    // console.log("pairArry", pairArry)
    coins.forEach(coin => {

      // console.log("exch : ",this.state.selectedExchange)
      if(this.state.SelectedBotServiceType.service_code === "PORTFOLIO_REBALANCING_BOT"){

        if (this.state.selectedExchange.value.split("::")[1].toLowerCase() == "bitflyer") {
          // console.log("exch 1: ",this.state.selectedExchange)
          if (coin.label && coin.label != 'JPY') {
            if (pairArry.indexOf(coin.label + '-JPY') != -1) {
              coinsArray.push(coin.label + '/JPY')
            }
          }
        } else {
          if (coin.label && coin.label != 'USDT') {
            if (pairArry.indexOf(coin.label + '-USDT') != -1) {
              coinsArray.push(coin.label + '/USDT')
            }
          }
        }
      } else {
        // console.log("exch 2: ",this.state.selectedExchange)
        // if (this.state.selectedExchange.split(":")[1].toLowerCase() == "bitflyer") {
          if (this.state.selectedExchange.label == "bitflyer") {
          if (coin.label && coin.label != 'JPY') {
            if (pairArry.indexOf(coin.label + '-JPY') != -1) {
              coinsArray.push(coin.label + '/JPY')
            }
          }
        } else {
          // console.log("exch 3: ",this.state.selectedExchange)
          if (coin.label && coin.label != 'USDT') {
            if (pairArry.indexOf(coin.label + '-USDT') != -1) {
              coinsArray.push(coin.label + '/USDT')
            }
          }
        }
      }
     


    });

    this.props.onGetPrices({ coinsArray, api_id: this.state.SelectedBotServiceType.service_code === "PORTFOLIO_REBALANCING_BOT" ? this.state.selectedExchange.value.split("::")[0] : this.state.selectedExchange.split(":")[0] }, this.fetchPricesSuccess, this.fetchPricesError)

    // let prices = await paradise.fetchTrades(coinsArray, undefined, 20, {});
    // let prices = await binance.fetchTickers(coinsArray);
    // this.setState({ prices })
  }

  fetchPricesSuccess() {
    this.calculateAssetsAverage()
  }

  fetchPricesError() {
    this.calculateAssetsAverage()
  }


  formatExchanges() {
    const { exchangeAPIs } = this.props;
    if (!exchangeAPIs || !exchangeAPIs.length) return []
    return [
      {
        label: '',
        options: exchangeAPIs.map(api => {
          return { label: api.name + ` (${api.exchange_id?.name})`, value: api._id + "::" + api.exchange_id?.name }
        })
      }
    ]
  }

  formatCoinList() {
    const { coins } = this.props;
    // console.log('coins : ' , coins)
    if (!coins || !coins.length) return []

    return [
      {
        label: '',
        options: coins.map(coin => {
          return { label: coin.short_name, value: coin._id }
        })
      }
    ]
  }

  formatCoinListSelection() {
    const { coinsList } = this.props;
    // console.log('list : ' , coinsList)
    if (!coinsList || !coinsList.length) return []
    return [
    
      {
        label: '',
        options: coinsList.map(coinsList => {
          return { label: coinsList.short_name, value: coinsList._id }
          
        })
      }
    ]
    
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }))
  }

  calculatePairs(selectedCoins) {
    if (!selectedCoins || selectedCoins.length < 2 || !this.props.pairs?.length) return;

    // let existingPairs = {};
    // let coinPairs = [];
    //
    // selectedCoins.forEach((firstCoin, firstIndex) => {
    //   selectedCoins.forEach((secondCoin, secondIndex) => {
    //
    //     if (firstCoin === secondCoin) return;
    //
    //     // Sort pair name alphabetically
    //     let pairLabel = firstCoin.label + '/' + secondCoin.label;
    //     let pairValue = firstCoin.label + '-' + secondCoin.label;
    //     if (secondCoin.value < firstCoin.value) {
    //       pairLabel = secondCoin.label + '/' + firstCoin.label;
    //       pairValue = secondCoin.label + '-' + firstCoin.label;
    //     }
    //
    //     if (existingPairs[pairLabel]) return;
    //     else {
    //       existingPairs[pairLabel] = true;
    //       coinPairs.push({ label: pairLabel, value: pairValue });
    //     }
    //
    //   });
    // });

    // console.log('selectedCoins', selectedCoins);

    const { pairs } = this.props

    let allPossiblePairs = {}
    selectedCoins.forEach(firstCoin => {
      selectedCoins.forEach(secondCoin => {
        allPossiblePairs[`*${firstCoin.label}-${secondCoin.label}*`] = true
        allPossiblePairs[`*${secondCoin.label}-${firstCoin.label}*`] = true
      });
    });

    allPossiblePairs = Object.keys(allPossiblePairs).join(',')

    // console.log('allPossiblePairs', allPossiblePairs);

    let coinPairs = pairs.filter(pair => allPossiblePairs.includes(`*${pair.pair}*`))

    // console.log('coinPairs', coinPairs);

    coinPairs.sort((first, second) => first.pair < second.pair);

    let pairLabels = [{
      label: '',
      options: coinPairs.map(pair => {
        return {
          label: pair.pair.replace('-', '/'),
          value: pair._id
        }
      })
    }]

    this.setState({ pairs: pairLabels })
  }


  calculateAssetsAverage() {
    const { prices } = this.props
    const coins = this.state.selectedCoins?.map(coin => coin.label)
    // console.log("coins ", coins);
    const { balance } = this.props

    let error = '';
    if (!coins || !coins.length) {
      error += this.props.t("No coins.") + ' '
    }
    if (!prices) {
      error += this.props.t("No prices.") + ' '
    }
    if (!balance) {
      error += this.props.t("No balance.") + ' '
    }

    if (error.trim()) {
      this.setState({ error })
      return;
    }
    else {
      this.setState({ error: null })
    }

    let assetsUSDT = {}
    coins.forEach(coin => {
      // console.log("coin", coin);

      // console.log(prices[coin + '/USDT']);
      if (this.state.selectedExchange.value.split("::")[1].toLowerCase() == "bitflyer") {
        if (coin == 'JPY') {
          assetsUSDT[coin] = balance[coin]
        } else {
          assetsUSDT[coin] = balance[coin] * prices[coin + '/JPY']?.price
        }
      } else {
        if (coin == 'USD' || coin == 'USDT') {
          assetsUSDT[coin] = balance[coin]
        } else {
          assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
        }
      }

    });

    let values = Object.values(assetsUSDT)
    let total = values.reduce((x, i) => x + i);
    let assetsAverage = total / values.length;

    this.setState({ assetsUSDT, assetsAverage })

    this.calcBalanceErrors()
    // setTimeout(() => {
    // }, 100)
  }

  coinDifference(coin) {
    const { assetsUSDT, assetsAverage } = this.state

    let difference = assetsUSDT?.[coin] / assetsAverage;
    return difference - 1;
  }

  displayCoinDifference(coin) {
    let difference = this.coinDifference(coin) * 100;
    if (isNaN(difference)) return ' (-- %)'
    let plus = difference > 0 ? '+' : '';
    return ` (${plus}${difference.toFixed(1)}%)`
  }

  isAssetSufficient(coin) {
    let difference = this.coinDifference(coin)

    // e.g. if difference is 0.3 (30%), is the balance
    // between 0.7 (70%) and 1.3 (130%) of average
    return Math.abs(difference) <= BOT_DIFFERENCE;
  }

  calcBalanceErrors() {
    const { assetsUSDT } = this.state
    // console.log("assetsUSDT ", assetsUSDT);
    let errors = [];
    Object.keys(assetsUSDT).forEach(coin => {
      if (!this.isAssetSufficient(coin)) {
        errors.push("[coinName] balance is more than 30% beyond the average.".replace("[coinName]", coin));
      }
    });

    if (!errors.length) errors = "";

    this.setState({ insufficientBalances: errors })
  }

  formatBalanceColor(coin) {
    return
  }

  formatColumnsWidth() {
    const { selectedCoins } = this.state;
    if (!selectedCoins || selectedCoins.length <= 1) return '12';
    else if (selectedCoins.length === 2) return '6';
    else return '4';
  }

  handleExchangeSelect = selectedExchange => {
    this.setState({
      selectedExchange,
      loadingBalance: true
    })
    this.props.onGetBalance(selectedExchange.value.split("::")[0], this.onGetBalanceSuccess, this.onGetBalanceError)
    this.props.onGetCoins({ api_id: selectedExchange.value.split("::")[0], bot_service_id: this.state.SelectedBotServiceType.value },
      (res) => { },
      (err) => { })
    this.props.onGetPairs((res) => { },
      { api_id: selectedExchange.value.split("::")[0], bot_service_id: this.state.SelectedBotServiceType.value  })
  }

  handleCoinSelect = async (selectedCoins, context) => {
    this.setState({ insufficientBalances: null })
    // console.log("context.action", context.action);
    if (this.state.selectedCoins && this.state.selectedCoins.length >= 10) {
      if (context.action === 'remove-value') {
        let localselectedCoins = this.state.selectedCoins.filter(function (obj) {
          return obj.label !== context.removedValue.label;
        });
        this.setState({
          // error: this.props.t("You can not choose more than 10 coins"),
          selectedCoins: localselectedCoins
        })
      }
      else if (context.action === 'clear') {
        this.setState({
          // error: this.props.t("You can not choose more than 10 coins"),
          selectedCoins: [],
          selectedPairs: null,
        })
      }
      else {
        // this.setState({
        //   error: this.props.t("You can not choose more than 10 coins")
        // })
      }
    }
    else {
      await this.fetchPrices(selectedCoins)
      this.calculatePairs(selectedCoins)
      this.setState({
        selectedCoins: selectedCoins ? selectedCoins : [],
        selectedPairs: null,
        // error: ''
      })
    }

    // if (!this.props.prices?.length) {
    //   setTimeout(() => {
    //     this.calculateAssetsAverage()
    //   }, 2000);
    // }
    // else {
    //   this.calculateAssetsAverage()
    // }
  }
  handlePairSelect = selectedPairs => {
    this.setState({ selectedPairs })
  }

  onGetBalanceSuccess(response) {
    this.setState({ loadingBalance: false })
  }

  onGetBalanceError(response) {
    // console.log("onGetBalanceError ", response);
    if (response?.data?.msg) {
      this.setState({
        error: response?.data?.msg,
        loadingBalance: false
      })
    } else {
      this.setState({
        error: response.error,
        loadingBalance: false
      })
    }
  }

  createBot() {
    const {
      name,
      delta,
      selectedExchange,
      selectedCoins,
      selectedPairs,
      selectCoinsList,
    } = this.state
    const { balance } = this.props


    if (!name.trim()) {
      this.setState({ error: this.props.t('Please enter a name.') })
      return;
    }
    if (!delta) {
      this.setState({ error: this.props.t('Please enter a delta amount (% per trade).') })
      return;
    }
    if (this.state.selectedExchange.value.split("::")[1].toLowerCase() == "bitflyer" && (delta % 1 != 0 || delta < 3 || delta > 10)) {
      this.setState({ error: this.props.t('Please enter a delta amount (% per trade) between 3 to 10. Decimal are not allowed') })
      return;
    }
    if (delta % 1 != 0 || delta < 1 || delta > 10) {
      this.setState({ error: this.props.t('Please enter a delta amount (% per trade) between 1 to 10. Decimal are not allowed') })
      return;
    }

    if (!selectedExchange) {
      this.setState({ error: this.props.t('Please select an exchange.') })
      return;
    }
    if (!selectedCoins || selectedCoins.length < 2) {
      this.setState({ error: this.props.t('Please select two or more coins.') })
      return;
    }
    if (!selectedPairs || !selectedPairs.length) {
      this.setState({ error: this.props.t('Please select one or more pairs.') })
      return;
    }


    this.setState({ loading: true });
  

    let coin_details = {};
    selectedCoins.forEach(coin => {
      coin_details[coin.label] = balance[coin.label]
    })
    let newBot = {
      bot_service_id:this.state.SelectedBotServiceType.value,
      name: name,
      api_id: this.state.selectedExchange.value.split("::")[0],
      coins: selectedCoins.map(coin => coin.value),
      pairs: selectedPairs.map(pair => pair.label?.replace('/', '-')),
      delta: delta / 100,
      coin_details
    }

    this.props.onCreateBot(newBot, this.onSuccess, this.onError);
  }

  onSuccess() {
    this.setState({ loading: false })
    this.props.history.push('/bot-list')
    // this.props.history.push('/my-apis')
  }

  onError(response) {
    this.setState({
      loading: false,
      error: response?.msg,
      isAlertOpen:true,
      // error: response.data?.msg,
      success: null,

    
    })
   
  }

  createMMBot=()=>{
    // alert("MMBot Data");
    // console.log("##########this.state", this.state);
    // this.mmb_form.reset();
    // return;
    const {
      mm_name,
      api_key_one,
      api_key_two,
      api_memo_one,
      api_passphrase_one,
      api_secret_one,
      api_secret_two,
      api_memo_two,
      api_passphrase_two,
      pair,
      max_order_count,
      max_buy_order_count,
      max_sell_order_count,
      min_buy_order_count,
      min_sell_order_count,
      order_size,
      order_size_multiplier,
      min_width_percent,
      min_width_percent_increment,
      initial_market_price,
      order_execution_duration,
      SelectedBotServiceType,
      selectedBotType,
      spread,
      delta,
      selectedExchange,
      selectedCoins,
      selectedPairs,
      selectCoinsList,
      orderDuration,
      poll_time,
    } = this.state;
    
    this.setState({ loading: true });

    let coin_details = {};
    // selectedCoins.forEach(coin => {
    //   coin_details[coin.label] = balance[coin.label]
    // })
    // console.log("MMBotTop:", initial_market_sell_price);
    // console.log("Map:", SelectedBotServiceType)
    let newBot = {

      // coin_details: coin_details, 
      exchange_id: selectedExchange,
      spread: spread,
      name: mm_name,
      api_key_one:api_key_one,
      api_key_two:api_key_two,
      api_secret_one:api_secret_one,
      api_secret_two:api_secret_two,
      // pair:pair,
      max_order_count:max_order_count,
      max_buy_order_count:max_buy_order_count,
      max_sell_order_count:max_sell_order_count,
      min_buy_order_count:min_buy_order_count,
      min_sell_order_count:min_sell_order_count,
      order_size:order_size,
      order_size_multiplier:order_size_multiplier,
      min_width_percent:min_width_percent,
      min_width_percent_increment:min_width_percent_increment,
      initial_market_price:initial_market_price,
      order_execution_duration:orderDuration,
  
      // api_id: this.state.selectedExchange.value.split("::")[0],
      bot_service_id : SelectedBotServiceType.value,
      // coins: selectedCoins.map(coin => coin.value),
      coins : selectCoinsList.map(coin=>coin.value),
      pairs: selectedPairs.map(pair => pair.label?.replace('/', '-')),
      delta: delta / 100,
      poll_time:poll_time,
    }
    if(api_memo_one && api_memo_one !== ''){
      newBot['api_memo_one'] = api_memo_one
    }
    if(api_memo_two && api_memo_two !== ''){
      newBot['api_memo_two'] = api_memo_two
    }
    if (api_passphrase_one && api_passphrase_one!==""){
      newBot['api_passphrase_one']=api_passphrase_one
    }
    if (api_passphrase_two && api_passphrase_two!==""){
      newBot['api_passphrase_two']=api_passphrase_two
    }
    // console.log("#####MMBot:", newBot)

    this.props.onCreateBot(newBot, this.onSuccess, this.onError);
    // console.log("selectedBotType:", selectedBotType);
  }

  handleCoinSelectList = async (selectCoinsList, context) => {
    // console.log('selectedcoinlist : ' , selectCoinsList)
    // console.log('context : ' , context)

    // this.props.onGetCoins({api_id :selectedExchange.value.split("::")[0] })
    this.setState({ insufficientBalances: null })
    this.setState({selectCoinsList:null})
   
    if (this.state.selectCoinsList && this.state.selectCoinsList.length >= 10) {
      if (context.action === 'remove-value') {
        let localselectedCoinsList = this.state.selectCoinsList.filter(function (obj) {
          return obj.label !== context.removedValue.label;
        });
        this.setState({
        
          selectCoinsList: localselectedCoinsList
        })
      }
      else if (context.action === 'clear') {
        this.setState({
          selectCoinsList: [],
          // selectedPairs: null,
        })
      }
     
    }
    else {
      await this.fetchPrices(selectCoinsList)
      this.calculatePairs(selectCoinsList)
      this.setState({
        selectCoinsList: selectCoinsList ? selectCoinsList : [],
        // selectedPairs: null,
       
      })
    }

  }


  redirectToAPIs() {
    this.props.history.push('/new-api')
  }

  insufficientBalancesAlert = (insufficientBalances) => {
    return (

      insufficientBalances && insufficientBalances.map((error, index) => {
        let errorFirstWord = error.split(" ")[0];
        let langErrorSentace = error.replace(errorFirstWord, "[coinName]");

        return (
        
            <div className="col-md-6" key={index}>
              <UncontrolledAlert color="danger" role="alert"  >
                {this.props.t(langErrorSentace).replace("[coinName]", errorFirstWord)}
              </UncontrolledAlert>
            </div>
     
        )
      })
    )
  }

  // onChange = e =>{
  //   this.set({[e.target.name]: e.target.label});
  //   console.log("onChange",e.target.value);
  // };
  
  onFetchExchanges() {
    if (this.props.exchanges && this.props.exchanges.length === 1) {
      this.setState({
        selectedExchange: this.props.exchanges[0]._id,
        selectedExchangeName: this.props.exchanges[0].name
      })
    }
  }


  onExchangeChange(e) {
    // console.log('selected e : ----' , e.target.value)
    // console.log('e.id : ' , e.target.value.split(":")[0] )
    let name = e.target.options[e.target.selectedIndex].text;
    let showUidFieldStatus = false;
    this.props.onGetCoins({ api_id: e.target.value.split(":")[0], bot_service_id: this.state.SelectedBotServiceType.value  },
    (res) => { 
    },
    (err) => { })
    // console.log('e details : ' , e )
    // console.log('bott_iddd : ' , this.state.SelectedBotServiceType.value)
    this.props.onGetPairs((res) => { },
    { api_id: e.target.value.split(":")[0], bot_service_id: this.state.SelectedBotServiceType.value  })

    if (e.target.value.split(":")[1] == "bitmart") {
      showUidFieldStatus = true;
    }
    this.setState({
      selectedExchange: e.target.value,
      selectedExchangeName: name,
      showUidField: showUidFieldStatus,
    })

  }

  addAPI() {
    const { selectedExchange, name, apiKey, apiSecret, uid } = this.state;

    if (!selectedExchange?.trim() || !name?.trim() || !apiKey?.trim() || !apiSecret?.trim() || (selectedExchange.split(":")[1] == "bitmart" && !uid?.trim())) {
      this.setState({ error: this.props.t('Please enter all fields.'), success: null })
      return;
    }

    this.setState({ loading: true })

    let newAPI = {
      exchange_id: this.state.selectedExchange.split(":")[0],
      name: this.state.name,
      uid: this.state.uid,
      api_key: this.state.apiKey,
      api_secret: this.state.apiSecret
    }
    this.props.onCreateAPI(newAPI, this.onSuccess, this.onError)
  }

  // checkOptions(e) {
  //   console.log(e)
  // }

  onCheckBotServices = (selectedOption) =>{
    // console.log('@@@@@@@',selectedOption)
    if(selectedOption.service_code==='MARKET_MAKING_BOT'){
      this.setState({
        SelectedBotServiceType:selectedOption
      })
      // console.log('444444444444444444444',selectedOption.value)
      this.props.onGetExchanges(selectedOption.value, this.onFetchExchanges)
      this.handleClear()

    }else{
      this.setState({
        SelectedBotServiceType:selectedOption
      })
      this.props.onGetAPIs()
      this.handleClear()
    }
  }

  handleClear = (e) => {
    // alert("Demo0000");
    this.setState({
      selectedExchange:"",
      selectCoinsList:[],
      selectedPairs:[],
      selectedCoins:[],

    })
  }
  toggleSwitchButton = () => {
    this.setState(prevState=>({ spread: !prevState.spread }));
  }
  toggleOrderSize = () => {
    this.setState({ isEnable: !this.state.isEnable });
  }

  onDismiss=()=>{
    this.setState({isAlertOpen:false})
  }

  render() {
    const {
      selectedPairs,
      selectedCoins,
      exchangesDisplay,
      insufficientBalances,
      assetsUSDT,
      assetsAverage,
      mm_name,
      api_key_one,
      api_key_two,
      api_secret_two,
      pair,
      max_order_count,
      max_buy_order_count,
      max_sell_order_count,
      min_buy_order_count,
      min_sell_order_count,
      order_size,
      order_size_multiplier,
      min_width_percent,
      min_width_percent_increment,
      initial_market_price,

    } = this.state;
    const { balance, exchanges } = this.props;
    // const { exchanges } = this.props;
    

    // const options = [
    //   { value: "PRBot", label: "Portfolio Rebalance Bot" },
    //   { value: "MMBot", label: "MM Bot", className: "myOptionClassName" }
    // ];
    
    const { selectedOption } = this.state;
    const {form, formErrors} = this.state;
    const isEnabled =  mm_name.length > 0 && api_key_one.length > 0;
    console.log(this.state.error)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Logos | New Bot")}</title>
          </MetaTags>
          <Container fluid>
            {this.state.error && (
              <Alert color="danger" role="alert" isOpen={this.state.isAlertOpen} toggle={this.onDismiss}>
                {this.state.error}
              </Alert>
              
            )}
            {this.state.success && (
              <UncontrolledAlert color="success" role="alert">
                {this.state.success}
              </UncontrolledAlert>
              
            )}
              
            <Breadcrumbs
              title={this.props.t("My Bots")}
              breadcrumbItem={this.props.t("Create New Bot")}
              parent="/bot-list"
            />

            <Card className="mx-auto" >
              <CardBody>
                  {this.state.loading?
                    (
                      <Row className="col-12 d-flex flex-column align-items-center justify-content-center" style={{height:"70vh"}}>
                      <Hourglass
                      visible={this.state.loading}
                      height="80"
                      width="80"
                      ariaLabel="hourglass-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      colors={['#306cce', '#72a1ed']}
                    />
                    </Row>
                    ):
                    (
                      <Row className="mt-2">
                        <div className="col-md-4">
                          <Label>Select Bot Type</Label>
                          
                            {/* <select className='form-control' onChange={(e)=>this.setState({SelectBotType:e.target.value})}>
                              <option>Select a Category</option>
                              { options && options.map((c, i) => (<option key={i} value={c.value}>{c.label}</option>)) }
                            </select>  */}
                            {/* {console.log("BotSelection:", this.state.SelectedBotServiceType)} */}
                            <Select 
                                value={this.state.SelectedBotServiceType}
                                onChange = {this.onCheckBotServices}
                                options={this.state.items}
                                // isMulti={''}
                                name="SelectedBotServiceType"
                                classNamePrefix="select2-selection"
                              />
                            {/* <Select
                                  value={this.state.selectedExchange}
                                  onChange = {this.onCheckBotServices}
                                  options={this.state.items}
                                  name="SelectedBotServiceType"
                                  classNamePrefix="select2-selection"
                                  placeholder={this.props.t("Select Exchange")}
                                /> */}
                        </div>
                      
                        <div className="col-md-12">
                          <div className="mb-3">
                          
                            {
                              this.state.SelectedBotServiceType.service_code === "PORTFOLIO_REBALANCING_BOT" &&(
                                
                                  <>
                                    <h4 className="card-title mb-2 mt-4">{this.props.t("New Bot")}</h4>
                    
                                    <div className="crypto-buy-sell-nav">
                                      <Form>
                                      <Row>
                                        <div className="col-md-6">
                                          {this.props.exchangeAPIs?.length ? (
                                            <div className="mb-3 col-md-12">
                                              <Label>{this.props.t("Exchange")}</Label>
                                              <Select
                                                value={this.state.selectedExchange}
                                                onChange={this.handleExchangeSelect}
                                                options={this.formatExchanges()}
                                                classNamePrefix="select2-selection"
                                                placeholder={this.props.t("Select Exchange")}
                                              />
                                          </div>
                                        
                                        ) : (
                                          <div className="mb-3">
                                            <p className="text-muted">{this.props.t("You have not added any API keys.")}</p>
                                          </div>
                                        )}
                                        </div>
                                        <div className="col-md-4 mt-2 pt-3">
                                        
                                        {this.state.selectedExchange && (
                                            <div className="float-start">
                                              <Dropdown
                                                isOpen={this.state.isMenu}
                                                toggle={this.toggleMenu}>
                                                <DropdownToggle
                                                  type="button"
                                                  tag="button"
                                                  className="btn btn-light">
                                                  <i className="mdi mdi-wallet me-1" />
                                                  <span className="d-none d-sm-inline-block ms-1">
                                                    {this.props.t("Wallet Balance")}{" "}
                                                    <i className="mdi mdi-chevron-down" />
                                                  </span>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end dropdown-menu-md"
                                                  style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                                  <div className="dropdown-item-text">
                                                    <div>
                                                      <p className="text-muted mb-2">
                                                        {this.props.t("Available Balance")}
                                                      </p>
                                                    </div>
                                                  </div>
                          
                                                  <DropdownItem divider />
                          
                                                  {balance && Object.keys(balance).map(coin => {
                                                    return balance[coin] ? (
                                                      <DropdownItem href="#" key={coin}>
                                                        {coin}<span className="float-end">{balance[coin]}</span>
                                                      </DropdownItem>
                                                    ) : ''
                                                  })}
                                                </DropdownMenu>
                                              </Dropdown>
                                            </div>
                                          )}
                                        </div>
                                        
                                      <div className="col-md-12">
                                        {this.state.selectedExchange && (
                                          <React.Fragment>
                                            <Row>
                                              <div className="col-md-6">
                                                <div className="mb-3">
                                                  <Label>{this.props.t("Bot Name")}</Label>
                                                  <Input type="text" className="form-control"
                                                    maxLength={30}
                                                    onChange={(e) => this.setState({ name: e.target.value })} 
                                                    value={this.state.name}
                                                    />
                                                </div>
                                              </div>
                                              <div className="col-md-6">

                                                <div className="mb-3">
                                                  <Label>{this.props.t("Delta per Trade (%)")}</Label>
                                                  <Input type="number" className="form-control"
                                                    // defaultValue={3}
                                                    value={this.state.delta}
                                                    onChange={(e) => this.setState({ delta: parseFloat(e.target.value) })}
                                                    onWheel={(e) => e.target.blur()} />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="mb-3 select2-container">
                                                  <label className="control-label">
                                                    {this.props.t("Select Coins")}
                                                  </label>
                                                  <Select
                                                    // value={this.state.selectCoinsList}
                                                    value={this.state.selectedCoins}
                                                    isMulti={true}
                                                    // multi={true}
                                                    isOptionDisabled={() => this.state.selectedCoins.length >= 10}
                                                    onChange={this.handleCoinSelect}
                                                    options={this.formatCoinList()}
                                                    classNamePrefix="select2-selection"
                                                    placeholder={this.props.t("Select Coins")}
                                                    noOptionsMessage={() => this.props.t("No Options")}
                                                  />
                                                  <small className="text-muted">{this.props.t("You can choose maximum 10 coins")}</small>
                                                </div>
                                              </div>
                                            </Row>
                                            
                                              {(selectedCoins?.length >= 2) && (
                                                <React.Fragment>
                                                <Row>
                                                  <Label>{this.props.t("Balance")}</Label>
                                                  <div className="col-md-12">
                                                    <p><span className="text-primary">{this.props.t("Assets Average")}</span> {this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + (this.state.selectedExchange.value.split("::")[1].toLowerCase() == "bitflyer" ? ' JPY' : ' USDT')}</p>
                                                  </div>
                                                </Row>
                                              
                                                  <div className="row mb-3">
                                                    {selectedCoins.map(coin => {
                                                      return (
                                                        // <Col sm={this.formatColumnsWidth()} key={coin.value}>
                                                      
                                                        <div className="col-md-6" key={coin.value}>
                                                          
                                                            <Label className="card-radio-label mb-2">
                                                              <div className="card-radio">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                  <i className={`mdi ${icons[coin.label]} font-size-18 text-warning align-middle me-2`} />{" "}
                                                                  <span>{coin.label}</span>
                                                                  <span style={{
                                                                    marginLeft: 'auto',
                                                                    fontWeight: '400',
                                                                    color: this.isAssetSufficient(coin.label) ? green : red
                                                                  }}>{balance[coin.label] + this.displayCoinDifference(coin.label)}</span>
                                                                </div>
                                                              </div>
                                                            </Label>
                                                          </div>
                                                      
                                                        // </Col>
                                                      )
                                                    })}
                                                  </div>
                                                
                                                </React.Fragment>
                                              )}
                                                  <div className="row">
                                                  {
                                                    this.insufficientBalancesAlert(insufficientBalances)
                                                  }
                                                  </div>

                                              <div className="row">
                                                <div  className="col-md-6">
                                                  {insufficientBalances && (
                                                    <Alert color="danger" role="alert" >
                                                      {this.props.t("Please adjust your portfolio balances and return to this page.")}
                                                    </Alert>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">

                                                  <div className="col-md-6 mb-3 select2-container">
                                                    <label className="control-label">
                                                      {this.props.t("Select Pair")}
                                                    </label>
                                                    <Select
                                                      value={selectedPairs}
                                                      isMulti={true}
                                                      onChange={this.handlePairSelect}
                                                      options={this.state.pairs}
                                                      classNamePrefix="select2-selection"
                                                      placeholder={this.props.t("Select Pair")}
                                                      noOptionsMessage={() => this.props.t("No Options")}
                                                    />
                                                  </div>
                                              </div>
                                            
                                          </React.Fragment>
                                        )}
                                      </div>
                                      

                                        {/* {this.state.error && (
                                          <>
                  
                                          <Alert color="danger" role="alert" className="float-right">
                                            {this.state.error}
                                          </Alert>
                                          </>
                                          
                                        )} */}
                                    

                                        {this.props.exchangeAPIs?.length ? (
                                          <div className="text-center mt-4 mt-4">
                                            <Alert color="warning" role="alert" >
                                              <span className="fw-bold">{this.props.t("Warning")}</span> {this.props.t("If you use the same currency more than three times, it may become negative.")}
                                              <br />
                                              <a href="https://support.logos.market/" target="_blank" rel="noreferrer">
                                                <span className="badge rounded-pill bg-primary float-end"></span>
                                                <span>{this.props.t("How to choose currency pair?")}</span>
                                              </a>
                                              {/* <a target="_blank" rel="noreferrer" href="https://support.atlantisgold.trade/hc/en-us/articles/4417556905875-How-to-choose-currency-pair">{this.props.t("How to choose currency pair?")}</a> */}
                                            </Alert>
                                            <Button type="button" color="success"
                                              onClick={this.createBot}
                                              disabled={
                                                !this.state.selectedExchange ||
                                                  // !this.state.name.trim() ||
                                                  !this.state.delta ||
                                                  !this.state.selectedCoins.length ||
                                                  !this.state.selectedPairs ||
                                                  !this.state.selectedOption ||
                                                  this.state.insufficientBalances != "" ? true : false
                                              }
                                            >
                                              {this.state.loading ? (
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                                              ) : this.props.t("Create Bot")}
                                            </Button><p>&nbsp;</p>
                                          </div>
                                        ) : (
                                          <div className="text-center mt-4">
                                            <Button type="button" color="success"
                                              onClick={this.redirectToAPIs}>
                                              {this.props.t("Add Exchange API Key")}
                                            </Button>
                                          </div>
                                        )}
                                      </Row>
                                      </Form>
                                    </div>
                                  </>
                                
                              )
                            }
                            {
                              // this.state.SelectBotType === this.state.SelectedBotServices &&(
                              this.state.SelectedBotServiceType.service_code === "MARKET_MAKING_BOT" &&(
                                // <>
                                  // {this.state.error && (
                                  //   <Alert color="danger" role="alert" className="col-md-6" isOpen={this.state.isAlertOpen} toggle={this.onDismiss}>
                                  //     {this.state.error}
                                  //   </Alert>
                                    
                                  // )}
                                <AvForm
                                  className="form-horizontal" 
                                  // onSubmit={this.handleClear}
                                  // ref={ref => (this.mmb_form = ref)}
                                  onSubmit={this.handleSubmit}
                                  >
                                  <Row>
                                    <div className="col-md-6">
                                      <div className="mt-3">
                                        <Label>{this.props.t("Exchange")}</Label>
                                        
                                        {/* <select className="form-select form-control" */}
                                        <AvField type="select" name="exchange" helpMessage="" required 
                                          value={this.state.selectedExchange}
                                          onChange={this.onExchangeChange}
                                          
                                          placeholder={this.props.t("Select Exchange")}>
                                          <option value="">{this.props.t("Select Exchange")}</option>
                                          {exchanges && exchanges.length ? exchanges.map(exchange => {
                                            // {console.log("Ex _id :", exchange._id)}
                                            return (
                                              // <option
                                              //   key={exchange._id}
                                              //   value={exchange._id + ":" + exchange.ccxt_id}
                                              // >{exchange.name}</option>
                                              
                                              <option
                                                key={exchange._id}
                                                value={exchange._id}
                                              >{exchange.name}</option>
                                            )
                                          }) : ''}
                                          </AvField>
                                        {/* </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mt-3">
                                        <AvField 
                                          required
                                          type="text"
                                          name="mm_name"
                                          label={this.props.t("Name")}
                                          value={this.state.mm_name}
                                          className="form-control"
                                          placeholder={this.props.t("Enter Name")}
                                          onChange={(e) => this.setState({ mm_name: e.target.value })}
                                          validate={{
                                            required: { value: true, errorMessage: this.props.t("Please Enter Name") }
                                          }}
                                        ></AvField>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <div className="mb-3 select2-container">
                                              <label className="control-label">
                                                {this.props.t("Select Coins")}
                                              </label>
                                              {/* {console.log(this.formatCoinListSelection())} */}
                                              <Select
                                              // multi={''}
                                                // <AvField type="select" name="coins" required helpMessage="Select Coins"
                                                // value={this.state.selectedCoins}
                                                value={this.state.selectCoinsList}
                                                isMulti={true}
                                                onChange={this.handleCoinSelectList}
                                                options={this.formatCoinList()}
                                                isOptionDisabled={() => this.state.selectCoinsList.length >= 2}
                                                classNamePrefix="select2-selection"
                                                placeholder={this.props.t("Select Coins")}
                                                noOptionsMessage={() => this.props.t("No Options")}
                                              />
                                              <span className="note"> <small>Note : Please select only 2 coins</small></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        
                                        {/* <AvField
                                            required
                                            name="pair"
                                            label={this.props.t("Pair")}
                                            value={this.state.pairs}
                                            className="form-control"
                                            placeholder={this.props.t("Pair")}
                                            onChange={(e)=>this.setState({pair:e.target.value})}
                                            validate={{
                                              required:{value:true, errorMessage:this.props.t("Enter Pair")}
                                            }}
                                          /> */}
                                  
                                      <div className="mt-3">
                                        <div className="mb-3 select2-container">
                                          <label className="control-label">
                                              {this.props.t("Select Pair")}
                                            </label>
                                            <Select
                                            // <AvField type="select" name="pair" required helpMessage="Select Pair"
                                              value={selectedPairs}
                                              isMulti={true}
                                              onChange={this.handlePairSelect}
                                              options={this.state.pairs}
                                              // classNamePrefix="select2-selection"
                                              placeholder={this.props.t("Select Pair")}
                                              noOptionsMessage={() => this.props.t("No Options")}
                                            />
                                          </div>
                                      </div>
                                  </div>
                                    
                                    <div className="col-md-6">
                                        <div className="mt-3">

                                          <AvField
                                            required
                                            type="name"
                                            name="api_key_one"
                                            label={this.props.t("Account One API KEY")}
                                            value={this.state.api_key_one}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account One API KEY")}
                                            onChange={(e) => this.setState({ api_key_one: e.target.value })}
                                            validate={{
                                              required: { value: true, errorMessage: this.props.t("Please Add Account One API KEY") }
                                            }}
                                          />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-3">
                                          <AvField
                                            required
                                            type="text"
                                            name="api_secret_one"
                                            label={this.props.t("Account One Private key")}
                                            value={this.state.api_secret_one}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account One Private key")}
                                            onChange={(e) => this.setState({ api_secret_one: e.target.value })}
                                            validate={{
                                              required: { value: true, errorMessage: this.props.t("Please Add Account One Private key") }
                                            }}
                                          />
                                        </div>
                                    </div>
                                    {this.state.selectedExchangeName==='Bitmart'&&(
                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <AvField
                                            required
                                            type="text"
                                            name="api_memo_one"
                                            label={this.props.t("Account One API Memo")}
                                            value={this.state.api_memo_one}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account One API Memo")}
                                            onChange={(e) => this.setState({ api_memo_one: e.target.value })}
                                            validate={{
                                              required: { value: true, errorMessage: this.props.t("Please Add Account One API Memo") }
                                            }}
                                          />
                                        </div>
                                    </div>
                                    )}
                                    {this.state.selectedExchangeName==='Bitget'&&(
                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <AvField
                                            required
                                            type="text"
                                            name="api_passphrase_one"
                                            label={this.props.t("Account One API PassPhrase")}
                                            value={this.state.api_passphrase_one}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account One API PassPhrase")}
                                            onChange={(e) => this.setState({ api_passphrase_one: e.target.value })}
                                            validate={{
                                              required: { value: true, errorMessage: this.props.t("Please Add Account One API PassPhrase") }
                                            }}
                                          />
                                        </div>
                                    </div>
                                    )}
                                    <div className="col-md-6">
                                        <div className="mt-3">
                                          <AvField 
                                            required
                                            type="text"
                                            name="api_key_two"
                                            label={this.props.t("Account Two API Key")}
                                            value={this.state.api_key_two}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account Two API Key")}
                                            onChange={(e)=> this.setState({api_key_two:e.target.value})}
                                            validate={{
                                              required:{value:true, errorMessage:this.props.t("Please Add Account Two API Key")}
                                            }}
                                            ></AvField>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              name="api_secret_two"
                                              type="text"
                                              label={this.props.t("Account Two Private key")}
                                              value={this.state.api_secret_two}
                                              placeholder={this.props.t("Enter Account Two Private key")}
                                              onChange={(e)=>this.setState({api_secret_two:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Enter Account Two Private key")}
                                              }}
                                              className="form-control"
                                            />
                                        </div>
                                    </div>

                                    {this.state.selectedExchangeName==='Bitmart'&&(
                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <AvField
                                            required
                                            type="text"
                                            name="api_memo_two"
                                            label={this.props.t("Account Two API Memo")}
                                            value={this.state.api_memo_two}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account Two API Memo")}
                                            onChange={(e) => this.setState({ api_memo_two: e.target.value })}
                                            validate={{
                                              required: { value: true, errorMessage: this.props.t("Please Add Account Two API Memo") }
                                            }}
                                          />
                                        </div>
                                    </div>
                                    )}
                                      {this.state.selectedExchangeName==='Bitget'&&(
                                      <div className="col-md-6">
                                        <div className="mt-3">
                                          <AvField
                                            required
                                            type="text"
                                            name="api_passphrase_two"
                                            label={this.props.t("Account Two API PassPhrase")}
                                            value={this.state.api_passphrase_two}
                                            className="form-control"
                                            placeholder={this.props.t("Enter Account Two API PassPhrase")}
                                            onChange={(e) => this.setState({ api_passphrase_two: e.target.value })}
                                            validate={{
                                              required: { value: true, errorMessage: this.props.t("Please Add Account Two API PassPhrase") }
                                            }}
                                          />
                                        </div>
                                    </div>
                                    )}
                                    
                                    
                                    <div className="col-md-3">
                                        
                                        <div className="mt-3 cmErrorMsg">
                                        <Label className="pb-2">Order execution Duration in : <span className="text-success text-bold"> {this.state.orderduratuon} Sec</span></Label>
                                        {/* {console.log("this.state.orderDuration", this.state.orderDuration)} */}
                                      
                                        <InputRange
                                            maxValue={60}
                                            minValue={0}
                                            name="order_execution_duration"
                                            value={this.state.orderDuration}
                                            onChange={(value)=> this.setState({orderDuration: value})}
                                            />
                                        <AvField
                                            type="hidden"
                                            name="order_execution_duration"
                                            value={this.state.orderDuration}
                                            onChange={(value)=> this.setState({orderDuration: value})}
                                            validate={{
                                              required:{value:true, errorMessage:this.props.t("Select Order execution Duration")},
                                              // min:{value:2, errorMessage:this.props.t("Select Order execution Duration 2")}
                                            }}
                                          />    
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        
                                        <div className="mt-3 ml-2">
                                        <Label className="pb-2" htmlFor="spreadID">Spread 
                                          <span className="text-success text-bold">
                                          {/* {this.state.spread}  */}
                                          </span></Label>
                                          <SwitchButton type="button" active={this.state.spread} id = "spreadID" clicked={this.toggleSwitchButton} />
                                        {/* {console.log("this.state.spread", this.state.spread)} */}
                                        {/* <InputRange
                                            required
                                            maxValue={60}
                                            minValue={0}
                                            name="spread"
                                            value={this.state.spread}
                                            onChange={(value)=> this.setState({spread: value})}
                                            />
                                          <AvField
                                            type="hidden"
                                            name="spread"
                                            value={this.state.spread}
                                            onChange={(value)=> this.setState({spread: value})}
                                            validate={{
                                              required:{value:true, errorMessage:this.props.t("Select Select Spread")}
                                            }}
                                          />                       */}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="mt-3">
                                          <AvField
                                              // required
                                              type="number"
                                              className="form-control"
                                              name="max_order_count"
                                              readOnly
                                              // max="20"
                                              label={this.props.t("Max Order Count")}
                                              placeholder={this.props.t("Max Order Count")}
                                              value={parseInt(max_buy_order_count) + parseInt(max_sell_order_count)}
                                              // onChange={(e)=>this.setState({max_order_count:e.target.value})}
                                              // validate={{
                                              //   required:{value:true, errorMessage:this.props.t("Max Order Count")},
                                              //   // max:{value:20, errorMessage:this.props.t("Max Order Count 20")},
                                              //   // min:{value:1, errorMessage:this.props.t("Min Order Count 1")}
                                              // }}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <div className="mt-3">
                                          <AvField
                                              required
                                              type="number"
                                              className="form-control"
                                              name="max_buy_order_count"
                                              max="25"
                                              label={this.props.t("Max Buy Order Count")}
                                              placeholder={this.props.t("Max Buy Order Count")}
                                              value={max_buy_order_count}
                                              onChange={(e)=>this.setState({max_buy_order_count:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Max Buy Order Count")},
                                                max:{value:25, errorMessage:this.props.t("Max Buy Order Count 25")},
                                                min:{value:1, errorMessage:this.props.t("Min Buy Order Count 1")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4"> */}
                                    <div className="col-md-3">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              type="number"
                                              className="form-control"
                                              name="max_sell_order_count"
                                              value={max_sell_order_count}
                                              max="25"
                                              label={this.props.t("Max Sell Order Count")}
                                              placeholder={this.props.t("Max Sell Order Count")}
                                              onChange={(e)=>this.setState({max_sell_order_count:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Max Sell Order Count")},
                                                max:{value:25, errorMessage:this.props.t("Max Sell Order Count 25")},
                                                min:{value:5, errorMessage:this.props.t("Min Sell Order Count 5")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3"> */}
                                    <div className="col-md-4">
                                        <div className="mt-3">
                                          <AvField
                                              // required
                                              type="number"
                                              className="form-control border"
                                              name="max_order_count"
                                              readOnly
                                              // max="20"
                                              label={this.props.t("Max Order Count")}
                                              placeholder={this.props.t("Max Order Count")}
                                              value={parseInt(max_buy_order_count) + parseInt(max_sell_order_count)}
                                              // onChange={(e)=>this.setState({max_order_count:e.target.value})}
                                              // validate={{
                                              //   required:{value:true, errorMessage:this.props.t("Max Order Count")},
                                              //   // max:{value:20, errorMessage:this.props.t("Max Order Count 20")},
                                              //   // min:{value:1, errorMessage:this.props.t("Min Order Count 1")}
                                              // }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mt-3">
                                          <AvField
                                              required
                                              type="number"
                                              className="form-control"
                                              name="min_buy_order_count"
                                              value={min_buy_order_count}
                                              max="2"
                                              label={this.props.t("Min Buy Order Count")}
                                              placeholder={this.props.t("Min Buy Order Count")}
                                              onChange={(e)=>this.setState({min_buy_order_count:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Min Buy Order Count")},
                                                max:{value:5, errorMessage:this.props.t("Max Buy Order Count 5")},
                                                min:{value:1, errorMessage:this.props.t("Min Buy Order Count 1")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mt-3">
                                          <AvField
                                              required
                                              type="number"
                                              className="form-control"
                                              name="min_sell_order_count"
                                              max="2"
                                              value={min_sell_order_count}
                                              label={this.props.t("Min Sell Order Count")}
                                              placeholder={this.props.t("Min Sell Order Count")}
                                              onChange={(e)=>this.setState({min_sell_order_count:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Min Sell Order Count")},
                                                max:{value:5, errorMessage:this.props.t("Max Sell Order 5")},
                                                min:{value:1, errorMessage:this.props.t("Min Sell Order 1")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-3">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              type="text"
                                              className="form-control"
                                              name="order_size_multiplier"
                                              min="1"
                                              max="9"
                                              value={order_size_multiplier}
                                              label={this.props.t("Order Size Multiplier")}
                                              placeholder={this.props.t("Order Size Multiplier")}
                                              onChange={(e)=>this.setState({order_size_multiplier:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Order Size Multiplier")},
                                                max:{value:0.9, errorMessage:this.props.t("Max Sell Order 0.9")},
                                                min:{value:0.1, errorMessage:this.props.t("Min Sell Order 0.1")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              type="text"
                                              className="form-control"
                                              name="min_width_percent"
                                              value={min_width_percent}
                                              label={this.props.t("Min Width Percent")}
                                              placeholder={this.props.t("Min Width Percent")}
                                              onChange={(e)=>this.setState({min_width_percent:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Min Width Percent")},
                                                max:{value:0.9, errorMessage:this.props.t("Max Width Percent 0.9")},
                                                min:{value:0.1, errorMessage:this.props.t("Min Width Percent 0.1")},
                                              }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-3">
                                          <AvField
                                              required
                                              type="text"
                                              className="form-control"
                                              name="min_width_percent_increment"
                                              value={min_width_percent_increment}
                                              label={this.props.t("Min Width Percent Increment")}
                                              placeholder={this.props.t("Min Width Percent Increment")}
                                              onChange={(e)=>this.setState({min_width_percent_increment:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Min Width Percent Increment")},
                                                max:{value:0.9, errorMessage:this.props.t("Max Width Percent Increment 0.9")},
                                                min:{value:0.1, errorMessage:this.props.t("Min Width Percent Increment 0.1")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              type="text"
                                              className="form-control"
                                              name="initial_market_price"
                                              value={initial_market_price}
                                              label={this.props.t("Initial market price")}
                                              placeholder={this.props.t("Initial Market Price")}
                                              onChange={(e)=>this.setState({initial_market_price:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Initial Market Price")}
                                              }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              type="text"
                                              className="form-control"
                                              name="initial_market_sell_price"
                                              label={this.props.t("Initial Market Sell Price")}
                                              placeholder={this.props.t("Initial Market Sell Price")}
                                              onChange={(e)=>this.setState({initial_market_sell_price:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Initial Market Sell Price")}
                                              }}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-3">
                                        <div className="mt-3">
                                            <AvField
                                              required
                                              type="text"
                                              className="form-control"
                                              name="poll_time"
                                              label={this.props.t("poll time in milliseconds")}
                                              placeholder={this.props.t("Poll time in milliseconds")}
                                              onChange={(e)=>this.setState({poll_time:e.target.value})}
                                              validate={{
                                                required:{value:true, errorMessage:this.props.t("Initial Market Sell Price")}
                                              }}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 text-center mt-4">
                                      <Button color="success" 
                                      disabled={!isEnabled} 
                                      type="submit" 
                                      onClick={this.createMMBot} className="btn btn-primary" >Create MM Bot
                                      </Button>
                                    </div>
                                  </Row>
                                </AvForm>
                                // </>
                                
                              )
                              
                            }
                          </div>
                        </div>
                      </Row>
                    )
                  }
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateBot.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  exchangeAPIs: PropTypes.array,
  balance: PropTypes.object,
  prices: PropTypes.object,
  coins: PropTypes.array,
  pairs: PropTypes.array,
  exchanges: PropTypes.array,
  onGetAPIs: PropTypes.func,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
  onGetCoins: PropTypes.func,
  onGetPairs: PropTypes.func,
  onCreateBot: PropTypes.func,
  onGetExchanges: PropTypes.func,
  onCreateAPI: PropTypes.func,
  coinsList: PropTypes.array,
  match: PropTypes.any,
  onGetBotServices: PropTypes.func,
  botServices: PropTypes.array
}

const mapStateToProps = state => ({
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
  balance: state.user.balance,
  prices: state.user.prices,
  coins: state.bots.coins,
  pairs: state.bots.pairs,
  exchanges: state.exchangeAPI.exchanges,
  coinsList:state.bots.coinsList
})

const mapDispatchToProps = dispatch => ({
  onGetAPIs: (onFetch) => dispatch(getAPIs(onFetch)),
  onGetBalance: (botID, onSuccess, onError) => dispatch(getBalance(botID, onSuccess, onError)),
  onGetPrices: (data, onSuccess, onError) => dispatch(getPrices(data, onSuccess, onError)),
  onGetCoins: (page, onSuccess, onError) => dispatch(getCoins(page, onSuccess, onError)),
  onGetPairs: (onFetch, page) => dispatch(getPairs(onFetch, page)),
  onCreateBot: (bot, onSuccess, onError) => dispatch(createBot(bot, onSuccess, onError)),
  onGetExchanges: (botService_Id, onFetch) => dispatch(getExchanges(botService_Id,onFetch)),
  onCreateAPI: (api, onSuccess, onError) => dispatch(createAPI(api, onSuccess, onError)),
  onGetBotServices: (page, onSuccess, onError) => dispatch(getBotServices(page, onSuccess, onError))

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CreateBot)))
