import { all, fork } from "redux-saga/effects"

import authSaga from "./auth/saga"
import botsSaga from "./bots/saga"
import exchangeAPISaga from "./exchangeAPI/saga"
import userSaga from "./user/saga"
import superAdminSaga from "./superAdmin/saga"

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(botsSaga),
    fork(exchangeAPISaga),
    fork(userSaga),
    fork(superAdminSaga),
  ])
}
