import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {  } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getAPIs,
  deleteAPI,
} from "store/actions"


class MyAPIs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: null,
      error: null,
      modal: false,
      deleteModalVisible: false,
      deletingAPI: null,
      shownSecrets: {},
      exchangeAPIs: []
    }

    this.toggle = this.toggle.bind(this)

    this.onToggleDeleteAPI = this.onToggleDeleteAPI.bind(this)
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.onConfirmDeleteAPI = this.onConfirmDeleteAPI.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
    this.onDeleteError = this.onDeleteError.bind(this)

    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.onToggleShowSecret = this.onToggleShowSecret.bind(this)
    this.renderColumns = this.renderColumns.bind(this)
  }

  toLowerCase1(str) {
    return str.toLowerCase()
  }

  componentDidMount() {
    const { exchangeAPIs, onGetAPIs } = this.props
    // if (exchangeAPIs && !exchangeAPIs.length) {
      onGetAPIs()
    // }
    this.setState({ exchangeAPIs })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { exchangeAPIs } = this.props
    if (!isEmpty(exchangeAPIs) && size(prevProps.exchangeAPIs) !== size(exchangeAPIs)) {
      this.setState({ exchangeAPIs: {}, isEdit: false })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  onToggleShowSecret(api) {
    // let updatedShownSecrets = {};
    // for (let id in this.state.shownSecrets) {
    //   if (id === api.id) updatedShownSecrets[id] = !this.state.shownSecrets[id];
    //   else updatedShownSecrets[id] = this.state.shownSecrets[id];
    // }
    // if (updatedShownSecrets[api.id] === undefined) updatedShownSecrets[api.id] = true;

    this.setState(prevState => ({
      shownSecrets: {
        ...prevState.shownSecrets,
        [api._id]: !prevState.shownSecrets[api._id]
      }
    }));
  }


  renderColumns() {
    return [
      {
        dataField: "name",
        text: this.props.t("Name"),
      },
      {
        dataField: "exchange_id.name",
        text: this.props.t("Exchange"),
      },
      {
        dataField: "key",
        text: this.props.t("API Key"),
      },
      {
        dataField: "secret",
        text: this.props.t("API Secret"),
        formatter: (cellContent, row, rowIndex, shownSecrets) => (
          <span>
            <Button
              type="button"
              color={shownSecrets[row._id] ? 'primary' : 'success'}
              onClick={() => this.onToggleShowSecret(row)}
            >
              {shownSecrets[row._id] ? this.props.t('Hide') : this.props.t('Show')}
            </Button>
            {' '}{ row.secret ?
              (shownSecrets[row._id] ? row.secret : row.secret.replace(/./g, '•')) :
              '' }
          </span>
        ),
        formatExtraData: this.state.shownSecrets
      },
      {
        dataField: "",
        text: this.props.t("Actions"),
        formatter: (cellContent, row) => (
          <Button
            type="button"
            color="danger"
            onClick={() => this.onToggleDeleteAPI(row)}
          >
            {this.props.t("Delete")}
          </Button>
        )
      }
    ]
  }



  onToggleDeleteAPI(api) {
    this.setState({ deletingAPI: api })
    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.setState({ deleteModalVisible: !this.state.deleteModalVisible })
  }

  onConfirmDeleteAPI() {
    this.props.onDeleteAPI(this.state.deletingAPI, this.onDeleteSuccess, this.onDeleteError)
    this.setState({ deleteModalVisible: false })
  }

  onDeleteSuccess(response) {
    this.setState({ success: this.props.t("API successfully deleted.") })
  }

  onDeleteError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error") })
    }
    else {
      this.setState({ error: response.error })
    }
  }


  deleteAPIModal() {
    return (
      <Modal
        isOpen={this.state.deleteModalVisible}
        toggle={this.toggleDeleteModal}
      >
        {/* <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Delete API Connection</h5>
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}

        <div className="modal-body">
          <h5>{this.props.t("Delete this API Connection?")}</h5>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
            </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onConfirmDeleteAPI}
          >
            {this.props.t("Delete")}
            </button>
        </div>
      </Modal>
    )
  }


  render() {
    const { exchangeAPIs } = this.props

    let exchangeAPIColumns = this.renderColumns();

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: exchangeAPIs.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Logos | My Exchanges")}</title>
          </MetaTags>
          {this.deleteAPIModal()}
          <Container fluid>
            <Breadcrumbs title={this.props.t("My APIs")} />
            <Row className="mb-2">
              <Col sm="4">
              </Col>
              <Col sm="8">
                <div className="text-sm-end">
                  <Link to="/new-api">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded mb-2 me-2"
                    >
                      <i className="mdi mdi-plus me-1" />{" "}
                      {this.props.t("Connect API")}
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title" style={{justifyContent:"space-between",display:"flex", flexDirection:"row"}}>
                  <p>{this.props.t("My Exchange APIs")}</p>
                </div>

                {this.state.success && (
                  <Alert color="success" role="alert">
                    {this.state.success}
                  </Alert>
                )}

                {this.state.error && (
                  <Alert color="danger" role="alert">
                    {this.state.error}
                  </Alert>
                )}

                {exchangeAPIs && exchangeAPIs?.length ? (
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={exchangeAPIColumns}
                    data={exchangeAPIs}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={exchangeAPIs}
                        columns={exchangeAPIColumns}
                        bootstrap4
                        search
                      >
                        {/* selectRow={selectRow} */}
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                defaultSorted={defaultSorted}
                                bordered={true}
                                striped={true}
                                keyField="_id"
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                ) : (
                  <div>
                    <p className="text-muted">{this.props.t("You have not added any API keys.")}</p>
                  </div>
                )}
                <h6>Note : In the list of exchange api’s page as this list is only for Portfolio Rebalance bot.</h6>
              </CardBody>
              
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

MyAPIs.propTypes = {
  t: PropTypes.any,
  exchangeAPIs: PropTypes.array,
  onGetAPIs: PropTypes.func,
  onDeleteAPI: PropTypes.func,
}

const mapStateToProps = state => ({
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
})

const mapDispatchToProps = dispatch => ({
  onGetAPIs: () => dispatch(getAPIs()),
  onDeleteAPI: (api, onSuccess, onError) => dispatch(deleteAPI(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(MyAPIs)))
