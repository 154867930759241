/* eslint-disable */

import React, { Component } from "react"
import PropTypes from 'prop-types'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import AuthHeader from "../../navigation/AuthHeader"

import { AvField, AvForm } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logos_logo_color.png"

import { login, register } from "store/actions"

import * as CryptoJS from 'crypto-js'
import secret from 'secretConfig'

import Footer from './../../navigation/Footer';

import ReCaptcha from 'react-google-recaptcha';
import { recaptchaKey } from '../../helpers/api_helper';
import auth_bg from "../../assets/images/auth_bg.jpg";

class Register extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,
      complete: false,

      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      referralCode: '',
      recaptcha: '',
      recaptchaError: ''
    }

    this.onRecaptchaChange = this.onRecaptchaChange.bind(this)

    this.onClickRegister = this.onClickRegister.bind(this)
    this.onRegisterSuccess = this.onRegisterSuccess.bind(this)
    this.onRegisterFailure = this.onRegisterFailure.bind(this)
    this.redirectToDashboard = this.redirectToDashboard.bind(this)
  }

  componentDidMount() {
    let code = this.props.match.params.code
    if (code) {
      this.setState({ referralCode: code })
    }
  }

  checkRecaptchaError() {
    if (this.state.recaptcha) {
      this.setState({
        recaptchaError: ""
      })
      return true;
    } else {
      this.setState({
        recaptchaError: this.props.t("Recaptcha is required")
      })
      return false;
    }
  }

  onSubmit = e => {
    this.checkRecaptchaError()
  };


  onRecaptchaChange(value) {
    this.setState({ recaptcha: value })
    this.checkRecaptchaError();
  }

  onClickRegister(event, values) {
    if (this.checkRecaptchaError()) {
      const { password, confirmPassword } = this.state

      if (password !== confirmPassword) {
        this.setState({ error: this.props.t("Passwords do not match.") })
        return;
      }

      if (!password.match(/[A-Z]/) ||
        !password.match(/[a-z]/) ||
        !password.match(/[0-9]/) ||
        !password.match(/[!@#$%^&*()_+=-?]/)) {
        this.setState({ error: this.props.t("Password must include an uppercase and lowercase letter, a number, and a special character.") })
        return;
      }

      this.setState({ loading: true })

      const userData = {
        name: values.name,
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword,
        username: 'username',
        terms_cond: true,
        referral_code: values.referralCode,
        recaptcha_response: this.state.recaptcha,
      }
      this.props.onRegister(userData, this.onRegisterSuccess, this.onRegisterFailure)
      this.onRegisterSuccess()
      window.grecaptcha.reset();
    }
  }

  onRegisterSuccess(response) {
    this.setState({
      loading: false,
      success: this.props.t("Registration successful! Please check your email for verification."),
      error: null,
      complete: true,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      referralCode: '',
      recaptcha: ''
    })
  }

  onRegisterFailure(response) {
    this.setState({
      loading: false,
      error: response.data?.msg,
      recaptcha: ''
    })
  }

  redirectToDashboard() {
    this.props.history.push('/login')
  }

  render() {
    return (
      <div style={{"backgroundImage": "url("+auth_bg+")", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
        <AuthHeader />
        {/*
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="bx bx-home h2" />
            </Link>
          </div>
          */}

        <div className="account-pages mb-0" style={{minHeight: "100vh"}}>
          <Container>
            <Row className="justify-content-center">
              <Col md={6} lg={5} xl={4}>

                {/* <div style={{ width: '180px', margin: 'auto', marginBottom: '2rem' }}>
                  <img src={logo} width="100%" />
                </div> */}

                <Card className="overflow-hidden" >

                  <div className="bg-primary bg-soft">
                   <Row>
                      <Col className="col-8 col-md-8">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{this.props.t("Welcome to Logos!")}</h5>
                        </div>
                      </Col>
                      <Col className="col-4 col-md-4">
                        <img src={logo} width="80" className="p-2"/>
                        {/* <img src={profile} alt="" className="img-fluid" /> */}
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0">

                    {/*
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={lightlogo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                        <Link to="/" className="auth-logo-dark">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      */}

                    <div className="p-3 pt-4">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.onClickRegister}
                        onInvalidSubmit={this.onSubmit}
                      >
                        <h5 className="text-center mb-2">{this.props.t("Register")}</h5>

                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : ''}


                        {!this.state.complete && (
                          <React.Fragment>
                            <div className="mb-3">
                              <AvField
                                required
                                type="name"
                                name="name"
                                label={this.props.t("Name")}
                                value={this.state.name}
                                className="form-control"
                                placeholder={this.props.t("Enter your name")}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                validate={{
                                  required: { value: true, errorMessage: this.props.t("Please enter name") }
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                required
                                type="email"
                                name="email"
                                label={this.props.t("Email")}
                                value={this.state.email}
                                className="form-control"
                                placeholder={this.props.t("Email")}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                validate={{
                                  email: { value: true, errorMessage: this.props.t("Please enter valid email id") },
                                  required: { value: true, errorMessage: this.props.t("Please enter email id") }
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                required
                                type="password"
                                name="password"
                                label={this.props.t("Password")}
                                value={this.state.password}
                                placeholder={this.props.t("Password")}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                validate={{ 
                                  pattern: {value: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*., ?])', errorMessage: this.props.t("Password must include an uppercase and lowercase letter, a number, and a special character.") },
                                  required: { value: true, errorMessage: this.props.t("Please enter password") }
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                required
                                type="password"
                                name="confirmPassword"
                                label={this.props.t("Confirm Password")}
                                value={this.state.confirmPassword}
                                placeholder={this.props.t("Confirm Password")}
                                onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                                validate={{
                                  match: { value: 'password', errorMessage: this.props.t("Password does not match") },
                                  required: { value: true, errorMessage: this.props.t("Please confirm password") }
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                required
                                type="text"
                                name="referralCode"
                                label={this.props.t("Referral Code")}
                                value={this.state.referralCode}
                                placeholder={this.props.t("Referral Code")}
                                onChange={(e) => this.setState({ referralCode: e.target.value })}
                                validate={{
                                  required: { value: true, errorMessage: this.props.t("Please enter referral code") }
                                }}
                              />
                            </div>
                            <center>
                            <ReCaptcha style={{transform:"scale(0.93)", transformOrigin:"0 0"}}
                              sitekey={recaptchaKey}
                              onChange={this.onRecaptchaChange}
                            />
                            </center>
                            {
                              this.state.recaptchaError ?
                                <div style={{ "width": "100%", "marginTop": "0.25rem", "fontSize": "80%", "color": "#f46a6a" }}>
                                  {this.state.recaptchaError}
                                </div>
                                :
                                null
                            }

                            <div className="my-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {this.state.loading ? (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                                ) : this.props.t("Register")}
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        {this.state.success && (
                          <Alert color="success" role="alert">
                            {this.state.success}
                          </Alert>
                        )}

                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                      </AvForm>
                    </div>
                    <div className="mt-0 text-center">
                  <p>
                    {this.props.t("Already have an account?")}
                    <Link
                      to="/login"
                      className="fw-medium text-primary"
                    >
                      {" "}
                      {this.props.t("Sign In")}{" "}
                    </Link>{" "}
                  </p>
                </div>
                  </CardBody>
                </Card>

                
              </Col>
            </Row>
            <Footer auth={true} />
          </Container>
        </div>
        </div>
    )
  }
}

Register.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onRegister: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onRegister: (userData, onSuccess, onError) => dispatch(register(userData, onSuccess, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Register)))
