import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
  Alert
} from "reactstrap"
import "assets/scss/datatables.scss"

//Import Breadcrumb
import Breadcrumbs from "navigation/Breadcrumb"
import WalletActivities from "./walletActivities"
import WalletOverView from "./walletOverView"
import { withTranslation } from "react-i18next"

import token_image_src from "assets/images/usdtbig.png"

import { wallet, orders } from './stubWallet'
import {
  createWallet,
  getTransactionHistory,
  getMe
} from "store/actions"


class Wallet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,
      walletCreated: false,

      isMenu: false,
      activeTab: "1",
    }

    this.createWallet = this.createWallet.bind(this)
    this.onCreateWalletSuccess = this.onCreateWalletSuccess.bind(this)
    this.onCreateWalletError = this.onCreateWalletError.bind(this)

    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentDidMount() {
    this.props.onGetMe((res) => { }, (error) => { });
    this.props.onGetTransactionHistory('USDT')
  }

  createWallet() {
    this.setState({ loading: true })
    this.props.onCreateWallet(this.onCreateWalletSuccess, this.onCreateWalletError)
  }

  onCreateWalletSuccess(response) {
    this.setState({
      loading: false,
      walletCreated: true,
      success: this.props.t("Wallet created successfully!")
    })

    setTimeout(() => {
      window.location?.reload()
    }, 1500)
  }

  onCreateWalletError(response) {
    this.setState({
      loading: false,
      error: response?.data?.msg
    })
  }



  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }))
  }

  createWalletAlert() {
    return (
      <Row>
        <Card>
          <CardBody>
            {!this.state.walletCreated ? (
              <React.Fragment>
                <p className="text-danger text-center">{this.props.t("Your wallet has not been setup. Click the button below to create your wallet.")}</p>
                <div className="text-center mt-4">
                  <Button type="button" color="primary"
                    onClick={this.createWallet}
                  >
                    {this.state.loading ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                    ) : this.props.t("Create Wallet")}
                  </Button>

                  {this.state.error && (
                    <Alert color="danger" role="alert" className="mt-3">
                      {this.state.error}
                    </Alert>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <Alert color="success" role="alert">
                {this.state.success}
              </Alert>
            )}
          </CardBody>
        </Card>
      </Row>
    )
  }

  render() {
    const { user } = this.props
    const { activeTab, isMenu } = this.state

    let balance = user?.address?.confirmed_balance

    let hasNoWallet = this.props.user && !this.props.user?.address

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Logos | Wallet")}</title>
          </MetaTags>
          <Container fluid>

            <Breadcrumbs title={this.props.t("Wallet")} />

            {hasNoWallet && this.createWalletAlert()}

            {!isEmpty(wallet) && (
              <Row>
                <Card>
                  <CardBody className="p-0">
                    <Row>
                      <Col md="4" sm="12" className="d-flex align-items-center p-3 border">
                        <Media>
                          <div className="me-4">
                            <i className="mdi mdi-account-circle text-primary h1" />
                          </div>

                          <Media body>
                            <div className="text-muted">
                              <h5>{user?.name}</h5>
                              <p className="mb-1">{user?.email}</p>
                            </div>
                          </Media>

                          {/*
                          <Dropdown isOpen={isMenu} toggle={this.toggleMenu} className="ms-2">
                            <DropdownToggle tag="a" className="text-muted">
                              <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem href="#">Action</DropdownItem>
                              <DropdownItem href="#">Another action</DropdownItem>
                              <DropdownItem href="#">Something else</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          */}
                        </Media>
                      </Col>

                      <Col md="4" sm="12" className="p-3 border">
                        <div>
                          <p className="text-muted mb-2">{this.props.t("Available Balance")}</p>
                          <div className="d-flex align-items-center mt-3">
                            <div className="me-3 align-self-center">
                              {/*<i className="mdi mdi-bitcoin h2 text-warning mb-0" />*/}
                              <img src={token_image_src} style={{
                                height: '32px',
                                weight: '32px'
                              }} />
                            </div>
                              <h5 className="mb-0">
                                {balance != null ? balance + ' '+this.props.user?.address?.wallet_type : '--'}
                              </h5>
                          </div>
                        </div>
                      </Col>

                      <Col md="4" sm="12" className="p-3 border">
                        <p className="text-muted mb-0">{this.props.t("Transfer Funds")}</p>
                        <div className="text-center">
                          <Row>
                            <Col sm="6">
                              <div className="mt-3">
                                <Link to="/transfer/withdraw" className="btn btn-primary btn-sm w-md">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <i className="font-size-24 my-1 me-2 bx bx-send" />
                                    <p className="mb-0">{this.props.t("Withdraw")}</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                            <Col sm="6">
                              <div className="mt-3">
                                <Link to="/transfer/deposit" className="btn btn-primary btn-sm w-md">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <i className="font-size-24 my-1 me-2 bx bx-import" />
                                    <p className="mb-0">{this.props.t("Deposit")}</p>
                                  </div>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {/*
                <Col xl="8">
                  <Card className="mini-stats-wid">
                    <CardBody>

                    </CardBody>
                  </Card>

                  <WalletOverView wallet={wallet} />
                </Col>
                */}
              </Row>
            )}
            <Row>
              <WalletActivities
                walletHistory={this.props.walletHistory}
                activeTab={activeTab}
                toggleTab={this.toggleTab}
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Wallet.propTypes = {
  t: PropTypes.any,
  user: PropTypes.object,
  walletHistory: PropTypes.any,
  onCreateWallet: PropTypes.func,
  onGetTransactionHistory: PropTypes.func,
  onGetMe: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  walletHistory: state.user?.walletHistory,
})

const mapDispatchToProps = dispatch => ({
  onCreateWallet: (onSuccess, onError) => dispatch(createWallet(onSuccess, onError)),
  onGetTransactionHistory: (coin, onSuccess, onError) => dispatch(getTransactionHistory(coin, onSuccess, onError)),
  onGetMe: (onSuccess, onError) => dispatch(getMe(onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Wallet)))
