import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"

import store from '../store'


// Dev
// const API_URL = "http://192.168.1.70:9050/"
// const API_URL = "http://328d-223-230-29-200.ngrok-free.app/"
// const API_URL = "http://192.168.1.69:9050/"

// Testing
// const API_URL = "http://128.199.129.139:9050/"
// const API_URL="http://logos.ceewen.xyz:9050/"

// Production
const API_URL = "https://api.logos.market/"



const axiosApi = axios.create({
  baseURL: API_URL + 'api/'
})


// ------------------
// STUB

export function stub(response, ms, isError) {
  return new Promise((resolve, reject) => {
    if (isError) {
      setTimeout(() => {
        reject(response)
      }, ms)
    }
    else {
      setTimeout(() => {
        resolve(response)
      }, ms)
    }
  })
}


// ------------------
// TOKEN & IP

export function setToken(token) {
  axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + token;
}
export function setLangHeader() {
  axiosApi.defaults.headers.common["Accept-Language"] = localStorage.getItem("I18N_LANGUAGE");
}

// export function setIP(ip) {
//   axiosApi.defaults.headers.common["ip-address"] = ip;
// }

if (window.localStorage.getItem('token')) {
  setToken(window.localStorage.getItem('token'))
}

function logout() {
  // console.log("logout called");
  setToken('')
  // console.log("logout called after settoken header");
  window.localStorage.setItem('token', '')
  // console.log("logout called after settoken localStorage");
  window.location.href = '/login'
  // console.log("logout redirect");
}

// ------------------


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  setLangHeader();
  return await axiosApi.get(url, { ...config })
  .then(response => response.data)
  .catch(error => {
    if (error.response.status == 401) {
      // console.log('401 Unauthorized. Logout1.');
      logout()
    }
    throw error;
  })
}

export async function post(url, data, config = {}) {
  setLangHeader();
  // const ip = store.getState().user?.ip;
  // console.log('ip', ip);
  // let params = { ...data };
  // if (ip) params.ip_address = ip;
  // console.log("post url I18N_LANGUAGE ", url, localStorage.getItem("I18N_LANGUAGE"));
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error?.response?.status == 401) {
        // console.log('401 Unauthorized. Logout2.');
        logout()
      }
      throw error;
    })
}

export async function apiPut(url, data, config = {}) {
  setLangHeader();
  // const ip = store.getState().user?.ip;
  // console.log('ip', ip);
  // let params = { ...data };
  // if (ip) params.ip_address = ip;

  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status == 401) {
        // console.log('401 Unauthorized. Logout3.');
        logout()
      }
      throw error;
    })
}

export async function del(url, config = {}) {
  setLangHeader();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status == 401) {
        // console.log('401 Unauthorized. Logout4.');
        logout()
      }
      throw error;
    })
}



export const recaptchaKey = '6LeZh4MUAAAAAGYX7WUwi1Dji8khGPAJoVl7nB4Q'
