import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import CompanySidebarContent from "./CompanySidebarContent"

import logo from "../../assets/images/white_logo.png"

class CompanySidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">

          <Link to="/exchanges" className="mt-4">
            <div className="artemis-logo">
              <img src={logo} width="100%"/>
            </div>
          </Link>

          <div data-simplebar className="h-100 pt-5">
            <CompanySidebarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(CompanySidebar))
