import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getBotServices,
  deleteBotService,
  changeStatusBotService
} from "store/actions"


class BotServices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      botServices: [],
      loadingInitData: true
    }
    this.onConfirmDeleteBotService = this.onConfirmDeleteBotService.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
    this.onDeleteError = this.onDeleteError.bind(this)
    this.onConfirmChangeStatusBotService = this.onConfirmChangeStatusBotService.bind(this)
    this.onChangeStatusSuccess = this.onChangeStatusSuccess.bind(this)
    this.onChangeStatusError = this.onChangeStatusError.bind(this)

  }

  componentDidMount() {
    const { botServices, onGetBotServices } = this.props
    // if (botServices && !botServices.length) {
    onGetBotServices({
      skip: 0,
      limit: 10
    }, (res) => {
      this.setState({ botServices: res.data, loadingInitData: false })
    }, (err) => {
      this.setState({ loadingInitData: false })
    })
    // }
  }



  renderColumns() {
    return [
      {
        dataField: "name",
        text: this.props.t("Name"),
      },
      // {
      //   dataField: "is_active",
      //   text: this.props.t("Status"),
      //   formatter: (cellContent, row) => (
      //     row.is_active ? "Active" : "Inactive"
      //   )
      // },
      {
        dataField: "created_date",
        text: this.props.t("Created Date"),
        formatter: (cellContent, row) => (
          new Date(row?.created_date)?.toLocaleString()
        )
      },
      {
        dataField: "is_active",
        text: this.props.t("Actions"),
        formatter: (cellContent, row) => (
          <Button
            type="button"
            color={row.is_active ? "danger" : "success"}
            onClick={() => this.changeStatusBotService(row)}
          >
            {row.is_active ? this.props.t("De-activate") : this.props.t("Activate")}
          </Button>
        )
      }
    ]
  }

  deleteBotService(botService) {

    if (window.confirm(this.props.t("Are you sure you want to delete [botName]?").replace("[botName]", botService.name))) {
      this.onConfirmDeleteBotService(botService)
    }
  }

  onConfirmDeleteBotService(botService) {
    this.props.onDeleteBotService(botService, this.onDeleteSuccess, this.onDeleteError)
  }

  onDeleteSuccess(response) {
    this.setState({ success: this.props.t("Bot Service successfully deleted."), error: '' })
  }

  onDeleteError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error"), success: '' })
    }
    else {
      this.setState({ error: response.error, success: '' })
    }
  }
  changeStatusBotService(botService) {

    if (window.confirm(this.props.t("Are you sure you want to change status of [botName]?").replace("[botName]", botService.name))) {
      this.onConfirmChangeStatusBotService(botService)
    }
  }

  onConfirmChangeStatusBotService(botService) {
    this.props.onChangeStatusBotService(botService, this.onChangeStatusSuccess, this.onChangeStatusError)
  }

  onChangeStatusSuccess(response) {
    let localBotService = this.state.botServices;
    localBotService.map((item, index) => {
      if (item._id == response.botService._id) {
        if (response.botService.is_active) {
          localBotService[index].is_active = false;
        } else {
          localBotService[index].is_active = true;

        }
      }
    })
    this.setState({ botServices: localBotService, success: this.props.t("Bot Service successfully change Status."), error: '' })
  }

  onChangeStatusError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error"), success: '' })
    }
    else {
      this.setState({ error: response, success: '' })
    }
  }


  render() {
    const { botServices } = this.state

    let exchangeAPIColumns = this.renderColumns();

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: botServices.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={this.props.t("Bot Services")} />
            {/* <Row className="mb-2">
              <Col sm="4">
              </Col>
              <Col sm="8">
                <div className="text-sm-end">
                  <Link to="/createBotService">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded mb-2 me-2"
                    >
                      <i className="mdi mdi-plus me-1" />{" "}
                      {this.props.t("Add Bot Services")}
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row> */}
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                  <p>{this.props.t("Bot Services")}</p>
                </div>

                {this.state.success && (
                  <Alert color="success" role="alert">
                    {this.state.success}
                  </Alert>
                )}

                {this.state.error && (
                  <Alert color="danger" role="alert">
                    {this.state.error}
                  </Alert>
                )}

                {
                  !this.state.loadingInitData ?
                    botServices && botServices?.length ? (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={exchangeAPIColumns}
                        data={botServices}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={botServices}
                            columns={exchangeAPIColumns}
                            bootstrap4
                            search
                          >
                            {/* selectRow={selectRow} */}
                            {toolkitProps => (
                              <React.Fragment>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={true}
                                    striped={true}
                                    keyField="_id"
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                  />
                                </div>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                        <div>
                          <p className="text-muted">{this.props.t("You have not added any Bot Services.")}</p>
                        </div>
                      )
                    :
                    <div>
                      <p className="text-muted">{this.props.t("Loading...")}</p>
                    </div>
                }
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

BotServices.propTypes = {
  t: PropTypes.any,
  botServices: PropTypes.array,
  onGetBotServices: PropTypes.func,
  onDeleteAPI: PropTypes.func,
  onDeleteBotService: PropTypes.func,
  onChangeStatusBotService: PropTypes.func,
}

const mapStateToProps = state => ({
  botServices: state.superAdmin.botServices,
})

const mapDispatchToProps = dispatch => ({
  onGetBotServices: (page, onSuccess, onError) => dispatch(getBotServices(page, onSuccess, onError)),
  onDeleteBotService: (botService, onSuccess, onError) => dispatch(deleteBotService(botService, onSuccess, onError)),
  onChangeStatusBotService: (botService, onSuccess, onError) => dispatch(changeStatusBotService(botService, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BotServices)))
