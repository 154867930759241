import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  createBotService
} from "store/actions"

import { formValChange, formValid } from "helpers/form_validation"


// const regExp = RegExp(
//   /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
// )

// const formValid = ({ isError, ...rest }) => {
//   let isValid = false;
//   Object.values(isError).forEach(val => {
//     if (val.length > 0) {
//       isValid = false
//     } else {
//       isValid = true
//     }
//   });
//   Object.values(rest).forEach(val => {
//     if (val === null) {
//       isValid = false
//     } else {
//       isValid = true
//     }
//   });
//   return isValid;
// };


class CreateBotService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      loading: false,
      error: null,

    }
    this.addBotService = this.addBotService.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)


  }

  componentDidMount() {
  }


  addBotService() {
    this.setState({ loading: true })

    let addBotService = {
      name: this.state.name
    }
    this.props.onCreateAPI(addBotService, this.onSuccess, this.onError)
  }

  onSuccess(response) {
    this.setState({ loading: false })
    this.props.history.push('/botServices')
  }

  onError(response) {
    this.setState({
      error: response.msg,
      success: '',
      loading: false
    })
  }


  render() {
    const { isError } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={this.props.t("Bot Services")} breadcrumbItem={this.props.t("Add Bot Service")} />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    {/* <h4 className="card-title mb-4">{this.props.t("Add Bot Service")}</h4> */}

                    <div className="crypto-buy-sell-nav">
                      <AvForm onValidSubmit={this.addBotService}>

                        <div className="mb-3">
                          <Label>{this.props.t("Name")}</Label>
                          <AvField
                            required
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            validate={{
                              required: { value: true, errorMessage: this.props.t("Please enter a name.") }
                            }} />
                        </div>



                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                        <div className="text-center mt-4">
                          <Link to={"/BotServices/"} className="ml-20">
                            <Button color="primary">
                              {this.props.t("Cancel")}
                            </Button>
                          </Link>
                          &nbsp;&nbsp;&nbsp;
                          <Button type="submit" color="success"
                          // onClick={this.onSubmit}
                          >
                            {this.state.loading ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                            ) : this.props.t("Add Bot Service")}
                          </Button>
                        </div>

                      </AvForm>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateBotService.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  onCreateAPI: PropTypes.func,
}

const mapStateToProps = state => ({
  exchanges: state.exchangeAPI.exchanges,
})

const mapDispatchToProps = dispatch => ({
  onCreateAPI: (api, onSuccess, onError) => dispatch(createBotService(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CreateBotService)))
