import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

import SuperAdminHeader from "./SuperAdmin/SuperAdminHeader"
import SuperAdminSidebar from "./SuperAdmin/SuperAdminSidebar"
import SuperAdminFooter from "./SuperAdmin/SuperAdminFooter"
import CompanyHeader from "./Company/CompanyHeader"
import CompanySidebar from "./Company/CompanySidebar"
import CompanyFooter from "./Company/CompanyFooter"


class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    }
  }

  componentDidMount() {
    // document.body.setAttribute('data-topbar', 'dark');
    document.body.setAttribute('data-sidebar', 'dark');

    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    window.scrollTo(0, 0)
  }


  showPage(){
    if(this.props.user.role == "user"){
      return (
        <div id="layout-wrapper">
          <Header />
          <Sidebar
            theme="dark"
            type="default"
            isMobile={this.state.isMobile}
          />
          <div className="main-content">{this.props.children}</div>
          <Footer auth={false}/>
        </div>
      )
    } 
    else if(this.props.user.role == "superAdmin"){
      return (
        <div id="layout-wrapper">
          <SuperAdminHeader />
          <SuperAdminSidebar
            theme="dark"
            type="default"
            isMobile={this.state.isMobile}
          />
          <div className="main-content">{this.props.children}</div>
          <SuperAdminFooter auth={false}/>
        </div>
      )
    }
    else if(this.props.user.role == "company"){
      return (
        <div id="layout-wrapper">
          <CompanyHeader />
          <CompanySidebar
            theme="dark"
            type="default"
            isMobile={this.state.isMobile}
          />
          <div className="main-content">{this.props.children}</div>
          <CompanyFooter auth={false}/>
        </div>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        {
          this.showPage()
        }
        
      </React.Fragment>
    )
  }
}

Navigation.propTypes = {
  children: PropTypes.any,
  isPreloader: PropTypes.bool,
  location: PropTypes.object,
  topbarTheme: PropTypes.any,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})


export default connect(
  mapStateToProps
)(withRouter(withTranslation()(Navigation)))
