import React, { Component } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardBody, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

//import cryptoWalletColumns from "./cryptoWalletColumns"



class ReferralEarnings extends Component {
  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }

  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          dataField: 'created_at',
          text: this.props.t("Date"),
          formatter: (cellContent, row) => (
            new Date(row?.created_at)?.toLocaleString()
          )
        },
        {
          dataField: 'amount',
          text: this.props.t("Amount"),
          formatter: (cellContent, row) => (
            row?.amount + ' PDT'
          )
        },
        {
          dataField: 'percentage',
          text: this.props.t('Percentage'),
          formatter: (cellContent, row) => (
            row?.percentage + '%'
          )
        },
        {
          dataField: 'refer_level',
          text: this.props.t('Referral level'),
          formatter: (cellContent, row) => (
            row?.refer_level
          )
        },
        {
          dataField: 'base_user_id',
          text:  this.props.t('Earned From'),
          formatter: (cellContent, row) => (
            row?.base_user_id?.email
          )
        }
        
      ]
    }
  }



  render() {
    return (
      
          
        <div>

          {this.props.referralEarnings?.length ? (
            <div className="mt-4 table-responsive">
              <BootstrapTable keyField='_id'
                data={this.props.referralEarnings}
                columns={this.state.columns} />
            </div>
          ) : (
            <p className="text-muted">{this.props.t("You have no transaction history yet.")}</p>
          )}
        </div>

       
    )
  }

}

ReferralEarnings.propTypes = {
  t: PropTypes.any,
  referralEarnings: PropTypes.array,
}

export default withTranslation()(ReferralEarnings)
