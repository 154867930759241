import React from 'react';
import styled from 'styled-components';

// Styled Components
const StyledSwitchButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    width: 38px;
    background-color: ${props => props.active ? '#3f51b5' : '#FFFFFF'};
    border-radius: 11px;
    border: 1px solid ${props => props.active ? '#3f51b5' : '#ECECEC'};
    box-sizing: border-box;
    padding: 0;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    outline: none;
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-color: #FFFFFF;
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.3);
      transition: all 300ms ease-in-out;
      transform: ${props => props.active ? 'translate(16px)' : 'translate(0)'};
      display: block;
    }
  }
  span {
    color: #989898;
    font-size: 12px;
    margin-left: 10px;
  }
`;

// Switch Button Component
const SwitchButton = (props) => (
  <StyledSwitchButton active={props.active}>
    <button type={props.type} onClick={props.clicked}></button>
    <span>{props.active ? 'Enable' : 'Disabled'}</span>
  </StyledSwitchButton>
);

// Styled Components
const StyledOrderSize = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    width: 38px;
    background-color: ${props => props.active ? '#3f51b5' : '#FFFFFF'};
    border-radius: 11px;
    border: 1px solid ${props => props.active ? '#3f51b5' : '#ECECEC'};
    box-sizing: border-box;
    padding: 0;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    outline: none;
    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background-color: #FFFFFF;
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.3);
      transition: all 300ms ease-in-out;
      transform: ${props => props.active ? 'translate(16px)' : 'translate(0)'};
      display: block;
    }
  }
  span {
    color: #989898;
    font-size: 12px;
    margin-left: 10px;
  }
`;

// Switch Button Component
export const ButtonOrderSize = (props) => (
  <StyledOrderSize active={props.active}>
    <button type={props.type} onClick={props.clicked}></button>
    <span>{props.active ? 'Yes' : 'No'}</span>
  </StyledOrderSize>
);


export default SwitchButton;