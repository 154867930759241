import React, { Component } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"

import user from "../../assets/images/user.png"

import { logout } from "store/actions"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "Admin",
    }

    this.toggle = this.toggle.bind(this)
    this.onLogout = this.onLogout.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  onLogout() {
    window.localStorage.removeItem('token')
    this.props.history.push('/login')
  }

  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        this.setState({ name: obj.displayName })
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        this.setState({ name: obj.username })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.props.user?.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-end">

            {/*
            <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle me-1"/>
              {this.props.t("Profile")}
            </DropdownItem>

            <div className="dropdown-divider"/>
            */}

            {/*
            <DropdownItem tag="a" href="/crypto-wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1"/>
              {this.props.t("My Wallet")}
            </DropdownItem>

            <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end"></span>
              <i className="bx bx-wrench font-size-17 align-middle me-1"/>
              {this.props.t("Settings")}
            </DropdownItem>

            <DropdownItem tag="a" href="auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
              {this.props.t("Lock screen")}
            </DropdownItem>
            */}

            <DropdownItem onClick={this.onLogout} className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
              <span>{this.props.t("Logout")}</span>
            </DropdownItem>

          </DropdownMenu>

        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.object,
  onLogout: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProfileMenu)))
