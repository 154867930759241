import Breadcrumbs from "navigation/Breadcrumb";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Breadcrumb, Card, CardBody, Col, Container, Row } from "reactstrap";

const TradingHistory = (props) =>{

    return(
        <React.Fragment>
        <div className="page-content">
            <Breadcrumb>
                <Breadcrumb href="#">Home</Breadcrumb>
                <Breadcrumb active>TradeHistory</Breadcrumb>
            </Breadcrumb> 
            <Container fluid>
                {/* {console.log("Props Data:",props)} */}
                
                <Card>
                  <CardBody className="p-0">
                    <Row>
                        <Col md="2" sm={6} className="border">
                            <div className="trade-data">

                            
                            <h6>Realized Pnl</h6>
                            <p>0.00 USD</p>
                            </div>
                        </Col>
                        <Col md="2" className="border">
                            <h6>W/L Ratio</h6>
                            <p>0 %</p>
                        </Col>
                        <Col md="2" className="border">
                            <h6>Trades</h6>
                            <p>0</p>
                        </Col>

                        <Col md="2" className="border">

                        </Col>

                        <Col md="2" className="border">
                            <h6>APY</h6>
                            <p>0%</p>
                        </Col>
                        <Col md="2" className="border">
                            <h6>All Time Pnl</h6>
                            <p>0.00 USD</p>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
                
                <Row>
                <Col md="8">
                    <Card>
                    <CardBody>
                        <h4>Demo Page for Trading History</h4>

                    </CardBody>
                    </Card>
                </Col>


                <Col md="4">
                    <Card>
                    <CardBody>
                        <h4>Demo Page for Trading History</h4>
                    </CardBody>
                    </Card>
                </Col>
                </Row>

            </Container>
        </div>
        </React.Fragment>
    )
}

export default TradingHistory;