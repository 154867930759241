import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'

class Profile extends Component {
    render() {
        return (
            <div>
                <Link to={"/login"}> <p> {this.props.t("login")} </p></Link>
                <p> {this.props.t("Profile")} </p>
            </div>
        )
    }
}

Profile.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(Profile)
