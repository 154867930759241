import {
  GET_BOT_SERVICE_SUCCESS,
  GET_BOT_SERVICE_FAIL,
  CREATE_BOT_SERVICE_SUCCESS,
  CREATE_BOT_SERVICE_FAIL,
  EDIT_BOT_SERVICE_SUCCESS,
  EDIT_BOT_SERVICE_FAIL,
  DELETE_BOT_SERVICE_SUCCESS,
  DELETE_BOT_SERVICE_FAIL,
  CHANGE_STATUS_BOT_SERVICE_SUCCESS,
  CHANGE_STATUS_BOT_SERVICE_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  CHANGE_STATUS_COMPANY_SUCCESS,
  CHANGE_STATUS_COMPANY_FAIL,
  CREATE_EXCHANGE_SUCCESS,
  CREATE_EXCHANGE_FAIL,
  UPDATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_LOGO_SUCCESS,
  UPDATE_COMPANY_LOGO_FAIL,
  GET_EXCHANGE_SUCCESS,
  GET_EXCHANGE_FAIL,
  GET_EXCHANGE_DETAIL_SUCCESS,
  GET_EXCHANGE_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  CHANGE_STATUS_EXCHANGE_SUCCESS,
  CHANGE_STATUS_EXCHANGE_FAIL,
  COIN_IMPORT_SUCCESS,
  COIN_IMPORT_FAIL,
  COIN_PAIR_IMPORT_SUCCESS,
  COIN_PAIR_IMPORT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  botServices: [],
  userList: [],
  companies: [],
  exchanges: [],
  exchange_details: {},
  company_details: {},
  error: {},
}

const SuperAdmin = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_BOT_SERVICE_SUCCESS:
      return {
        ...state,
        botServices: action.payload,
      }

    case GET_BOT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case EDIT_BOT_SERVICE_SUCCESS:
      return {
        ...state,
        botServices: [...state.bots, action.payload],
      }

    case EDIT_BOT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_BOT_SERVICE_SUCCESS:
      return {
        ...state,
        botServices: state.botServices.filter(api => api._id != action.payload._id),
      }

    case DELETE_BOT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case CHANGE_STATUS_BOT_SERVICE_SUCCESS:
      return {
        ...state,
        // botServices: changeStatus(state, action)
      }

    case CHANGE_STATUS_BOT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case CREATE_BOT_SERVICE_SUCCESS:
      return {
        ...state,
        botServices: [...state.botServices, action.payload]
      }

    case CREATE_BOT_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
      }

    case GET_USER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      }

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload]
      }

    case CREATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case CHANGE_STATUS_COMPANY_SUCCESS:
      return {
        ...state,
        // botServices: changeStatus(state, action)
      }

    case CHANGE_STATUS_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case CREATE_EXCHANGE_SUCCESS:
      return {
        ...state,
        exchanges: [...state.exchanges, action.payload]
      }

    case CREATE_EXCHANGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EXCHANGE_SUCCESS:
      return {
        ...state,
        exchanges: [...state.exchanges, action.payload]
      }

    case UPDATE_EXCHANGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload]
      }

    case UPDATE_COMPANY_LOGO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload]
      }

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EXCHANGE_SUCCESS:
      return {
        ...state,
        exchanges: action.payload,
      }

    case GET_EXCHANGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_EXCHANGE_DETAIL_SUCCESS:
      return {
        ...state,
        exchange_details: action.payload[0],
      }

    case GET_EXCHANGE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        company_details: action.payload[0],
      }

    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CHANGE_STATUS_EXCHANGE_SUCCESS:
      return {
        ...state,
        // botServices: changeStatus(state, action)
      }

    case CHANGE_STATUS_EXCHANGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
    case COIN_IMPORT_SUCCESS:
      return {
        ...state,
        // botServices: changeStatus(state, action)
      }

    case COIN_IMPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case COIN_PAIR_IMPORT_SUCCESS:
      return {
        ...state,
        // botServices: changeStatus(state, action)
      }

    case COIN_PAIR_IMPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}


export default SuperAdmin
