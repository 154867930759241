import React, { Component } from "react"
import PropTypes from "prop-types";
import {
  Col,
  Card,
  Nav,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Row,
} from "reactstrap"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next"

class Transactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  renderOrders(orders) {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "330px" }}>
          <div className="table-responsive">
            <Table className="align-middle table-nowrap">
              <tbody>
                  <tr>
                    <td style={{ width: "50px" }}>
                      <div className="font-size-22 text-primary">
                        {/* <i className="bx bx-down-arrow-circle" /> */}
                        #
                      </div>
                    </td>
                    <td className="text-bold card-title">Pair Name</td>
                    <td className="text-bold card-title">Side</td>
                    <td><div className="text-muted card-title text-left">Order Date & Time</div></td>
                    <td><div className="text-left card-title">Price</div></td>
                    <td><div className="text-end card-title">Quantity</div></td>
                    <td>
                      <div className="text-end">
                        <h5 className="font-size-14 text-muted mb-0"></h5>
                      </div>
                    </td>
                  </tr>
                {orders && orders.length ?
                  orders.map(order => {
                    return (
                      
                        <tr key={order.id}>
                          <td style={{ width: "50px" }}>
                            <div className="font-size-22 text-primary">
                              <i className="bx bx-down-arrow-circle" />
                            </div>
                          </td>
                          <td>
                            <div>
                              <h5 className="font-size-14 mb-1">{order.info?.symbol}</h5>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className='font-size-14 mb-1'>
                                <span className={order.side == 'BUY' ?'text-success':'text-danger'}>{order.side}</span> {order.info?.symbol?.split('-')?.[0]}
                              </p>

                            </div>
                          </td>
                          <td>
                            <div>
                               <p className="mb-0 ">{new Date(order.datetime)?.toLocaleString()}</p>
                            </div>
                          </td>
                          <td>
                            <div>
                               <p className="mb-0">
                                  <span className={order.side == 'BUY' ?'text-success':'text-danger'}>
                                  {order.price} 
                                  </span>
                                  <span className="">&nbsp;{order.info?.symbol?.split('-')?.[1]}</span>
                                </p>
                            </div>
                          </td>

                          <td>
                            <div className="text-end">
                              <p className="font-size-14 mb-0">
                                <span className={order.side == 'BUY' ?'text-success':'text-danger'}>
                                  {order.amount} 
                                </span>&nbsp;
                                {order.info?.symbol?.split('-')?.[0]}</p>
                            </div>
                          </td>

                          <td>
                            <div className="text-end">
                              <h5 className="font-size-14 text-muted mb-0"></h5>
                            </div>
                          </td>
                        </tr>
                     
                    )
                  }) : (
                    <tr>
                      <td style={{ width: "50px" }}>
                        <div className="font-size-22 text-primary">
                          <i className="bx bx-down-arrow-circle" />
                        </div>
                      </td>

                      <td>
                        <div>
                          <p className="text-muted mb-0">{this.props.t("No Orders")}</p>
                        </div>
                      </td>

                      <td>
                        <div className="text-end">
                          <h5 className="font-size-14 mb-0">--</h5>
                        </div>
                      </td>

                      <td>
                        <div className="text-end">
                          <h5 className="font-size-14 text-muted mb-0">--</h5>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }

  render() {
    const { openOrders } = this.props

    return (
      <React.Fragment>
        <Col xs="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">{this.props.t("Open Orders")}</h4>

              {this.props.loading ? (
                <p>{this.props.t("Loading...")}</p>
              ) : (
                <React.Fragment>
                  <Nav pills className="bg-light rounded" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1")
                        }}
                      >
                        {this.props.t("All")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2")
                        }}
                      >
                        {this.props.t("Buy")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggleTab("3")
                        }}
                      >
                        {this.props.t("Sell")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  {/* <Row className="mt-2">
                    <Col xs sm={7}><div className="cmDetails">Order Details</div></Col>
                    <Col xs sm={5}><div className="cmDetails cmAlignRight">Market Price</div></Col>
                  </Row> */}

                  <TabContent activeTab={this.state.activeTab} className="mt-4">
                    <TabPane tabId="1">
                      {this.renderOrders(openOrders)}
                    </TabPane>
                    <TabPane tabId="2">
                      {this.renderOrders(openOrders?.filter(order => order.side == this.props.t("BUY")))}
                    </TabPane>
                    <TabPane tabId="3">
                      {this.renderOrders(openOrders?.filter(order => order.side == this.props.t("SELL")))}
                    </TabPane>
                  </TabContent>
                </React.Fragment>
              )}

            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

Transactions.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  openOrders: PropTypes.any,
  loading: PropTypes.bool
}


export default withTranslation()(Transactions)
