import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Media,
  Button,
  Label,
  Input
} from "reactstrap"
import PropTypes from 'prop-types'

import Breadcrumbs from "navigation/Breadcrumb"

import CardPricing from "./card-pricing"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import plans from './plans'

import {
  subscribe
} from "store/actions"



class PaymentComplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.redirect = this.redirect.bind(this)
  }

  redirect() {
    this.props.history.push('/subscription')
  }

  render() {
    let id = this.props.match.params.id

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>{this.props.t("Logos | Payment Successful")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t("Subscription")}
              breadcrumbItem={this.props.t("Payment")}
            />

            <Row className="justify-content-center">
              <Col lg={6}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '60px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                  <i className="bx bx-check-circle font-size-24 text-success" />
                  <h4 style={{ margin: 0, marginLeft: '0.5rem' }}>{this.props.t("Payment Successful")}</h4>
                </div>
              </Col>
            </Row>

            <Card className="mx-auto" style={{ maxWidth: '480px' }}>
              <CardBody className="p-4">
                <p className="text-center">{this.props.t("Thank you for your purchase.")}</p>
                <Button type="button" color="success"
                  className="d-block m-auto"
                  onClick={this.redirect}
                >{this.props.t("Back To Bot Subscription")}</Button>
              </CardBody>
            </Card>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PaymentComplete.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onSubscribe: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onSubscribe: (id, onSuccess, onError) => dispatch(subscribe(id, onSuccess,onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(PaymentComplete)))
