import PropTypes from 'prop-types'
import React, { Component } from "react"
import { withTranslation } from "react-i18next"

// Import menuDropdown
import LanguageDropdown from "./TopbarDropdown/LanguageDropdown"


class AuthHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }


  render() {
    return (
      <React.Fragment>
        <div className="navbar-header">

          <div className="d-flex">
          </div>

          <div className="d-flex">
            <LanguageDropdown />
          </div>
        </div>
      </React.Fragment>
    )
  }
}


AuthHeader.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(AuthHeader)
