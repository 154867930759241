import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BOT_SERVICE,
  CREATE_BOT_SERVICE,
  EDIT_BOT_SERVICE,
  DELETE_BOT_SERVICE,
  CHANGE_STATUS_BOT_SERVICE,
  GET_USER_LIST,
  GET_COMPANIES,
  CREATE_COMPANY,
  CHANGE_STATUS_COMPANY,
  CREATE_EXCHANGE,
  UPDATE_EXCHANGE,
  UPDATE_COMPANY,
  UPDATE_COMPANY_LOGO,
  GET_EXCHANGE,
  GET_EXCHANGE_DETAIL,
  CHANGE_STATUS_EXCHANGE,
  GET_COMPANY_DETAIL,
  COIN_IMPORT,
  COIN_PAIR_IMPORT
} from "./actionTypes"
import {
  getBotServiceSuccess,
  getBotServiceFail,
  createBotServiceSuccess,
  createBotServiceFail,
  editBotServiceSuccess,
  editBotServiceFail,
  deleteBotServiceSuccess,
  deleteBotServiceFail,
  changeStatusBotServiceSuccess,
  changeStatusBotServiceFail,
  getUserListSuccess,
  getUserListFail,
  getCompaniesSuccess,
  getCompaniesFail,
  createCompanySuccess,
  createCompanyFail,
  changeStatusCompanySuccess,
  changeStatusCompanyFail,
  createExchangeSuccess,
  createExchangeFail,
  updateCompanySuccess,
  updateCompanyFail,
  updateCompanyLogoSuccess,
  updateCompanyLogoFail,
  updateExchangeSuccess,
  updateExchangeFail,
  getAdminExchangeSuccess,
  getAdminExchangeFail,
  getAdminExchangeDetailSuccess,
  getAdminExchangeDetailFail,
  getCompanyDetailSuccess,
  getCompanyDetailFail,
  changeStatusExchangeSuccess,
  changeStatusExchangeFail,
  coinImportSuccess,
  coinImportFail,
  coinPairImportSuccess,
  coinPairImportFail
} from "./actions"

import { get, post, apiPut } from "helpers/api_helper"


function* fetchBotService({ payload: page, onSuccess, onError }) {
  try {
    // const response = yield call(get, 'admin/services', page)
    const response = yield call(get, 'exchanges/services', page)

    if (onSuccess) onSuccess(response)
    yield put(getBotServiceSuccess(response.data))
  } catch (error) {
    // console.log("fetchBotService error.response", error.response)
    if (onError) onError(error.response)
    yield put(getBotServiceFail(error))
  }
}



function* onCreateBotService({ payload: bot, onSuccess, onError }) {
  try {
    // const response = yield call(post, 'admin/services', bot)
    const response = yield call(post, 'exchanges/services', bot)

    if (response.status == 'success') {
      onSuccess(response.data);
      yield put(createBotServiceSuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    onError(error.response.data)
    yield put(createBotServiceFail(error))
  }
}

function* onEditBotService({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/editBotService', bot)
    if (response.status == 'success') {
      onSuccess(response.data);
      yield put(editBotServiceSuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    // console.log("onEditBotService error ", error);
    onError(error)
    yield put(editBotServiceFail(error))
  }
}

function* onDeleteBotService({ payload: botService, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/services/' + botService._id, { id: botService._id })
    onSuccess({ ...response, botService })
    yield put(deleteBotServiceSuccess(botService))
  } catch (error) {
    onError(error.response)
    yield put(deleteBotServiceFail(error))
  }
}

function* onChangeStatusBotService({ payload: botService, onSuccess, onError }) {
  try {
    const response = yield call(apiPut, 'admin/services/' + botService._id, { id: botService._id })
    onSuccess({ ...response, botService })
    // yield put(changeStatusBotServiceSuccess(botService))
  } catch (error) {
    onError(error.response)
    yield put(changeStatusBotServiceFail(error))
  }
}

function* fetchUserList({ payload: page, onSuccess, onError }) {
  // console.log("fetchUserList page ", page)
  try {
    let response = null;
    if (page.id) {
      response = yield call(get, 'admin/users/' + page.id, page)
    } else {
      response = yield call(get, 'admin/users', page)
    }
    if (onSuccess) onSuccess(response)
    yield put(getUserListSuccess(response.data))
  } catch (error) {
    // console.log("error.response", error.response)
    if (onError) onError(error.response)
    yield put(getUserListFail(error))
  }
}

function* fetchCompanies({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(get, 'admin/companies', page)
    if (onSuccess) onSuccess(response)
    yield put(getCompaniesSuccess(response.data))
  } catch (error) {
    // console.log("error.response", error.response)
    if (onError) onError(error.response)
    yield put(getCompaniesFail(error))
  }
}

function* onCreateCompany({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/companies', bot)
    if (response.status == 'success') {
      onSuccess(response.data);
      yield put(createCompanySuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    onError(error.response.data)
    yield put(createCompanyFail(error))
  }
}

function* onChangeStatusCompany({ payload: company, onSuccess, onError }) {
  try {
    const response = yield call(apiPut, 'admin/companies/' + company._id, { id: company._id })
    onSuccess({ ...response, company })
    // yield put(changeStatusCompanySuccess(botService))
  } catch (error) {
    onError(error.response)
    yield put(changeStatusCompanyFail(error))
  }
}

function* onCreateExchange({ payload: bot, onSuccess, onError }) {
  try {
    // console.log("call api ", bot)
    const response = yield call(post, 'admin/exchanges', bot)
    // console.log("exchanges res ", response);
    if (response.status == 'success') {
      onSuccess(response.data);
      yield put(createExchangeSuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    onError(error)
    yield put(createExchangeFail(error))
  }
}

function* onUpdateExchange({ payload: exchange, onSuccess, onError }) {
  // console.log("onUpdateExchange exchange", exchange)
  try {
    const response = yield call(post, 'admin/exchanges/' + exchange.e_id, exchange)
    onSuccess(response);
    if (response.status == 'success') {
      yield put(updateExchangeSuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    console.log("error ", error);
    console.log("error.response ", error.response);
    onError(error)
    yield put(updateExchangeFail(error))
  }
}

function* onUpdateCompany({ payload: company, onSuccess, onError }) {
  // console.log("onUpdateCompany company", company)
  try {
    const response = yield call(post, 'admin/companies/' + company.c_id, company)
    onSuccess(response);
    if (response.status == 'success') {
      yield put(updateCompanySuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    console.log("error.response ", error.response);
    onError(error.response)
    yield put(updateCompanyFail(error))
  }
}

function* onUpdateCompanyLogo({ payload: company, onSuccess, onError }) {
  // console.log("onUpdateCompany company", company)
  try {
    const response = yield call(post, 'api/company/logo', company)
    onSuccess(response);
    if (response.status == 'success') {
      yield put(updateCompanyLogoSuccess(response.data))
    }
    else throw response
  }
  catch (error) {
    console.log("error.response ", error.response);
    onError(error.response)
    yield put(updateCompanyLogoFail(error))
  }
}

function* fetchExchange({ payload: page, onSuccess, onError }) {
  try {
    const response = yield call(get, 'admin/exchanges', page)
    if (onSuccess) onSuccess(response)
    yield put(getAdminExchangeSuccess(response.data))
  } catch (error) {
    // console.log("fetchExchange error.response", error.response)
    if (onError) onError(error.response)
    yield put(getAdminExchangeFail(error))
  }
}

function* fetchExchangeDetails({ payload: exchange, onSuccess, onError }) {
  try {
    const response = yield call(get, 'admin/exchanges?id=' + exchange.id)
    if (onSuccess) onSuccess(response)
    yield put(getAdminExchangeDetailSuccess(response.data))
  } catch (error) {
    // console.log("fetchExchangeDetails error.response", error.response)
    if (onError) onError(error.response)
    yield put(getAdminExchangeDetailFail(error))
  }
}

function* fetchCompanyDetails({ payload: company, onSuccess, onError }) {
  try {
    const response = yield call(get, 'admin/companies/'+ company.id)
    // console.log("fetchCompanyDetails respo", response)
    if (onSuccess) onSuccess(response)
    yield put(getCompanyDetailSuccess(response.data))
  } catch (error) {
    // console.log("fetchExchangeDetails error.response", error.response)
    if (onError) onError(error.response)
    yield put(getCompanyDetailFail(error))
  }
}

function* onChangeStatusExchange({ payload: exchange, onSuccess, onError }) {
  try {
    const response = yield call(apiPut, 'admin/exchanges/' + exchange._id, { id: exchange._id })
    onSuccess({ ...response, exchange })
    // yield put(changeStatusExchangeSuccess(botService))
  } catch (error) {
    onError(error.response)
    yield put(changeStatusExchangeFail(error))
  }
}

function* coinImport({ payload: exchange, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/importCoins', exchange)
    onSuccess(response);
    if (response.status == 'success') {
      yield put(coinImportSuccess(response.data))
    }
    // else throw response
  }
  catch (error) {
    // console.log("error", error);
    onError(error.response)
    yield put(coinImportFail(error))
  }
}
function* coinPairImport({ payload: exchange, onSuccess, onError }) {
  try {
    const response = yield call(post, 'admin/importPairs', exchange)
    onSuccess(response);
    if (response.status == 'success') {
      yield put(coinPairImportSuccess(response.data))
    }
    // else throw response
  }
  catch (error) {
    onError(error.response)
    yield put(coinPairImportFail(error))
  }
}


function* superAdminSaga() {
  yield takeEvery(GET_BOT_SERVICE, fetchBotService)
  yield takeEvery(CREATE_BOT_SERVICE, onCreateBotService)
  yield takeEvery(EDIT_BOT_SERVICE, onEditBotService)
  yield takeEvery(DELETE_BOT_SERVICE, onDeleteBotService)
  yield takeEvery(CHANGE_STATUS_BOT_SERVICE, onChangeStatusBotService)
  yield takeEvery(GET_USER_LIST, fetchUserList)
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(CREATE_COMPANY, onCreateCompany)
  yield takeEvery(CHANGE_STATUS_COMPANY, onChangeStatusCompany)
  yield takeEvery(CREATE_EXCHANGE, onCreateExchange)
  yield takeEvery(UPDATE_EXCHANGE, onUpdateExchange)
  yield takeEvery(GET_EXCHANGE, fetchExchange)
  yield takeEvery(GET_EXCHANGE_DETAIL, fetchExchangeDetails)
  yield takeEvery(CHANGE_STATUS_EXCHANGE, onChangeStatusExchange)
  yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetails)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(UPDATE_COMPANY_LOGO, onUpdateCompanyLogo)
  yield takeEvery(COIN_IMPORT, coinImport)
  yield takeEvery(COIN_PAIR_IMPORT, coinPairImport)
}

export default superAdminSaga
