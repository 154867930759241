import React from "react"


// Auth
import Login from "../pages/Auth/Login"
import Verify from "../pages/Auth/Verify"
import VerifyIP from "../pages/Auth/VerifyIP"
import ForgotPassword from "pages/Auth/ForgotPassword"
import ResetPassword from "pages/Auth/ResetPassword"
import Register from "pages/Auth/Register"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Bots
import BotList from '../pages/Bots/BotList'
import CreateBot from '../pages/Bots/CreateBot'
import EditBot from '../pages/Bots/EditBot'
import BotDetail from '../pages/Bots/BotDetail'

// Exchange API
import MyAPIs from '../pages/ExchangeAPI/MyAPIs'
import NewAPI from '../pages/ExchangeAPI/NewAPI'

// History
import History from "../pages/History/History"

import Subscription from "../pages/Subscription/Subscription"
import Initialize from "../pages/Subscription/Initialize"
import Payment from "../pages/Subscription/Payment"
import PaymentComplete from "../pages/Subscription/PaymentComplete"

import Referrals from "../pages/Referrals/Referrals"
import Security from "../pages/Security/Security"
import Profile from "../pages/Profile/Profile"

import Wallet from "../pages/Wallet/Wallet"
import Transfer from "../pages/Wallet/Transfer"
import VerifyTransfer from "../pages/Wallet/VerifyTransfer"
import HowToChooseCurrencyPair from "../pages/HowToChooseCurrencyPair/HowToChooseCurrencyPair"


//Super admin components
import Exchanges from "../pages/SuperAdmin/Exchange/Exchanges"
import AddEditExchanges from "../pages/SuperAdmin/Exchange/AddEditExchanges"
import ImportCoinNPairs from "../pages/SuperAdmin/Exchange/ImportCoinNPairs"
import BotServices from "../pages/SuperAdmin/BotService/BotServices"
import CreateBotService from "../pages/SuperAdmin/BotService/CreateBotService"
import Users from "../pages/SuperAdmin/User/Users"
import Companies from "../pages/SuperAdmin/Company/Companies"
import AddEditCompany from "../pages/SuperAdmin/Company/AddEditCompany"


//Company login
import UpdateCompany from "../pages/Company/UpdateCompany"
import TradingHistory from "pages/Bots/Trading/TradingHistory"

// ====================



const superAdminAuthProtectedRoutes = [

  //these are super admin routes
  { path: '/', component: Exchanges },
  { path: '/exchanges/', component: Exchanges },
  { path: '/addEditExchanges/', component: AddEditExchanges },
  { path: '/importCoinNPairs/:id', component: ImportCoinNPairs },
  { path: '/addEditExchanges/:id', component: AddEditExchanges },
  { path: '/botServices/', component: BotServices },
  { path: '/createBotService/', component: CreateBotService },
  { path: '/users/', component: Users },
  { path: '/companies/', component: Companies },
  { path: '/addEditCompany/', component: AddEditCompany },
  { path: '/addEditCompany/:id', component: AddEditCompany },

]

const companyAuthProtectedRoutes = [

   // these are company routes
   { path: '/updateCompany/:id', component: UpdateCompany },

]
const authProtectedRoutes = [

  // Dashboard
  // { path: "/dashboard", component: Dashboard },

  // Bots
  { path: '/', component: BotList },
  { path: '/bot-list', component: BotList },
  { path: '/create-bot', component: CreateBot },
  { path: '/edit-bot/:id', component: EditBot },
  { path: '/bot-detail/:id', component: BotDetail },

  // Exchange API
  { path: '/my-apis', component: MyAPIs },
  { path: '/new-api', component: NewAPI },

  // History
  { path: '/history', component: History },
  { path: '/profile', component: Profile },

  { path: '/subscription', component: Subscription },
  { path: '/initialize', component: Initialize },
  { path: '/payment/:id', component: Payment },
  { path: '/payment-success/:id', component: PaymentComplete },

  { path: '/referrals', component: Referrals },
  { path: '/security', component: Security },

  { path: '/wallet', component: Wallet },
  { path: '/transfer/:type', component: Transfer },
  { path: '/approve_transfer_request/:code', component: VerifyTransfer },
  { path: '/how-to-choose-currency-pair', component: HowToChooseCurrencyPair },

  // Trading History
  {path: '/trading-history', component:TradingHistory},
  
  
  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [

  { path: '/login', component: Login },
  { path: '/verify/:id', component: Verify },
  { path: '/verify_login/:code', component: VerifyIP },
  { path: '/forgotpassword', component: ForgotPassword },
  { path: '/resetPassword/:key', component: ResetPassword },
  { path: '/register/:code', component: Register },
  { path: '/register', component: Register },

  // { path: "/pass/:token", component: Handoff },

]

export { authProtectedRoutes, publicRoutes, superAdminAuthProtectedRoutes, companyAuthProtectedRoutes }
