import {
  GET_EXCHANGES_FAIL,
  GET_EXCHANGES_SUCCESS,
  GET_APIS_FAIL,
  GET_APIS_SUCCESS,
  CREATE_API_SUCCESS,
  CREATE_API_FAIL,
  UPDATE_API_SUCCESS,
  UPDATE_API_FAIL,
  DELETE_API_SUCCESS,
  DELETE_API_FAIL,
  GET_ADMIN_APIS_FAIL,
  GET_ADMIN_APIS_SUCESS
} from "./actionTypes"

const INIT_STATE = {
  exchanges: [],
  exchangeAPIs: [],
  error: {},
}

const ExchangeAPI = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_EXCHANGES_SUCCESS:
      return {
        ...state,
        exchanges: action.payload,
      }

    case GET_EXCHANGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case GET_APIS_SUCCESS:
      return {
        ...state,
        exchangeAPIs: action.payload,
      }

    case GET_APIS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case CREATE_API_SUCCESS:
      return {
        ...state,
        exchangeAPIs: [...state.exchangeAPIs, action.payload],
      }

    case CREATE_API_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case UPDATE_API_SUCCESS:
      return {
        ...state,
        exchangeAPIs: state.exchangeAPIs.map(api =>
          api.id.toString() === action.payload.id.toString()
            ? { api, ...action.payload }
            : api
        ),
      }

    case UPDATE_API_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case DELETE_API_SUCCESS:
      return {
        ...state,
        exchangeAPIs: state.exchangeAPIs.filter(api => api._id != action.payload._id)
      }

    case DELETE_API_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ADMIN_APIS_SUCESS:
      return{
        ...state,
        exchangeAPIs: state.exchangeAPIs.filter(api => api._id != action.payload._id)
      }

    case GET_ADMIN_APIS_FAIL:
      return{
        ...state,
        error:action.payload
      }


    default:
      return state
  }
}

export default ExchangeAPI
