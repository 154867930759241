
const regExp = RegExp(
    /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
  )

export function formValid({ isError, ...rest }) {
    let isValid = false;
    Object.values(isError).forEach(val => {
        if (val.length > 0) {
            isValid = false
        } else {
            isValid = true
        }
    });
    Object.values(rest).forEach(val => {
        if (val === null) {
            isValid = false
        } else {
            isValid = true
        }
    });
    return isValid;
}

export function formValChange (e, that) {
    e.preventDefault();
    const { name, value } = e.target;
    let isError = { ...that.state.isError };
    switch (name) {
      case "name":
        isError.name =
          value.length < 1 ? "This filed is required" : "";
        break;
      case "email":
        isError.email = regExp.test(value)
          ? ""
          : "Email address is invalid";
        break;
      case "password":
        isError.password =
          value.length < 6 ? "Atleast 6 characaters required" : "";
        break;
      default:
        break;
    }
    that.setState({
      isError,
      [name]: value
    })
  }