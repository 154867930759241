import { combineReducers } from "redux"

import auth from "./auth/reducer"
import bots from "./bots/reducer"
import exchangeAPI from "./exchangeAPI/reducer"
import user from "./user/reducer"
import superAdmin from "./superAdmin/reducer"

const rootReducer = combineReducers({
  auth,
  bots,
  exchangeAPI,
  user,
  superAdmin
})

export default rootReducer
