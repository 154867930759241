import {
  GET_BOT_SERVICE,
  GET_BOT_SERVICE_FAIL,
  GET_BOT_SERVICE_SUCCESS,
  CREATE_BOT_SERVICE,
  CREATE_BOT_SERVICE_FAIL,
  CREATE_BOT_SERVICE_SUCCESS,
  EDIT_BOT_SERVICE,
  EDIT_BOT_SERVICE_FAIL,
  EDIT_BOT_SERVICE_SUCCESS,
  DELETE_BOT_SERVICE,
  DELETE_BOT_SERVICE_SUCCESS,
  DELETE_BOT_SERVICE_FAIL,
  CHANGE_STATUS_BOT_SERVICE,
  CHANGE_STATUS_BOT_SERVICE_SUCCESS,
  CHANGE_STATUS_BOT_SERVICE_FAIL,
  GET_USER_LIST,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_COMPANIES,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  CREATE_COMPANY,
  CREATE_COMPANY_FAIL,
  CREATE_COMPANY_SUCCESS,
  CHANGE_STATUS_COMPANY,
  CHANGE_STATUS_COMPANY_SUCCESS,
  CHANGE_STATUS_COMPANY_FAIL,
  CREATE_EXCHANGE,
  CREATE_EXCHANGE_FAIL,
  CREATE_EXCHANGE_SUCCESS,
  UPDATE_EXCHANGE,
  UPDATE_EXCHANGE_FAIL,
  UPDATE_EXCHANGE_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_LOGO,
  UPDATE_COMPANY_LOGO_FAIL,
  UPDATE_COMPANY_LOGO_SUCCESS,
  GET_EXCHANGE,
  GET_EXCHANGE_FAIL,
  GET_EXCHANGE_SUCCESS,
  GET_EXCHANGE_DETAIL,
  GET_EXCHANGE_DETAIL_FAIL,
  GET_EXCHANGE_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  CHANGE_STATUS_EXCHANGE,
  CHANGE_STATUS_EXCHANGE_SUCCESS,
  CHANGE_STATUS_EXCHANGE_FAIL,
  COIN_IMPORT,
  COIN_IMPORT_SUCCESS,
  COIN_IMPORT_FAIL,
  COIN_PAIR_IMPORT,
  COIN_PAIR_IMPORT_SUCCESS,
  COIN_PAIR_IMPORT_FAIL,
} from "./actionTypes"



export const getBotServices = (page, onSuccess, onError) => ({
  type: GET_BOT_SERVICE,
  payload: page,
  onSuccess,
  onError
})

export const getBotServiceSuccess = orders => ({
  type: GET_BOT_SERVICE_SUCCESS,
  payload: orders,
})

export const getBotServiceFail = error => ({
  type: GET_BOT_SERVICE_FAIL,
  payload: error,
})



export const createBotService = (bot, onSuccess, onError) => ({
  type: CREATE_BOT_SERVICE,
  payload: bot,
  onSuccess,
  onError
})

export const createBotServiceSuccess = bot => ({
  type: CREATE_BOT_SERVICE_SUCCESS,
  payload: bot,
})

export const createBotServiceFail = error => ({
  type: CREATE_BOT_SERVICE_FAIL,
  payload: error,
})

export const editBotService = (bot, onSuccess, onError) => ({
  type: EDIT_BOT_SERVICE,
  payload: bot,
  onSuccess,
  onError
})

export const editBotServiceSuccess = bot => ({
  type: EDIT_BOT_SERVICE_SUCCESS,
  payload: bot,
})

export const editBotServiceFail = error => ({
  type: EDIT_BOT_SERVICE_FAIL,
  payload: error,
})

export const deleteBotService = (bot, onSuccess, onError) => ({
  type: DELETE_BOT_SERVICE,
  payload: bot,
  onSuccess,
  onError
})

export const deleteBotServiceSuccess = bot => ({
  type: DELETE_BOT_SERVICE_SUCCESS,
  payload: bot,
})

export const deleteBotServiceFail = error => ({
  type: DELETE_BOT_SERVICE_FAIL,
  payload: error,
})

export const changeStatusBotService = (bot, onSuccess, onError) => ({
  type: CHANGE_STATUS_BOT_SERVICE,
  payload: bot,
  onSuccess,
  onError
})

export const changeStatusBotServiceSuccess = bot => ({
  type: CHANGE_STATUS_BOT_SERVICE_SUCCESS,
  payload: bot,
})

export const changeStatusBotServiceFail = error => ({
  type: CHANGE_STATUS_BOT_SERVICE_FAIL,
  payload: error,
})

export const getUserList = (page, onSuccess, onError) => ({
  type: GET_USER_LIST,
  payload: page,
  onSuccess,
  onError
})

export const getUserListSuccess = orders => ({
  type: GET_USER_LIST_SUCCESS,
  payload: orders,
})

export const getUserListFail = error => ({
  type: GET_USER_LIST_FAIL,
  payload: error,
})

export const getCompanies = (page, onSuccess, onError) => ({
  type: GET_COMPANIES,
  payload: page,
  onSuccess,
  onError
})

export const getCompaniesSuccess = orders => ({
  type: GET_COMPANIES_SUCCESS,
  payload: orders,
})

export const getCompaniesFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
})

export const createCompany = (bot, onSuccess, onError) => ({
  type: CREATE_COMPANY,
  payload: bot,
  onSuccess,
  onError
})

export const createCompanySuccess = bot => ({
  type: CREATE_COMPANY_SUCCESS,
  payload: bot,
})

export const createCompanyFail = error => ({
  type: CREATE_COMPANY_FAIL,
  payload: error,
})
export const createExchange = (bot, onSuccess, onError) => ({
  type: CREATE_EXCHANGE,
  payload: bot,
  onSuccess,
  onError
})

export const createExchangeSuccess = bot => ({
  type: CREATE_EXCHANGE_SUCCESS,
  payload: bot,
})

export const createExchangeFail = error => ({
  type: CREATE_EXCHANGE_FAIL,
  payload: error,
})
export const updateExchange = (bot, onSuccess, onError) => ({
  type: UPDATE_EXCHANGE,
  payload: bot,
  onSuccess,
  onError
})

export const updateExchangeSuccess = bot => ({
  type: UPDATE_EXCHANGE_SUCCESS,
  payload: bot,
})

export const updateExchangeFail = error => ({
  type: UPDATE_EXCHANGE_FAIL,
  payload: error,
})
export const updateCompany = (bot, onSuccess, onError) => ({
  type: UPDATE_COMPANY,
  payload: bot,
  onSuccess,
  onError
})

export const updateCompanySuccess = bot => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: bot,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})
export const updateCompanyLogo = (bot, onSuccess, onError) => ({
  type: UPDATE_COMPANY_LOGO,
  payload: bot,
  onSuccess,
  onError
})

export const updateCompanyLogoSuccess = bot => ({
  type: UPDATE_COMPANY_LOGO_SUCCESS,
  payload: bot,
})

export const updateCompanyLogoFail = error => ({
  type: UPDATE_COMPANY_LOGO_FAIL,
  payload: error,
})

export const changeStatusCompany = (bot, onSuccess, onError) => ({
  type: CHANGE_STATUS_COMPANY,
  payload: bot,
  onSuccess,
  onError
})

export const changeStatusCompanySuccess = bot => ({
  type: CHANGE_STATUS_COMPANY_SUCCESS,
  payload: bot,
})

export const changeStatusCompanyFail = error => ({
  type: CHANGE_STATUS_COMPANY_FAIL,
  payload: error,
})

export const getAdminExchanges = (page, onSuccess, onError) => ({
  type: GET_EXCHANGE,
  payload: page,
  onSuccess,
  onError
})

export const getAdminExchangeSuccess = orders => ({
  type: GET_EXCHANGE_SUCCESS,
  payload: orders,
})

export const getAdminExchangeFail = error => ({
  type: GET_EXCHANGE_FAIL,
  payload: error,
})

export const getAdminExchangeDetails = (exchange, onSuccess, onError) => ({
  type: GET_EXCHANGE_DETAIL,
  payload: exchange,
  onSuccess,
  onError
})

export const getAdminExchangeDetailSuccess = orders => ({
  type: GET_EXCHANGE_DETAIL_SUCCESS,
  payload: orders,
})

export const getAdminExchangeDetailFail = error => ({
  type: GET_EXCHANGE_DETAIL_FAIL,
  payload: error,
})

export const getCompanyDetails = (exchange, onSuccess, onError) => ({
  type: GET_COMPANY_DETAIL,
  payload: exchange,
  onSuccess,
  onError
})

export const getCompanyDetailSuccess = orders => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  payload: orders,
})

export const getCompanyDetailFail = error => ({
  type: GET_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const changeStatusExchange = (bot, onSuccess, onError) => ({
  type: CHANGE_STATUS_EXCHANGE,
  payload: bot,
  onSuccess,
  onError
})

export const changeStatusExchangeSuccess = bot => ({
  type: CHANGE_STATUS_EXCHANGE_SUCCESS,
  payload: bot,
})

export const changeStatusExchangeFail = error => ({
  type: CHANGE_STATUS_EXCHANGE_FAIL,
  payload: error,
})

export const coinImport = (bot, onSuccess, onError) => ({
  type: COIN_IMPORT,
  payload: bot,
  onSuccess,
  onError
})

export const coinImportSuccess = bot => ({
  type: COIN_IMPORT_SUCCESS,
  payload: bot,
})

export const coinImportFail = error => ({
  type: COIN_IMPORT_FAIL,
  payload: error,
})

export const coinPairImport = (bot, onSuccess, onError) => ({
  type: COIN_PAIR_IMPORT,
  payload: bot,
  onSuccess,
  onError
})

export const coinPairImportSuccess = bot => ({
  type: COIN_PAIR_IMPORT_SUCCESS,
  payload: bot,
})

export const coinPairImportFail = error => ({
  type: COIN_PAIR_IMPORT_FAIL,
  payload: error,
})