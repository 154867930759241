import React, { Component } from "react"
import PropTypes from 'prop-types'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import AuthHeader from "../../navigation/AuthHeader"

import { AvField, AvForm } from "availity-reactstrap-validation"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logos_logo_color.png"
import lightlogo from "../../assets/images/logos_logo_white.png"
import auth_bg from "../../assets/images/auth_bg.jpg"
import { forgotPassword } from "store/actions"

import * as CryptoJS from 'crypto-js'
import secret from 'secretConfig'

import Footer from './../../navigation/Footer';

import ReCaptcha from 'react-google-recaptcha';
import { recaptchaKey } from '../../helpers/api_helper';


class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,

      email: '',
      hasSubmitted: false,
      recaptcha: ''
    }

    this.onRecaptchaChange = this.onRecaptchaChange.bind(this)

    this.resetPassword = this.resetPassword.bind(this)
    this.resetPasswordSuccess = this.resetPasswordSuccess.bind(this)
    this.resetPasswordError = this.resetPasswordError.bind(this)
  }

  onRecaptchaChange(value) {
    this.setState({ recaptcha: value })
  }

  resetPassword() {
    this.setState({ loading: true })
    let params = {
      email: this.state.email,
      recaptcha_response: this.state.recaptcha
    }
    this.props.onForgotPassword(params, this.resetPasswordSuccess, this.resetPasswordError)
    window.grecaptcha.reset();
  }

  resetPasswordSuccess(response) {
    this.setState({
      loading: false,
      success: response?.msg,
      error: null,
      hasSubmitted: true,
      recaptcha: ''
    })
  }

  resetPasswordError(response) {
    this.setState({
      loading: false,
      error: response?.data?.msg,
      success: null,
      recaptcha: ''
    })
  }


  render() {
    let isEmailValid = this.state.email.trim() && this.state.email.includes('@')

    return (
      <div style={{"backgroundImage": "url("+auth_bg+")", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
        <AuthHeader />
        <div className="account-pages mb-3" style={{height: "100vh"}}>
          <Container>
            <Row className="justify-content-center">
              <Col md={6} lg={5} xl={4}>
                <Card className="overflow-hidden">

                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-8 col-md-8">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{this.props.t("Welcome to Logos!")}</h5>
                        </div>
                      </Col>
                      <Col className="col-4 col-md-4">
                        <img src={logo} width="80" className="p-2"/>
                        {/* <img src={profile} alt="" className="img-fluid" /> */}
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0">
                    <div className="p-3 pt-5">
                      <h6 className="text-center mb-4">{this.props.t("Forgot Password")}</h6>
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.onClickLogin}
                      >
                        {this.state.success && (
                          <Alert color="success" role="alert">
                            {this.state.success}
                          </Alert>
                        )}

                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                        {!this.state.hasSubmitted && (
                          <React.Fragment>
                            <div className="mb-3">
                              <AvField
                                required
                                type="email"
                                name="email"
                                label={this.props.t("Email")}
                                value={this.state.email}
                                className="form-control"
                                placeholder={this.props.t("Email")}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                validate={{
                                  email: { value: true, errorMessage: this.props.t("Please enter valid email id") },
                                  required: { value: true, errorMessage: this.props.t("Please enter email id") }
                                }}
                              />
                            </div>
                            <center>
                              <ReCaptcha style={{transform:"scale(0.93)", transformOrigin:"0 0"}}
                                sitekey={recaptchaKey}
                                onChange={this.onRecaptchaChange}/>
                            </center>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                onClick={this.resetPassword}
                                disabled={!isEmailValid}
                              >
                                {this.state.loading ? (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                                ) : this.props.t("Reset Password")}
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        <div className="mt-4 text-center">
                          <Link to="/login" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> {this.props.t("Back to Login")}
                          </Link>
                        </div>

                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Footer auth={true} />
          </Container>
        </div>
      </div>
    )
  }
}

ForgotPassword.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  error: PropTypes.any,
  onForgotPassword: PropTypes.func
}

const mapStateToProps = state => ({
  user: state.auth.user,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onForgotPassword: (email, onSuccess, onError) => dispatch(forgotPassword(email, onSuccess, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ForgotPassword)))
