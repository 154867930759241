import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  Label,
  Modal,
  Badge
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import { AvField, AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getAdminExchangeDetails,
  coinImport,
  coinPairImport,
} from "store/actions"


class ImportCoinNPairs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success: null,
      error: null,
      showCoinImportModal: false,
      exchange: null,
      coins: [],
      coinPairs: [],
      fetchingCoinsLoading: false,
      fetchingCoinsPairLoading: false,
      searchCoins: "",
      searchCoinsPairs: "",
      market_summary_url: ""
    }
    this.onConfirmCoinImport = this.onConfirmCoinImport.bind(this)
    this.onConfirmCoinpairImport = this.onConfirmCoinpairImport.bind(this)
  }

  componentDidMount() {

    let code = this.props?.match?.params?.id;
    if (code) {
      this.props.onGetExchange({ id: code }, (res) => {
        this.setState({
          exchange: res.data[0],
          coins: res.data[0].coins,
          coinPairs: res.data[0].pairs,
          market_summary_url: res.data[0].market_summary_url,
          e_id: res.data[0]._id
        })
      })
    }

  }

  onConfirmCoinImport() {
    this.setState({
      fetchingCoinsLoading: true
    })
    let coinImportParams = {
      api_url: this.state.coinImportUrl,
      exchange_id: this.state.e_id
    };
    this.props.onCoinImport(coinImportParams, (res) => {
      if (res.status == 'success') {

        if (res.data.length) {

          let localExchange = this.state.exchange;
          localExchange.imported_coins = true;
          this.setState({
            fetchingCoinsLoading: false,
            coins: res.data,
            exchange: localExchange,
            success: res.msg
          })
        } else {
          this.setState({
            fetchingCoinsLoading: false,
            success: res.msg
          })

        }
      } else {
        this.setState({
          fetchingCoinsLoading: false,
          error: res.msg
        })
      }
      // console.log("onCoinImport sucess", res)
    }, (err) => {
      this.setState({
        fetchingCoinsLoading: false
      })
    })
  }

  onConfirmCoinpairImport() {
    this.setState({
      fetchingCoinsPairLoading: true
    })
    let coinImportParams = {
      api_url: this.state.coinPairImportUrl,
      exchange_id: this.state.e_id
    };
    this.props.onCoinPairImport(coinImportParams, (res) => {
      // console.log("res ", res);
      if (res.status == 'success') {
        if (res.data.length) {
          let localExchange = this.state.exchange;
          localExchange.imported_pairs = true;
          this.setState({
            fetchingCoinsPairLoading: false,
            coinPairs: res.data,
            exchange: localExchange,
            success: res.msg
          })
        } else {
          this.setState({
            fetchingCoinsPairLoading: false,
            success: res.msg
          })

        }
      } else {
        this.setState({
          fetchingCoinsPairLoading: false,
          error: res.msg
        })
      }
      // console.log("onCoinImport sucess", res)
    }, (err) => {
      this.setState({
        fetchingCoinsPairLoading: false
      })
      // console.log("onCoinImport err", err)
    })
  }


  render() {

    const { searchCoins, searchCoinsPairs } = this.state;
    const filteredCoins = this.state.coins.filter(coin => {
      if (coin) {
        return coin.toLowerCase().indexOf(searchCoins.toLowerCase()) !== -1;
      }
    });
    const filteredCoinsPairs = this.state.coinPairs.filter(coin => {
      if (coin) {
        return coin.toLowerCase().indexOf(searchCoinsPairs.toLowerCase()) !== -1;
      }
    });
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={this.props.t("Exchanges")} breadcrumbItem={this.props.t("Import Coins And Coin Pairs") + ": " + (this.state?.exchange?.name ? this.state?.exchange?.name : "")} />
            {this.state.error && (
              <Alert color="danger" role="alert">
                {this.state.error}
              </Alert>
            )}
            {this.state.success && (
              <Alert color="success" role="alert">
                {this.state.success}
              </Alert>
            )}
            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle >
                      <span>{this.props.t("Import Coins")}</span>
                      {
                        !this.state.fetchingCoinsLoading ?
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.onConfirmCoinImport}
                            style={{ float: "right", top: -7, position: "relative" }}
                          >
                            {this.props.t("Update Coins")}
                          </button>
                          :
                          null
                      }
                    </CardTitle>
                    <hr />
                    {
                      this.state.exchange && !this.state.exchange.imported_coins ?
                        <Form>
                          {/* <div className="mb-3">
                            <Label>{this.props.t("Enter url")}</Label>
                            <Input type="text" className="form-control"
                              onChange={(e) => this.setState({ coinImportUrl: e.target.value })} />
                          </div> */}
                          {
                            !this.state.fetchingCoinsLoading ?
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={this.onConfirmCoinImport}
                                >
                                  {this.props.t("Import Coins")}
                                </button>
                              </div>
                              : null
                          }
                        </Form>
                        : null
                    }

                    {
                      this.state.fetchingCoinsLoading ?
                        <span>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                          {this.props.t("Importing coins. It might take few minutes. Please wait...")}
                        </span>
                        : null
                    }
                    {
                      this.state.exchange && this.state.exchange.imported_coins && this.state.market_summary_url ?
                        <div><strong>{this.state.market_summary_url}</strong></div>
                        : null
                    }

                    {
                      this.state.coins.length > 0 ?

                        <div className="mt-3">
                          <Label>{this.props.t("Coins imported")}</Label>
                          <Input
                            placeholder={this.props.t("Search Coins")}
                            onChange={(e) => this.setState({ searchCoins: e.target.value })}
                          />
                          <div>
                            {
                              filteredCoins.map((item, index) => {
                                return (
                                  <span key={index.toString()}>
                                    <button
                                      type="button"
                                      className="btn btn-outline-success btn-sm mt-1"
                                    >
                                      {item}
                                    </button>
                                &nbsp;
                                  </span>
                                )
                              })
                            }
                          </div>
                        </div>
                        :
                        null
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>
                      <span>{this.props.t("Import Coins Pairs")}</span>
                      {
                        !this.state.fetchingCoinsPairLoading ?
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.onConfirmCoinpairImport}
                            style={{ float: "right", top: -7, position: "relative" }}
                          >
                            {this.props.t("Update Coins Pairs")}
                          </button>
                          :
                          null
                      }
                    </CardTitle>
                    <hr />
                    {
                      this.state.exchange && !this.state.exchange.imported_pairs ?
                        <Form>

                          {/* <div className="mb-3">
                            <Label>{this.props.t("Enter url")}</Label>
                            <Input type="text" className="form-control"
                              onChange={(e) => this.setState({ coinPairImportUrl: e.target.value })} />
                          </div> */}
                          {
                            !this.state.fetchingCoinsPairLoading ?
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={this.onConfirmCoinpairImport}
                                >
                                  {this.props.t("Import Coins Pair")}
                                </button>
                              </div>
                              :
                              null
                          }
                        </Form>
                        : null
                    }

                    {
                      this.state.fetchingCoinsPairLoading ?
                        <span>
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                          {this.props.t("Importing coins. It might take few minutes. Please wait...")}
                        </span>
                        : null
                    }

                    {
                      this.state.exchange && this.state.exchange.imported_pairs && this.state.market_summary_url ?
                        <div><strong>{this.state.market_summary_url}</strong></div>
                        : null
                    }

                    {
                      this.state.coinPairs.length > 0 ?

                        <div className="mt-3">
                          <Label>{this.props.t("Coins imported")}</Label>
                          <Input
                            placeholder={this.props.t("Search Coins Pairs")}
                            onChange={(e) => this.setState({ searchCoinsPairs: e.target.value })}
                          />
                          <div>
                            {
                              filteredCoinsPairs.map((item, index) => {
                                return (
                                  <span key={index.toString()}>
                                    <button
                                      type="button"
                                      className="btn btn-outline-success btn-sm mt-1"
                                    >
                                      {item}
                                    </button>
                                &nbsp;
                                  </span>
                                )
                              })
                            }
                          </div>
                        </div>
                        :
                        null
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ImportCoinNPairs.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  // exchanges: PropTypes.array,
  exchange_details: PropTypes.array,

  onGetExchange: PropTypes.func,
  onCreateExchange: PropTypes.func,
  onUpdateExchange: PropTypes.func,
  match: PropTypes.any,
  onCoinImport: PropTypes.func,
  onCoinPairImport: PropTypes.func,
}

const mapStateToProps = state => ({
  // exchanges: state.superAdmin.exchanges,
})

const mapDispatchToProps = dispatch => ({
  onGetExchange: (exchange, onSuccess, onError) => dispatch(getAdminExchangeDetails(exchange, onSuccess, onError)),
  onCoinImport: (api, onSuccess, onError) => dispatch(coinImport(api, onSuccess, onError)),
  onCoinPairImport: (api, onSuccess, onError) => dispatch(coinPairImport(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ImportCoinNPairs)))
