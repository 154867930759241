import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import { AvField, AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getAdminExchangeDetails,
  createExchange,
  updateExchange,
  getBotServices
} from "store/actions"
import Select from 'react-select';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' }
// ]


class AddEditCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedExchange: null,
      selectedExchangeName: null,
      name: '',
      exchange_id: '',
      balance_api: '',
      balance_api_method: 'GET',
      open_order: '',
      open_order_method: 'GET',
      create_order: '',
      create_order_method: 'GET',
      delete_order: '',
      delete_order_method: 'GET',
      market_summary_api: '',
      market_summary_api_method: 'GET',
      order_history_api: '',
      order_history_api_method: 'GET',
      tickers_api: '',
      tickers_api_method: 'GET',
      ccxtType: null,
      exchange: null,
      isEdit: false,
      e_id: null,


      loading: false,
      error: null,
      success: null,
      botServices: [],
      SelectedBotServices:[],

    }
    this.createExchange = this.createExchange.bind(this)
    this.updateExchange = this.updateExchange.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)

    // this.state = {
    //   items: [],
    //   DataisLoaded: false
    // };
  }


  componentDidMount() {

    // console.log("componentDidMount called");
    const { botServices, onGetBotServices } = this.props
    // console.log("componentDidMount called1");
    let code = this.props?.match?.params?.id;
    if (code) {
      // console.log("componentDidMount called3");

      this.props.onGetExchange({ id: code }, (res) => {
        this.setState({
          exchange: res.data[0],
          e_id: res.data[0]._id,
          name: res.data[0].name,
          ccxtType: res.data[0].is_ccxt_registered ? "ccxt" : "nonccxt",
          exchange_id: res.data[0].ccxt_id,
          balance_api: res.data[0].balance_api_url,
          balance_api_method: res.data[0].balance_api_url_method,
          open_order: res.data[0].open_orders_api_url,
          open_order_method: res.data[0].open_orders_api_url_method,
          create_order: res.data[0].create_order_api_url,
          create_order_method: res.data[0].create_order_api_url_method,
          delete_order: res.data[0].delete_order_api_url,
          delete_order_method: res.data[0].delete_order_api_url_method,
          market_summary_api: res.data[0].market_summary_url,
          market_summary_api_method: res.data[0].market_summary_url_method,
          order_history_api: res.data[0].order_history_url,
          order_history_api_method: res.data[0].order_history_url_method,
          tickers_api: res.data[0].ticker_api_url,
          tickers_api_method: res.data[0].ticker_api_url_method,
          isEdit: true,
          SelectedBotServices:  Object.prototype.hasOwnProperty.call(res.data[0], "bot_services") ? res.data[0].bot_services.map(botService=>{
            return {value:botService._id, label:botService.name}
          }): null,
          
        })
        // const SelectedBotServices=res.data[0].bot_services.map(botService=>{
        //   return {label:botService.name, value:botService._id}
        // })
        // console.log(SelectedBotServices)
      })
    }
    console.log("componentDidMount called4");
    onGetBotServices(
      {}, 
      (res) => {
        console.log(" res ", res);
      this.setState({ botServices: res.data, loadingInitData: false })
      let items = [];
      res.data.map((item) => (
        items.push({ value: item._id, label: item.name })

      ));
      this.setState({
        items: items,
        DataisLoaded: true
      });
    }, (err) => {
      console.log(" err ", err);
      this.setState({ loadingInitData: false })
    })

    // fetch(
    //   "https://jsonplaceholder.typicode.com/users")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     // console.log(json)
    //     let items = [];
    //     json.map((item) => (
    //       items.push({ value: item.id, label: item.name })

    //     ));
    //     this.setState({
    //       items: items,
    //       DataisLoaded: true
    //     });
    //   })
  }


  onChangeCcxtType(ccxtType) {
    this.setState({
      ccxtType: ccxtType,
      exchange_id: '',
      balance_api: '',
      open_order: '',
      create_order: '',
      delete_order: '',
      market_summary_api: '',
      order_history_api: '',
      tickers_api: ''
    });

  }


  createExchange() {
    this.setState({ loading: true })

    let newExchange = {
      name: this.state.name,
      is_ccxt_registered: this.state.ccxtType == "ccxt" ? true : false,
      ccxt_id: this.state.exchange_id,
      balance_api_url: this.state.balance_api,
      balance_api_url_method: this.state.balance_api_method,
      open_orders_api_url: this.state.open_order,
      open_orders_api_url_method: this.state.open_order_method,
      create_order_api_url: this.state.create_order,
      create_order_api_url_method: this.state.create_order_method,
      delete_order_api_url: this.state.delete_order,
      delete_order_api_url_method: this.state.delete_order_method,
      market_summary_api_url: this.state.market_summary_api,
      market_summary_api_url_method: this.state.market_summary_api_method,
      order_history_url: this.state.order_history_api,
      order_history_url_method: this.state.order_history_api_method,
      ticker_api_url: this.state.tickers_api,
      ticker_api_url_method: this.state.tickers_api_method,
      bot_services:this.state.SelectedBotServices.map(service=>{
        return service.value
      })
    }
    this.props.onCreateExchange(newExchange, this.onSuccess, this.onError)
  }

  onSuccess(response) {
    this.setState({ loading: false })
    this.props.history.push('/exchanges')
  }

  onError(response) {
    this.setState({
      error: response.msg,
      success: '',
      loading: false
    })
  }

  updateExchange() {

    this.setState({ loading: true })
   console.log('this.state',this.state)
    let newExchange = {
      name: this.state.name,
      e_id: this.state.e_id,
      is_ccxt_registered: this.state.ccxtType == "ccxt" ? true : false,
      ccxt_id: this.state.exchange_id,
      balance_api_url: this.state.balance_api,
      balance_api_url_method: this.state.balance_api_method,
      open_orders_api_url: this.state.open_order,
      open_orders_api_url_method: this.state.open_order_method,
      create_order_api_url: this.state.create_order,
      create_order_api_url_method: this.state.create_order_method,
      delete_order_api_url: this.state.delete_order,
      delete_order_api_url_method: this.state.delete_order_method,
      market_summary_api_url: this.state.market_summary_api,
      market_summary_api_url_method: this.state.market_summary_api_method,
      order_history_url: this.state.order_history_api,
      order_history_url_method: this.state.order_history_api_method,
      ticker_api_url: this.state.tickers_api,
      ticker_api_url_method: this.state.tickers_api_method,
      bot_services:this.state.SelectedBotServices && this.state.SelectedBotServices.length>0?this.state.SelectedBotServices.map(service=> service.value):[]
    }

    this.props.onCreateExchange(newExchange, this.onSuccess, this.onError)

    this.props.onUpdateExchange(newExchange, (res) => {
      console.log("Res Data", res);
      if (res.status == "success") {
        this.setState({ loading: false, error: '', success: res.msg })
      } else {
        this.setState({ loading: false, error: res.msg, success: '' })
      }
      // this.props.history.push('/exchanges')
    }, (response) => {
      this.setState({
        error: response.msg,
        success: '',
        loading: false
      })
    })
  }



  render() {
    let ccxtType = this.state.ccxtType == "ccxt" ? true : false;
    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded) return <div>
      <h1> Pleses wait some time.... </h1> </div>;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={this.props.t("Exchanges")} breadcrumbItem={this.state.isEdit ? this.props.t("Update Exchange") : this.props.t("New Exchange")} />
            
            <Card>
              <div className="row">
                <div className="col-md-10 mx-auto" >
                  <Row>
                    <Col md="12">
                      <CardBody>

                        {/* <h4 className="card-title mb-4">{this.props.t("New Exchange")}</h4> */}

                        <div className="crypto-buy-sell-nav">
                          <AvForm onValidSubmit={this.state.isEdit ? this.updateExchange : this.createExchange}>

                            <div className="mb-3">
                              <Label>{this.props.t("Name")}</Label>
                              <AvField
                                required
                                name="name"
                                type="text"
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                validate={{
                                  maxLength: { value: 30, errorMessage: this.props.t("Please enter less than 30 charactors") },
                                  required: { value: true, errorMessage: this.props.t("Please enter name") }
                                }} />
                            </div>
                            <div className="mb-3">
                              <Label>Select Bot Services</Label>
                              <div className="row">
                                <div className="col-md-12 col-12" >
                                  {/* {console.log(this.state.SelectedBotServices)} */}
                                  <Select 
                                    name="SelectedBotServices"
                                    onChange={(selectedOption)=> this.setState(prevState=>({SelectedBotServices:selectedOption}))}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={this.state.items}
                                    value={this.state.SelectedBotServices}
                                  />
                                </div>
                              </div>
                            </div>
                            
                              {/* // this.state.ccxtType == "nonccxt" ? */}
                              <div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Balance api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>
                                      <select className="form-control" style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }} name="balance_api_method" onChange={(e) => this.setState({ balance_api_method: e.target.value })}>
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="balance_api"
                                        type="text"
                                        value={this.state.balance_api}
                                        onChange={(e) => this.setState({ balance_api: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter balance api url") }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <Label>{this.props.t("Open orders api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>

                                      <select className="form-control" style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }} name="open_order_method" onChange={(e) => this.setState({ open_order_method: e.target.value })}

                                      >
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="open_order"
                                        type="text"
                                        value={this.state.open_order}
                                        onChange={(e) => this.setState({ open_order: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter open order api url") },
                                          url: { value: true, errorMessage: this.props.t("Please enter valid order api url") }
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Create order api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>

                                      <select className="form-control" style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }} name="create_order_method" onChange={(e) => this.setState({ create_order_method: e.target.value })}

                                      >
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="create_order"
                                        type="text"
                                        value={this.state.create_order}
                                        onChange={(e) => this.setState({ create_order: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter create order api url") },
                                          url: { value: true, errorMessage: this.props.t("Please enter valid create order api url") }
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Delete order api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>

                                      <select className="form-control" style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }} name="delete_order_method" onChange={(e) => this.setState({ delete_order_method: e.target.value })}

                                      >
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="delete_order"
                                        type="text"
                                        value={this.state.delete_order}
                                        onChange={(e) => this.setState({ delete_order: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter delete order api url") },
                                          url: { value: true, errorMessage: this.props.t("Please enter valid delete order api url") }
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Market summary api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>

                                      <select className="form-control" style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }} name="market_summary_api_method" onChange={(e) => this.setState({ market_summary_api_method: e.target.value })}

                                      >
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="market_summary_api"
                                        type="text"
                                        value={this.state.market_summary_api}
                                        onChange={(e) => this.setState({ market_summary_api: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter market summary api url") },
                                          url: { value: true, errorMessage: this.props.t("Please enter valid market summary api url") }
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Order history api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>

                                      <select className="form-control" style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }} name="order_history_api_method" onChange={(e) => this.setState({ order_history_api_method: e.target.value })}

                                      >
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="order_history_api"
                                        type="text"
                                        value={this.state.order_history_api}
                                        onChange={(e) => this.setState({ order_history_api: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter order history api url") },
                                          url: { value: true, errorMessage: this.props.t("Please enter valid order history api url") }
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Tickers api url")}</Label>
                                  <div className="row">
                                    <div className="col-md-2 col-2" style={{ paddingRight: 0 }}>

                                      <select className="form-control"
                                        style={{ "appearance": "auto", "padding": "0.43rem 0.75rem" }}
                                        name="tickers_api_method"
                                        onChange={(e) => this.setState({ tickers_api_method: e.target.value })}

                                      >
                                        <option value="GET" >GET</option>
                                        <option value="POST" >POST</option>
                                        <option value="PUT" >PUT</option>
                                        <option value="DELETE" >DELETE</option>
                                      </select>
                                    </div>
                                    <div className="col-md-10 col-10" style={{ paddingLeft: 0 }}>
                                      <AvField
                                        required
                                        name="tickers_api"
                                        type="text"
                                        value={this.state.tickers_api}
                                        onChange={(e) => this.setState({ tickers_api: e.target.value })}
                                        validate={{
                                          required: { value: true, errorMessage: this.props.t("Please enter tickers api url") },
                                          url: { value: true, errorMessage: this.props.t("Please enter valid tickers api url") }
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                  
                              </div>

                              {this.state.success && (
                                <Alert color="success" role="alert">
                                  {this.state.success}
                                </Alert>
                              )}


                              {this.state.error && (
                                <Alert color="danger" role="alert">
                                  {this.state.error}
                                </Alert>
                              )}

                              <div className="text-center mt-4">
                                <Link to={"/exchanges/"} className="ml-20">
                                  <Button color="primary">
                                    {this.props.t("Cancel")}
                                  </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;
                                <Button type="submit" color="success"
                                >
                                  {this.state.loading ? (
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                                  ) : this.state.isEdit ? this.props.t("Update Exchange") : this.props.t("New Exchange")}
                                </Button>
                              </div>

                          </AvForm>
                        </div>

                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

AddEditCompany.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  // exchanges: PropTypes.array,
  exchange_details: PropTypes.array,

  onGetExchange: PropTypes.func,
  onCreateExchange: PropTypes.func,
  onUpdateExchange: PropTypes.func,
  match: PropTypes.any,
  onGetBotServices: PropTypes.func,
  botServices: PropTypes.array,
}

const mapStateToProps = state => ({
  // exchanges: state.superAdmin.exchanges,
})

const mapDispatchToProps = dispatch => ({
  onGetExchange: (exchange, onSuccess, onError) => dispatch(getAdminExchangeDetails(exchange, onSuccess, onError)),
  onCreateExchange: (api, onSuccess, onError) => dispatch(createExchange(api, onSuccess, onError)),
  onUpdateExchange: (api, onSuccess, onError) => dispatch(updateExchange(api, onSuccess, onError)),
  onGetBotServices: (page, onSuccess, onError) => dispatch(getBotServices(page, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(AddEditCompany)))
