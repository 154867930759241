import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import Select from 'react-select';
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getCompanyDetails,
  updateCompany,
  updateCompanyLogo,
  getBotServices
} from "store/actions"
import { forEach } from "lodash";


class UpdateCompany extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: null,
      name: '',
      email: '',
      password: '',
      bot_customization_price: '',
      bot_init_price: '',
      bot_subscription_price: '',
      selected_bot_services: null,

      selected_bot_servicesError: null,

      loading: false,
      error: null,
      isEdit: false
    }
    this.updateCompany = this.updateCompany.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
  }

  componentDidMount() {
    this.props.onGetBotServices()
    let code = this.props?.match?.params?.id;
    if (code) {
      this.props.onGetComapny({ id: code }, (res) => {
        let bot_services = [];
        res.data[0].services.map((item) => {
          bot_services.push({ value: item._id, label: item.name })
        })
        this.setState({
          c_id: res.data[0]._id,
          name: res.data[0].name,
          email: res.data[0].email,
          bot_customization_price: res.data[0].customization_price.amount,
          bot_init_price: res.data[0].initialise_price.amount,
          bot_subscription_price: res.data[0].subscription_plans[0].price,
          selected_bot_services: bot_services,
          isEdit: true
        })
      })
    }
  }


  onChangeSelected_bot_services(selected_bot_services) {

    this.setState({ selected_bot_services: selected_bot_services }, () => {
      this.checkSelected_bot_servicesValidation();
    });
  }
  checkSelected_bot_servicesValidation() {
    let is_valid = true;
    if (!this.state.selected_bot_services) {
      this.setState({ selected_bot_servicesError: "Please select Bot Services" })
      is_valid = false;
    } else {
      this.setState({ selected_bot_servicesError: "" })
      is_valid = true;

    }
    return is_valid;
  }


  ispositive(element, index, array) {
    return element;
  }

  onSubmit = e => {
    this.checkSelected_bot_servicesValidation()
  };


  updateCompany() {

    if (![this.checkSelected_bot_servicesValidation()].every(this.ispositive)) {
      return;
    }



    this.setState({ loading: true })

    let newCompany = {
      c_id: this.state.c_id,
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      initialise_price: this.state.bot_init_price,
      subscription_price: this.state.bot_subscription_price,
      customization_price: this.state.bot_customization_price,
      services: this.state.selected_bot_services.map(value => value.value)
    }
    this.props.onUpdateCompany(newCompany, (res) => {
      if (res.status == "success") {
        this.setState({ loading: false, error: '', success: res.msg })
      } else {
        this.setState({ loading: false, error: res.msg, success: '' })
      }
    }, (response) => {
      this.setState({
        error: response.msg,
        success: '',
        loading: false
      })
    })
  }


  updateCompanyLogo() {
    this.setState({ loading: true })

    let newCompany = {
      logo: this.state.logo
    }
    this.props.onUpdateCompanyLogo(newCompany, (res) => {
      if (res.status == "success") {
        this.setState({ loading: false, error: '', success: res.msg })
      } else {
        this.setState({ loading: false, error: res.msg, success: '' })
      }
      // this.props.history.push('/exchanges')
    }, (response) => {
      this.setState({
        error: response.msg,
        success: '',
        loading: false
      })
    })
  }

  onSuccess(response) {
    this.setState({ loading: false })
    this.props.history.push('/companies')
  }

  onError(response) {
    this.setState({
      error: response.msg,
      success: '',
      loading: false
    })
  }

  handleChange = (selectedOption) => {
    this.setState({ selected_bot_services: selectedOption }, () => {
      this.checkSelected_bot_servicesValidation();
    });
  };




  render() {
    const { botServices } = this.props;
    let options = [];
    botServices.forEach((item, index) => {
      options.push({ value: item._id, label: item.name })
    })
    // const options = [
    //   { value: 'chocolate', label: 'Chocolate' },
    //   { value: 'strawberry', label: 'Strawberry' },
    //   { value: 'vanilla', label: 'Vanilla' }
    // ]

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={this.props.t("Update Company")}  />
            {/* <Row>
              <AvForm
                onValidSubmit={this.updateCompanyLogo}
              >
                <Card>
                  <CardBody>

                    <div className="mb-3">
                      <Label>{this.props.t("Logo")}</Label>
                      <AvField
                        required
                        type="file"
                        name="logo"
                        onChange={(e) => this.setState({ logo: e.target.files[0] })}
                        validate={{
                          required: { value: true, errorMessage: 'Please choose a logo' }
                        }} />
                    </div>
                    {
                      this.state.logo ?
                        <div className="mb-3">
                          <img src={URL.createObjectURL(this.state.logo)} style={{width: "80px"}}/>
                        </div> :
                        null
                    }
                    <div className="mt-4">
                      <Button type="submit" color="success"
                      >
                        {this.state.loading ? (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                        ) : this.props.t("Submit")}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </AvForm>
            </Row> */}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    {/* <h4 className="card-title mb-4">{this.props.t("Add New Company")}</h4> */}

                    <div className="crypto-buy-sell-nav">
                      <AvForm
                        onValidSubmit={this.updateCompany}
                        onInvalidSubmit={this.onSubmit}
                      >

                        <div className="mb-3">
                          <Label>{this.props.t("Name")}</Label>
                          <AvField
                            required
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            validate={{
                              required: { value: true, errorMessage: 'Please enter a name' }
                            }} />
                        </div>

                        <div className="mb-3">
                          <Label>{this.props.t("Email")}</Label>
                          <AvField
                            required
                            name="email"
                            type="email"
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            validate={{
                              required: { value: true, errorMessage: 'Please enter email id' }
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <Label>{this.props.t("Price for bot customization")}(USDT)</Label>
                          <AvField
                            required
                            name="bot_customization_price"
                            type="number"
                            value={this.state.bot_customization_price}
                            onChange={(e) => this.setState({ bot_customization_price: e.target.value })}
                            validate={{
                              required: { value: true, errorMessage: 'Please enter Price for bot customization price' }
                            }} />
                        </div>
                        <div className="mb-3">
                          <Label>{this.props.t("Price for bot initialization")}(USDT)</Label>
                          <AvField
                            required
                            name="bot_init_price"
                            type="number"
                            value={this.state.bot_init_price}
                            onChange={(e) => this.setState({ bot_init_price: e.target.value })}
                            validate={{
                              required: { value: true, errorMessage: 'Please enter Price for bot initialization price' }
                            }} />
                        </div>
                        <div className="mb-3">
                          <Label>{this.props.t("Price for Bot Subscription")}(USDT)</Label>
                          <AvField
                            required
                            name="bot_subscription_price"
                            type="number"
                            value={this.state.bot_subscription_price}
                            onChange={(e) => this.setState({ bot_subscription_price: e.target.value })}
                            validate={{
                              required: { value: true, errorMessage: 'Please enter Price for Bot Subscription price' }
                            }} />
                        </div>

                        <div className="mb-3">
                          <Label>{this.props.t("Bot Services")}</Label>
                          <Select
                            isDisabled={this.state.isEdit ? true : false}
                            required
                            name="bot_services"
                            options={options}
                            value={this.state.selected_bot_services}
                            isMulti={true}
                            onChange={this.handleChange}
                            validate={{
                              required: { value: true, errorMessage: 'Please choose bot services' }
                            }}
                          />
                          {this.state.selected_bot_servicesError && (
                            <span
                              style={{ "width": "100%", "marginTop": "0.25rem", "fontSize": "80%", "color": "#f46a6a" }}>
                              {this.state.selected_bot_servicesError}
                            </span>
                          )}
                        </div>

                        {this.state.success && (
                          <Alert color="success" role="alert">
                            {this.state.success}
                          </Alert>
                        )}


                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                        <div className="text-center mt-4">
                          <Button type="submit" color="success"

                          >
                            {this.state.loading ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                            ) : this.props.t("Update Company")}
                          </Button>
                        </div>

                      </AvForm>
                    </div>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UpdateCompany.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  onCreateCompany: PropTypes.func,
  onGetBotServices: PropTypes.func,
  onGetComapny: PropTypes.func,
  botServices: PropTypes.array,
  onUpdateCompany: PropTypes.func,
  onUpdateCompanyLogo: PropTypes.func,
  match: PropTypes.any,
}

const mapStateToProps = state => ({
  botServices: state.superAdmin.botServices,
})

const mapDispatchToProps = dispatch => ({
  onGetBotServices: page => dispatch(getBotServices(page)),
  onGetComapny: (exchange, onSuccess, onError) => dispatch(getCompanyDetails(exchange, onSuccess, onError)),
  onUpdateCompany: (api, onSuccess, onError) => dispatch(updateCompany(api, onSuccess, onError)),
  // onUpdateCompanyLogo: (api, onSuccess, onError) => dispatch(updateCompanyLogo(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(UpdateCompany)))
