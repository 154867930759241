import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"

//import cryptoWalletColumns from "./cryptoWalletColumns"

// Table Data
const productData = [
  { id: 1, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Subscribe", amount: "0.00413 PDT" },
  { id: 2, idno: "#SK3336", pdate: "03 Mar, 2020", type: "Change", amount: "0.0125 PDT" },
  { id: 3, idno: "#SK3226", pdate: "13 Jun, 2020", type: "Unsubscribe", amount: "0 PDT" },
  { id: 4, idno: "#SK3226", pdate: "03 Mar, 2020", type: "Subscribe", amount: "0.00413 PDT" },
]

class SubscriptionHistory extends Component {
  // const data = {
  //   columns: cryptoWalletColumns,
  //   rows: walletHistory,
  // }

  constructor(props) {
    super(props)
    this.state = {}
  }

  getColumns(){
    let that = this;
    return [
      {
        dataField: 'created_at',
        text: this.props.t("Date"),
        formatter: (cellContent, row) => (
          new Date(row?.created_at)?.toLocaleString()
        )
      },
      {
        dataField: 'invoice',
        text: this.props.t("ID")
      },
      {
        dataField: 'initial_payment',
        text: this.props.t('Type'),
        formatter: (cellContent, row) => (
          <span className="text-success"> {row?.initial_payment ? this.props.t("Initialized"): this.props.t("Subscribed")}</span>
        ),
        formatExtraData: (cell, row) => {
          <span className="text-success">{row?.initial_payment ? this.props.t("Initialized"): this.props.t("Subscribed")}</span>
        }
      },
      {
        dataField: 'subscription_plan',
        text: this.props.t('Plan'),
        formatter: (cellContent, row) => (
          row?.subscription_plan?.name ? row?.subscription_plan?.name : "-"
        )
      },
      {
        dataField: 'amount',
        text: this.props.t('Amount')
      },
      {
        dataField: 'status',
        text: this.props.t('Status'),
        formatter: (cellContent, row) => (
          <span
            className={row?.status == 'paid' ? 'text-success' : 'text-danger'}
            style={{ textTransform: 'capitalize' }}
          >{this.props.t(row?.status)}</span>
        ),
        formatExtraData: (cell, row) => {
          <span
            className={row?.status == 'paid' ? 'text-success' : 'text-danger'}
            style={{ textTransform: 'capitalize' }}
          >{this.props.t(row?.status)}</span>
        }
      },
    ]
  }

  render() {

    const { invoices } = this.props
    let columns = this.getColumns()


    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: invoices.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    return (
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{this.props.t("Subscription History")}</h4>
          {/*
          <ul className="nav nav-tabs nav-tabs-custom">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                All
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Buy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3")
                }}
              >
                Sell
              </NavLink>
            </NavItem>
          </ul>
          */}

          {invoices && invoices?.length ? (
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={invoices}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={invoices}
                  columns={columns}
                  bootstrap4
                  search
                >
                  {/* selectRow={selectRow} */}
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          responsive
                          defaultSorted={defaultSorted}
                          bordered={true}
                          striped={true}
                          keyField="_id"
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"} />
                      </div>
                      <div className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : (
              <p className="text-muted">{this.props.t("You have no transaction history yet.")}</p>
            )}

        </CardBody>
      </Card>
    )
  }
}

SubscriptionHistory.propTypes = {
  t: PropTypes.any,
  history: PropTypes.array,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
  invoices: PropTypes.any,
  plans: PropTypes.any,
}

export default withTranslation()(SubscriptionHistory)
