import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getExchanges,
  createAPI
} from "store/actions"


class NewAPI extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedExchange: null,
      selectedExchangeName: null,
      name: null,
      apiKey: null,
      apiSecret: null,
      showUidField: false,

      loading: false,
      error: null
    }
    this.onFetchExchanges = this.onFetchExchanges.bind(this)
    this.onExchangeChange = this.onExchangeChange.bind(this)
    this.addAPI = this.addAPI.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
  }

  componentDidMount() {
    this.props.onGetExchanges(this.onFetchExchanges)
  }

  onFetchExchanges() {
    if (this.props.exchanges && this.props.exchanges.length === 1) {
      this.setState({
        selectedExchange: this.props.exchanges[0]._id,
        selectedExchangeName: this.props.exchanges[0].name
      })
    }
  }


  onExchangeChange(e) {
    let name = e.target.options[e.target.selectedIndex].text;
    let showUidFieldStatus = false;
    if (e.target.value.split(":")[1] == "bitmart") {
      showUidFieldStatus = true;
    }
    this.setState({
      selectedExchange: e.target.value,
      selectedExchangeName: name,
      showUidField: showUidFieldStatus,
    })
  }

  addAPI() {
    const { selectedExchange, name, apiKey, apiSecret, uid } = this.state;

    if (!selectedExchange?.trim() || !name?.trim() || !apiKey?.trim() || !apiSecret?.trim() || (selectedExchange.split(":")[1] == "bitmart" && !uid?.trim())) {
      this.setState({ error: this.props.t('Please enter all fields.'), success: null })
      return;
    }

    this.setState({ loading: true })

    let newAPI = {
      exchange_id: this.state.selectedExchange.split(":")[0],
      name: this.state.name,
      uid: this.state.uid,
      api_key: this.state.apiKey,
      api_secret: this.state.apiSecret
    }
    this.props.onCreateAPI(newAPI, this.onSuccess, this.onError)
  }

  onSuccess(response) {
    this.setState({ loading: false })
    this.props.history.push('/my-apis')
  }

  onError(response) {
    this.setState({
      error: response.data?.msg,
      success: null,
      loading: false
    })
  }


  render() {
    const { exchanges } = this.props;
    console.log('exch : ' , exchanges)

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Logos | Connect Exchange API")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("My APIs")} breadcrumbItem={this.props.t("Connect New API")} />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                    <h4 className="card-title mb-4">{this.props.t("Connect Exchange API")}</h4>

                    <div className="crypto-buy-sell-nav">
                      <Form>

                        <div className="mb-3">
                          <Label>{this.props.t("Nickname")}</Label>
                          <Input type="text" className="form-control" placeholder={this.props.t("Nickname")}
                            onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>

                        <div className="mb-3">
                          <Label>{this.props.t("Exchange")}</Label>
                          <select className="form-select form-control"
                            defaultValue={this.state.selectedExchange}
                            onChange={this.onExchangeChange}
                            placeholder={this.props.t("Select Exchange")}
                          >
                            <option
                              value=""
                            >{this.props.t("Select Exchange")}</option>
                            {exchanges && exchanges.length ? exchanges.map(exchange => {
                              return (
                                <option
                                  key={exchange._id}
                                  value={exchange._id + ":" + exchange.ccxt_id}
                                >{exchange.name}</option>
                              )
                            }) : ''}
                          </select>
                        </div>

                        {
                          this.state.showUidField ?
                            <div className="mb-3">
                              <Label>{this.props.t("Bitmart Memo")}</Label>
                              <Input type="text" className="form-control" placeholder={this.props.t("Memo of your Bitmart API KEY")}
                                onChange={(e) => this.setState({ uid: e.target.value })} />
                            </div>
                            :
                            null
                        }
                        <div className="mb-3">
                          <Label>{this.props.t("API Key")}</Label>
                          <Input type="text" className="form-control" placeholder={this.props.t("API Key")}
                            onChange={(e) => this.setState({ apiKey: e.target.value })} />
                        </div>

                        <div className="mb-3">
                          <Label>{this.props.t("API Secret")}</Label>
                          <Input type="text" className="form-control" placeholder={this.props.t("API Secret")}
                            onChange={(e) => this.setState({ apiSecret: e.target.value })} />
                        </div>

                        {this.state.error && (
                          <Alert color="danger" role="alert">
                            {this.state.error}
                          </Alert>
                        )}

                        <div className="text-center mt-4">
                          <Button type="button" color="success"
                            onClick={this.addAPI}
                          >
                            {this.state.loading ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                            ) : this.props.t("Add Exchange API")}
                          </Button>
                        </div>

                      </Form>
                    </div>
                    <h6 className="mt-4">Note : In the list of exchange api’s page as this list is only for Portfolio Rebalance bot.</h6>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

NewAPI.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  exchanges: PropTypes.array,
  onGetExchanges: PropTypes.func,
  onCreateAPI: PropTypes.func,
}

const mapStateToProps = state => ({
  exchanges: state.exchangeAPI.exchanges,
})

const mapDispatchToProps = dispatch => ({
  onGetExchanges: (onFetch) => dispatch(getExchanges(onFetch)),
  onCreateAPI: (api, onSuccess, onError) => dispatch(createAPI(api, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(NewAPI)))
