import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getUserList,
  getCompanies
} from "store/actions"


class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userList: [],
      companies: [],
      loadingInitData: true
    }

  }

  toLowerCase1(str) {
    return str.toLowerCase()
  }

  componentDidMount() {
    const { userList, onGetUserList, onGetCompanies } = this.props
    onGetCompanies({}, (res) => {
      this.setState({ companies: res.data })
    }, () => {

    })
    // if (userList && !userList.length) {
    this.getUser(null)
    // }
    this.setState({ userList })
  }

  getUser = (event) => {
    this.setState({ loadingInitData: true })
    if (event) {
      this.props.onGetUserList({ id: event.target.value },
        (res) => {
          this.setState({ loadingInitData: false })
        }, (err) => {
          this.setState({ loadingInitData: false })
        })
    } else {

      this.props.onGetUserList(
        {},
        (res) => {
          this.setState({ loadingInitData: false })
        }, (err) => {
          this.setState({ loadingInitData: false })
        })
    }
  }



  renderColumns() {
    return [
      {
        dataField: "name",
        text: this.props.t("Name"),
      },
      {
        dataField: "email",
        text: this.props.t("Email"),
      },
      {
        dataField: "email_verified",
        text: this.props.t("Email Verified"),
      },
      {
        dataField: "company",
        text: this.props.t("Company"),
        formatter: (cellContent, row) => (
          row?.company?.name
        )
      },
      {
        dataField: "created_at",
        text: this.props.t("Created Date"),
        formatter: (cellContent, row) => (
          new Date(row?.created_at)?.toLocaleString()
        )
      },

    ]
  }





  render() {
    const { userList } = this.props

    let exchangeAPIColumns = this.renderColumns();

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: userList.length, // replace later with size(Order),
      custom: true
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={this.props.t("Users")} />
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title" style={{ display: "flex", flexDirection: "row" }}>
                  <div className="col-lg-1 d-flex flex-column justify-content-center">
                    {this.props.t("Users")}

                  </div>
                  <div className="col-lg-3">

                    <select className="form-control" onChange={(e) => this.getUser(e)}>
                      <option value="">{this.props.t("Select Company")}</option>
                      {
                        this.state.companies.map((item, index) => {
                          return (

                            <option key={item._id} value={item._id}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>

                {this.state.success && (
                  <Alert color="success" role="alert">
                    {this.state.success}
                  </Alert>
                )}

                {this.state.error && (
                  <Alert color="danger" role="alert">
                    {this.state.error}
                  </Alert>
                )}

                {
                  !this.state.loadingInitData ?

                    userList && userList?.length ? (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='id'
                        columns={exchangeAPIColumns}
                        data={userList}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={userList}
                            columns={exchangeAPIColumns}
                            bootstrap4
                            search
                          >
                            {/* selectRow={selectRow} */}
                            {toolkitProps => (
                              <React.Fragment>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={true}
                                    striped={true}
                                    keyField="_id"
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                  />
                                </div>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    ) : (
                      <div>
                        <p className="text-muted">{this.props.t("You do not have any user")}</p>
                      </div>
                    )
                    :
                    <div>
                      <p className="text-muted">{this.props.t("Loading...")}</p>
                    </div>
                }
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Users.propTypes = {
  t: PropTypes.any,
  userList: PropTypes.array,
  companies: PropTypes.array,
  onGetUserList: PropTypes.func,
  onGetCompanies: PropTypes.func,
}

const mapStateToProps = state => ({
  userList: state.superAdmin.userList,
  companies: state.superAdmin.companies,
})

const mapDispatchToProps = dispatch => ({
  onGetCompanies: (page, onSuccess, onError) => dispatch(getCompanies(page, onSuccess, onError)),
  onGetUserList: (page, onSuccess, onError) => dispatch(getUserList(page, onSuccess, onError))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Users)))
