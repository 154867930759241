import React from "react";
import { Input, Label, Row } from "reactstrap";

class PRBot extends React.Component{
    constructor(props) {
        super(props);
     
        this.state = { value: 0};
      }
     render(){
        return(
            <>
                <Row>
                    <div className="col-md-6">
                        <div className="mt-3">
                            <Label>Account One Private key</Label>
                            <Input type="text" className="form-control" placeholder="Account One Private key"/>
                        </div>
                    </div>
                    
                </Row>
            </>
        )
     }
}

export default PRBot;