
export const GET_EXCHANGES = "GET_EXCHANGES"
export const GET_EXCHANGES_SUCCESS = "GET_EXCHANGES_SUCCESS"
export const GET_EXCHANGES_FAIL = "GET_EXCHANGES_FAIL"

export const GET_APIS = "GET_APIS"
export const GET_APIS_SUCCESS = "GET_APIS_SUCCESS"
export const GET_APIS_FAIL = "GET_APIS_FAIL"

export const CREATE_API = "CREATE_API"
export const CREATE_API_SUCCESS = "CREATE_API_SUCCESS"
export const CREATE_API_FAIL = "CREATE_API_FAIL"

export const UPDATE_API = "UPDATE_API"
export const UPDATE_API_SUCCESS = "UPDATE_API_SUCCESS"
export const UPDATE_API_FAIL = "UPDATE_API_FAIL"

export const DELETE_API = "DELETE_API"
export const DELETE_API_SUCCESS = "DELETE_API_SUCCESS"
export const DELETE_API_FAIL = "DELETE_API_FAIL"

export const GET_ADMIN_SERVICES = "GET_ADMIN_SERVICES"
export const GET_ADMIN_APIS_SUCESS = "GET_ADMIN_APIS_SUCESS"
export const GET_ADMIN_APIS_FAIL = "GET_ADMIN_APIS_FAIL"
