
export const GET_BOT_SERVICE = "GET_BOT_SERVICE"
export const GET_BOT_SERVICE_SUCCESS = "GET_BOT_SERVICE_SUCCESS"
export const GET_BOT_SERVICE_FAIL = "GET_BOT_SERVICE_FAIL"

export const CREATE_BOT_SERVICE = "CREATE_BOT_SERVICE"
export const CREATE_BOT_SERVICE_SUCCESS = "CREATE_BOT_SERVICE_SUCCESS"
export const CREATE_BOT_SERVICE_FAIL = "CREATE_BOT_SERVICE_FAIL"

export const EDIT_BOT_SERVICE = "EDIT_BOT_SERVICE"
export const EDIT_BOT_SERVICE_SUCCESS = "EDIT_BOT_SERVICE_SUCCESS"
export const EDIT_BOT_SERVICE_FAIL = "EDIT_BOT_SERVICE_FAIL"

export const DELETE_BOT_SERVICE = "DELETE_BOT_SERVICE"
export const DELETE_BOT_SERVICE_SUCCESS = "DELETE_BOT_SERVICE_SUCCESS"
export const DELETE_BOT_SERVICE_FAIL = "DELETE_BOT_SERVICE_FAIL"

export const CHANGE_STATUS_BOT_SERVICE = "CHANGE_STATUS_BOT_SERVICE"
export const CHANGE_STATUS_BOT_SERVICE_SUCCESS = "CHANGE_STATUS_BOT_SERVICE_SUCCESS"
export const CHANGE_STATUS_BOT_SERVICE_FAIL = "CHANGE_STATUS_BOT_SERVICE_FAIL"

export const GET_USER_LIST = "GET_USER_LIST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL"

export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

export const CREATE_COMPANY = "CREATE_COMPANY"
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS"
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL"

export const CREATE_EXCHANGE = "CREATE_EXCHANGE"
export const CREATE_EXCHANGE_SUCCESS = "CREATE_EXCHANGE_SUCCESS"
export const CREATE_EXCHANGE_FAIL = "CREATE_EXCHANGE_FAIL"

export const UPDATE_EXCHANGE = "UPDATE_EXCHANGE"
export const UPDATE_EXCHANGE_SUCCESS = "UPDATE_EXCHANGE_SUCCESS"
export const UPDATE_EXCHANGE_FAIL = "UPDATE_EXCHANGE_FAIL"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

export const UPDATE_COMPANY_LOGO = "UPDATE_COMPANY_LOGO"
export const UPDATE_COMPANY_LOGO_SUCCESS = "UPDATE_COMPANY_LOGO_SUCCESS"
export const UPDATE_COMPANY_LOGO_FAIL = "UPDATE_COMPANY_LOGO_FAIL"


export const CHANGE_STATUS_COMPANY = "CHANGE_STATUS_COMPANY"
export const CHANGE_STATUS_COMPANY_SUCCESS = "CHANGE_STATUS_COMPANY_SUCCESS"
export const CHANGE_STATUS_COMPANY_FAIL = "CHANGE_STATUS_COMPANY_FAIL"

export const GET_EXCHANGE = "GET_EXCHANGE"
export const GET_EXCHANGE_SUCCESS = "GET_EXCHANGE_SUCCESS"
export const GET_EXCHANGE_FAIL = "GET_EXCHANGE_FAIL"

export const GET_EXCHANGE_DETAIL = "GET_EXCHANGE_DETAIL"
export const GET_EXCHANGE_DETAIL_SUCCESS = "GET_EXCHANGE_DETAIL_SUCCESS"
export const GET_EXCHANGE_DETAIL_FAIL = "GET_EXCHANGE_DETAIL_FAIL"

export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

export const CHANGE_STATUS_EXCHANGE = "CHANGE_STATUS_EXCHANGE"
export const CHANGE_STATUS_EXCHANGE_SUCCESS = "CHANGE_STATUS_EXCHANGE_SUCCESS"
export const CHANGE_STATUS_EXCHANGE_FAIL = "CHANGE_STATUS_EXCHANGE_FAIL"

export const COIN_IMPORT = "COIN_IMPORT"
export const COIN_IMPORT_SUCCESS = "COIN_IMPORT_SUCCESS"
export const COIN_IMPORT_FAIL = "COIN_IMPORT_FAIL"

export const COIN_PAIR_IMPORT = "COIN_PAIR_IMPORT"
export const COIN_PAIR_IMPORT_SUCCESS = "COIN_PAIR_IMPORT_SUCCESS"
export const COIN_PAIR_IMPORT_FAIL = "COIN_PAIR_IMPORT_FAIL"