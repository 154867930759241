import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import { Container } from "reactstrap"
import Breadcrumbs from "navigation/Breadcrumb"

class HowToChooseCurrencyPair extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("How To Choose Currency Pair")} />
                        <div>
                            How To Choose Currency Pair
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

HowToChooseCurrencyPair.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(HowToChooseCurrencyPair)
