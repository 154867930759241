import {
  GET_EXCHANGES,
  GET_EXCHANGES_SUCCESS,
  GET_EXCHANGES_FAIL,
  GET_APIS,
  GET_APIS_SUCCESS,
  GET_APIS_FAIL,
  CREATE_API,
  CREATE_API_SUCCESS,
  CREATE_API_FAIL,
  UPDATE_API,
  UPDATE_API_SUCCESS,
  UPDATE_API_FAIL,
  DELETE_API,
  DELETE_API_SUCCESS,
  DELETE_API_FAIL,
  GET_ADMIN_SERVICES,
  GET_ADMIN_APIS_SUCESS,
  GET_ADMIN_APIS_FAIL,
} from "./actionTypes"



export const getExchanges = (botServiceId, onFetch) => ({
  type: GET_EXCHANGES,
  onFetch,
  payload:botServiceId
})

export const getExchangesSuccess = exchanges => ({
  type: GET_EXCHANGES_SUCCESS,
  payload: exchanges,
})

export const getExchangesFail = error => ({
  type: GET_EXCHANGES_FAIL,
  payload: error,
})



export const getAPIs = onFetch => ({
  type: GET_APIS,
  onFetch
})

export const getAPIsSuccess = apis => ({
  type: GET_APIS_SUCCESS,
  payload: apis,
})

export const getAPIsFail = error => ({
  type: GET_APIS_FAIL,
  payload: error,
})



export const createAPI = (api, onSuccess, onError) => ({
  type: CREATE_API,
  payload: api,
  onSuccess,
  onError
})

export const createAPISuccess = api => ({
  type: CREATE_API_SUCCESS,
  payload: api,
})

export const createAPIFail = error => ({
  type: CREATE_API_FAIL,
  payload: error,
})



export const updateAPI = api => ({
  type: UPDATE_API,
  payload: api,
})

export const updateAPISuccess = api => ({
  type: UPDATE_API_SUCCESS,
  payload: api,
})

export const updateAPIFail = error => ({
  type: UPDATE_API_FAIL,
  payload: error,
})



export const deleteAPI = (api, onSuccess, onError) => ({
  type: DELETE_API,
  payload: api,
  onSuccess,
  onError
})

export const deleteAPISuccess = api => ({
  type: DELETE_API_SUCCESS,
  payload: api,
})

export const deleteAPIFail = error => ({
  type: DELETE_API_FAIL,
  payload: error,
})

export const getadminServices = onFetch =>({
  type : GET_ADMIN_SERVICES,
  onFetch
})

export const getAdminAPIsSucess = apis =>({
  type: GET_ADMIN_APIS_SUCESS,
  payload: apis,
})

export const getAdminAPIsFail = error => ({
  type: GET_ADMIN_APIS_FAIL,
  payload: error,
})
