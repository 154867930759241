import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {  } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import {
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap"
import MetaTags from 'react-meta-tags'

import Breadcrumbs from "navigation/Breadcrumb"


import {
} from "store/actions"

class History extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewmodal: false,
      modal: false,
      orders: [],
      EcommerceOrderColumns: [
        {
          text: this.props.t("id"),
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (order) => (
            <>
              {order.id}
            </>
          ),
        },
        {
          dataField: "orderdate",
          text: this.props.t("Transaction Date and Time"),
          sort: true
        },
        {
          dataField: "orderWidth",
          text: this.props.t("Order Width"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.orderWidth}
            </Link>
          ),
        },
        {
          dataField: "tradingVolume",
          text: this.props.t("Trading Volume"),
          sort: true,
        },
        {
          dataField: "balance",
          text: this.props.t("Balance"),
          sort: true,
        },
        {
          dataField: "bp",
          text: this.props.t("BP"),
          sort: true,
          // formatter: (cellContent, row) => (
          //   <Badge
          //     className={"font-size-12 badge-soft-" + row.badgeclass}
          //     color={row.badgeclass}
          //     pill
          //   >
          //     {row.paymentStatus}
          //   </Badge>
          // ),
        },
        {
          dataField: "annual_interest_pace",
          text: this.props.t("Annual Interest Pace"),
          sort: true,
        },
      ]
    }
    this.toggle = this.toggle.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
  }

  toLowerCase1(str) {
    return str.toLowerCase()
  }

  componentDidMount() {
    // const { orders } = this.props
    // if (orders && !orders.length) {
    // }
    // this.setState({ orders })
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { orders } = this.props
    if (!isEmpty(orders) && size(prevProps.orders) !== size(orders)) {
      this.setState({ orders: {}, isEdit: false })
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }
  render() {
    const { orders } = this.state

    //pagination customization
    const pageOptions = {
      sizePerPage: 6,
      totalSize: orders.length,
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }]

    const selectRow = {
      mode: 'checkbox',
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Logos | History")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("My Bots")} />
            <Card>
              <CardBody>
                <div className="mb-4 h4 card-title" style={{justifyContent:"space-between",display:"flex", flexDirection:"row"}}>
                  <p>{this.props.t("Recent Transactions")}</p>
                </div>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField='id'
                  columns={this.state.EcommerceOrderColumns}
                  data={orders}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={this.state.EcommerceOrderColumns}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              defaultSorted={defaultSorted}
                              bordered={false}
                              striped={false}
                              selectRow={selectRow}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                            />
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

History.propTypes = {
  t: PropTypes.any,
  orders: PropTypes.array,
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(History)))
